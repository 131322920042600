import React, { useState, useEffect } from "react";
import Widget from "../Widget";

interface ChartProperties {
  prize: number;
  title: string;
  bgColor: string;
  styleName: string;
  desc: string;
  percent: string;
  icon: string;
}

interface ChartCardProps {
  chartProperties: ChartProperties;
  children: React.ReactNode;
}

const ChartCard: React.FC<ChartCardProps> = ({ chartProperties, children }) => {
  const [isHide, setIsHide] = useState(false);
  const [properties, setProperties] = useState(chartProperties);

  useEffect(() => {
    setProperties(chartProperties);
  }, [chartProperties]);

  const handleToggle = () => {
    setIsHide((prevIsHide) => !prevIsHide);
  };

  const { prize, title, styleName, desc, bgColor, percent } = properties;

  return (
    <Widget styleName={`gx-card-full`}>
      <div className={`${isHide ? `gx-fillchart gx-bg-${bgColor} gx-fillchart-nocontent` : `gx-fillchart gx-bg-${bgColor} gx-overlay-fillchart`}`}>
        <div className="ant-card-head-title">{title}</div>
        {children}
        <div className="gx-fillchart-content">
          <div className="ant-card-head-title gx-mb-3">{title}</div>
          <h2 className="gx-mb-2 gx-fs-xxxl gx-font-weight-medium">{prize}</h2>
          {/* {percent > 0 && (
            <p className="gx-mb-0 gx-fs-sm">
              <span className={`gx-font-weight-medium gx-fs-md gx-chart-${styleName}`}>
                {percent}
                {percent > 0 ? <i className="icon icon-menu-up gx-fs-sm" /> : null}
              </span>
              {desc}
            </p>
          )} */}
        </div>
        {/* <div className="gx-fillchart-btn-close" onClick={handleToggle}>
          <i className="icon icon-close" />
        </div>
        <div className="gx-fillchart-btn" onClick={handleToggle}>
          <i className={`icon icon-stats gx-fs-xxxl`} />
        </div> */}
      </div>
    </Widget>
  );
};

export default ChartCard;
