import { useEffect, useState } from "react";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import App from "../../routes/index";
import Topbar from "../Topbar";
import AppSidebar from "./AppSidebar";
import { NAV_STYLE_ABOVE_HEADER, NAV_STYLE_BELOW_HEADER, NAV_STYLE_DARK_HORIZONTAL, NAV_STYLE_DEFAULT_HORIZONTAL, NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_INSIDE_HEADER_HORIZONTAL, NAV_STYLE_MINI_SIDEBAR } from "../../constants/ThemeSetting";
import { updateWindowWidth } from "../../appRedux/actions";
import type { RootState } from "../../appRedux/store";
import CommonModal from "../Modal";
import { useHistory } from "react-router-dom";
import addTokenAndExpiryTimeToLocalStorage from "../../util/addTokenAndExpiryTimeToLocalStorage";
import { useIdleTimer } from "react-idle-timer";
import { refreshTokenAPI } from "../../services/userServices";
import CustomizerSystem from "../Customizer";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import { projectName } from "../../util/projectName";

const { Content, Footer } = Layout;

const getContainerClass = (navStyle: string) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return "";
  }
};

const getNavStyles = (navStyle: string) => {
  switch (navStyle) {
    case NAV_STYLE_FIXED:
      return <Topbar />;
    case NAV_STYLE_DRAWER:
      return <Topbar />;
    case NAV_STYLE_MINI_SIDEBAR:
      return <Topbar />;

    default:
      return null;
  }
};

const MainApp = () => {
  // CONSTANTS
  const dispatch = useDispatch();
  const history = useHistory();
  const refreshToken = localStorage.getItem(LocalStorageConstants.REFRESH_TOKEN) as string;

  // STATE
  const { navStyle } = useSelector(({ settings }: RootState) => settings);
  const [isActive, setIsActive] = useState<boolean>(true);
  /* separate state for refresh token expiry reached (Modal)*/
  const [isModalOpen, setIsModalOpen] = useState(false);
  /* separate state for when user is inactivity (Modal) */
  const [isSessionModalOpen, setIsSessionModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(1);

  // HANDLERS
  useIdleTimer({
    onIdle: () => {
      setIsActive(false);
    },
    onActive: () => {
      setIsActive(true);
    },
    timeout: process.env.REACT_APP_SESSION_TIMEOUT ? +process.env.REACT_APP_SESSION_TIMEOUT : 30 * 60 * 1000
    // timeout: process.env.REACT_APP_SESSION_TIMEOUT ? +process.env.REACT_APP_SESSION_TIMEOUT : 900_000
  });

  // EFFECTS

  useEffect(() => {
    const checkLocalStorageAndRedirect = () => {
      // Check if the value you are looking for is not available in localStorage
      if (!localStorage.getItem(LocalStorageConstants.TOKEN)) {
        // Redirect to the desired URL
        window.location.assign("/");
      }
    };

    // Call the function to check localStorage when the component mounts
    checkLocalStorageAndRedirect();

    // Add an event listener to handle changes in localStorage
    const handleStorageChange = (e: StorageEvent) => {
      // Check if the value you are looking for is not available in localStorage
      if (!localStorage.getItem(LocalStorageConstants.TOKEN)) {
        // Redirect to the desired URL
        window.location.assign("/");
      }
    };

    window.addEventListener("storage", handleStorageChange, false);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange, false);
    };
  }, []);

  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  const handleSetSessionModal = () => {
    setIsSessionModalOpen(true);
  };

  const handeleSessionModalOk = () => {
    setIsModalOpen(true);
    localStorage.clear();
    history.push("/");
  };

  const handleOk = () => {
    setIsModalOpen(false);
    localStorage.clear();
    history.push("/");
  };

  // EFFECTS

  useEffect(() => {
    if (isActive) return;
    handleSetSessionModal();
  }, [isActive]);

  useEffect(() => {
    /* calculate difference between two timestamps (expiry timestamp and current timestamp) */
    const currentTimeStamp = moment();
    const tokenExpiryTimeStamp: string = JSON.parse(localStorage.getItem(LocalStorageConstants.TOKEN_EXPIRY_TIMESTAMP) || "{}");
    const differenceInTime = moment(tokenExpiryTimeStamp).diff(currentTimeStamp);
    const differenceInMiliSeconds = moment.duration(differenceInTime).asMilliseconds();

    /* on token expiry we don't want to directly prompt
     the user back to the signIn page, But we will use refresh mechanism just before the expiry time of the token
       as soon as the token is about to expire this callback
        fn registered with the timer will execute and get the new token.
       the expiry time of token and token is updated in localstorage.
    */

    const timer = setTimeout(async () => {
      try {
        const data = await refreshTokenAPI(refreshToken);
        addTokenAndExpiryTimeToLocalStorage(data.token);
        /* we will keep updating after every execution so the timer is registered for the next cycle as well.  */
        setRefresh(Math.random());
      } catch (error) {
        handleShowModal();
      }
    }, differenceInMiliSeconds);

    /* cleanup function which will clear old timers */
    return () => clearTimeout(timer);
  }, [refresh]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  return (
    <>
      <CommonModal resetModal={handeleSessionModalOk} showModal={isSessionModalOpen} modalMessage={"Due to inactivity your session is expired, please login again."} title="Session Expired" />
      <CommonModal resetModal={handleOk} showModal={isModalOpen} modalMessage={"Your session is expired, please login and retry."} title="Session Expired" />
      <Layout className="gx-app-layout">
        <AppSidebar navStyle={navStyle} />
        <CustomizerSystem />
        <Layout>
          {getNavStyles(navStyle)}
          <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
            <App />
            <Footer>
              <div className="gx-layout-footer-content">
                {/* get year from current year(YYYY) - next year (YY) */}
                <div>
                  {/* © {projectName() ?? "TrackMyTree"} {new Date().getFullYear()}-{(new Date().getFullYear() + 1).toString().slice(2)} */}© <span style={{ color: "#1860ab" }}>{projectName()?.slice(0, 5) || "Track"}</span>
                  <span style={{ color: "#46b82f" }}>{projectName()?.slice(5) || "MyTree"}</span>&nbsp;
                  {new Date().getFullYear()}-{(new Date().getFullYear() + 1).toString().slice(2)}
                </div>
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default MainApp;
