import { useState } from "react";
import { Card, Space, Spin, Tag, Modal, Row, Col } from "antd";
import { CloseCircleOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import NoImageAvailable from "../../assets/pictures/No_Image_Available.jpg";

const IMAGE_BASE_URL = process.env.REACT_APP_API_URL?.split("/").slice(0, 3).join("/");

interface TreeDetailsProps {
  tree: {
    latitude: string;
    longitude: string;
    name: string;
    treeTag: string;
    TreeHealthCondition: string;
    ImageUrl: string;
    AreaName: string;
  } | null;
  loading: boolean;
  onClose: () => void;
  onForwardClick: () => void;
  onBackwardClick: () => void;
}

const TreeDetails: React.FC<TreeDetailsProps> = ({ tree, loading, onClose, onForwardClick, onBackwardClick }) => {
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const getTagColor = () => {
    switch (tree?.TreeHealthCondition) {
      case "GOOD":
        return "#64e764";
      case "NEED-ATTENTION":
        return "#F7B239";
      case "VERY GOOD":
        return "#4CB706";
      case "DEAD":
        return "#F75039";
      default:
        return "#64e764";
    }
  };

  const handleImageClick = (url: string) => {
    setSelectedImageUrl(url);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setSelectedImageUrl(null);
    setIsModalVisible(false);
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Card style={{ width: "100%", overflowY: showDetails ? "auto" : "hidden", overflowX: "hidden", height: "60.5vh" }} bodyStyle={{ padding: 0, position: "relative" }} className={styles["custom-scrollbar"]}>
      {loading ? (
        <div className="loading loading--center">
          <Space direction="vertical" className="space--width" size="large">
            <Spin tip="Loading" size="large" />
          </Space>
        </div>
      ) : (
        <>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div style={{ position: "relative", overflowX: "hidden" }}>
                <LeftOutlined className={`${styles["arrow-icon"]} gx-text-primary`} style={{ position: "absolute", top: "30%", left: "10px", zIndex: 5 }} onClick={onBackwardClick} />
                <RightOutlined className={`${styles["arrow-icon"]} gx-text-primary`} style={{ cursor: "pointer", position: "absolute", top: "30%", right: "10px", zIndex: 5 }} onClick={onForwardClick} />
                <CloseCircleOutlined style={{ borderRadius: "25px", border: "1px solid black", background: "black", fontSize: "25px", color: "white", position: "absolute", top: "0px", right: "0px", cursor: "pointer", zIndex: 1, marginTop: showDetails ? "0px" : "0px" }} onClick={onClose} />
                <img
                  style={{ width: "100%", cursor: "pointer", height: "56vh", objectFit: "cover" }}
                  src={`${IMAGE_BASE_URL}${tree?.ImageUrl}`}
                  alt="No img Available"
                  onError={(e: any) => {
                    e.target.src = NoImageAvailable;
                  }}
                  onClick={() => {
                    if (tree?.ImageUrl) {
                      handleImageClick(tree?.ImageUrl);
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div onClick={toggleDetails} style={{ marginLeft: "15px", marginTop: "2px", cursor: "pointer", textDecoration: "underline" }}>
                {showDetails ? <span className="gx-text-primary">see less</span> : <span className="gx-text-primary">see more</span>}
              </div>
            </Col>
          </Row>

          {showDetails && (
            <div className={styles["details-section"]} style={{ overflowX: "hidden" }}>
              <Row gutter={24} style={{ marginBottom: "5px" }}>
                <Col span={12}>
                  <span>Tree Tag</span>
                </Col>
                <Col span={12}>
                  <span className="gx-text-black gx-mb-1">: {tree?.treeTag}</span>
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: "5px" }}>
                <Col span={12}>
                  <span>Tree Name</span>
                </Col>
                <Col span={12}>
                  <span className="gx-text-black gx-mb-1">: {tree?.name}</span>
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: "5px" }}>
                <Col span={12}>
                  <span>Latitude</span>
                </Col>
                <Col span={12}>
                  <span className="gx-text-black gx-mb-1">: {tree?.latitude}</span>
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: "5px" }}>
                <Col span={12}>
                  <span>Longitude</span>
                </Col>
                <Col span={12}>
                  <span className="gx-text-black gx-mb-1">: {tree?.longitude}</span>
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: "5px" }}>
                <Col span={12}>
                  <span>AreaName</span>
                </Col>
                <Col span={12}>
                  <span className="gx-text-black gx-mb-1">: {tree?.AreaName}</span>
                </Col>
              </Row>
              <Row gutter={24} style={{ marginBottom: "5px", marginTop: "10px" }}>
                <Col span={12}>
                  <span>Tree Healthcondition</span>
                </Col>
                <Col span={12}>
                  <span className="gx-text-black gx-mb-1">:</span>
                  <Tag className="gx-rounded-xs" color={getTagColor()} style={{ marginLeft: "5px" }}>
                    {tree?.TreeHealthCondition}
                  </Tag>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}

      <Modal
        visible={isModalVisible}
        onCancel={closeModal}
        footer={null}
        centered
        width={selectedImageUrl ? "500" : "auto"}
        destroyOnClose
        bodyStyle={{
          padding: 0,
          backgroundColor: "rgba(0, 0, 0, 0)"
        }}
        closeIcon={<CloseCircleOutlined style={{ color: "white", background: "black", fontSize: "27px", borderRadius: "20px", border: "1px solid black" }} />}
      >
        {selectedImageUrl && (
          <div
            className="image-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              padding: "20px"
            }}
          >
            <img
              className="gx-rounded-lg"
              style={{
                width: "100%",
                height: "650px",
                objectFit: "contain"
              }}
              src={`${IMAGE_BASE_URL}${selectedImageUrl}`}
              alt="Clicked_Image"
            />
          </div>
        )}
      </Modal>
    </Card>
  );
};

export default TreeDetails;
