import axios from "axios";

//for project id
export const getOneOrgMasterListById = async (TOKEN: string, RoleId: any, isActive: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organizationMaster/${RoleId}?IsActive=${isActive}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

//for ClusterId
export const getOneOrgMasterListByClusterId = async (TOKEN: string, RoleId: any, isActive: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organizationMaster/cluster/${RoleId}?IsActive=${isActive}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

//for VillageId
export const getOneOrgMasterListByVillageId = async (TOKEN: string, RoleId: any, isActive: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organizationMaster/village/${RoleId}?IsActive=${isActive}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

//for project id
export const getFarmerList = async (TOKEN: string, VillageId: any, isActive: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organizationMaster/farmer/${VillageId}?IsActive=${isActive}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
export const addOrgMasterAPI = async (TOKEN: string, formData: object) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/organizationMaster`, formData, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error adding atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateOrgMasterAPI = async (TOKEN: string, id: number, formData: object) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/organizationMaster/${id}`, formData, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    if (response.status !== 200) {
      throw new Error("Error updating atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const deleteOrgMasterAPI = async (TOKEN: string, modelId: number, id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/organizationMaster/${modelId}/${id}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error deleting atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
