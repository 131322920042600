import React, { useState, useEffect, useRef } from "react";
import { Table, Select, Button, Input, Space, Card, message, Spin, Modal, DatePicker } from "antd";
import { DeleteOutlined, SaveOutlined, CloseCircleFilled } from "@ant-design/icons";
import styles from "./index.module.css";
import type { ColumnsType } from "antd/es/table";
import { DataItem, Response } from "../../shared/interfaces/OrgMasterManagement.interfaces";
import { OrgMasterModulesAndMapping } from "../../constants/databaseConstants";
import * as orgmasterServices from "../../services/orgmasterServices";
import { TablePaginationConfig } from "antd/lib/table/interface";
import { Link } from "react-router-dom";
import moment, { Moment } from "moment";
import { LocalStorageConstants } from "../../constants/localStorageConstants";

const OrganizationMasterTables = () => {
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  /* when a module is selected this state stores list of records for that module. */
  const [selectedList, setSelectedList] = useState<DataItem[]>([]);
  /* states for refresh when a module is refreshed */
  const [refresh, setRefresh] = useState<boolean>(true);
  const [refreshProject, setRefreshProject] = useState<boolean>(false);
  const [refreshCluster, setRefreshCluster] = useState<boolean>(false);
  const [refreshVillage, setRefreshVillage] = useState<boolean>(false);
  /* selected module Id */
  const [selectedId, setSelectedId] = useState<number | null>(OrgMasterModulesAndMapping.PROJECT);
  /* Icon list fetch refresh */
  const [refreshIconList, setRefreshIconList] = useState<boolean>(true);
  /* Id of the item to be edited */
  /* searched text state */
  const [searchedText, setSearchedText] = useState<string>("");
  const [editingItemId, setEditingItemId] = useState<number | null>(null);
  /* when item to be deleted the  first columns name is higlighted */
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);
  /* state for pagination */
  const pageSizeOptions = ["10", "20", "50", "100"];
  const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 10 });
  /* for addView false is active and true is inactive */
  const [addView, setAddView] = useState<boolean>(false);
  /* to check if the input on add or edit is correct. */
  const [validateInput, setValidateInput] = useState<boolean>(true);
  /* when user adds a record or edits the details are stored in this state. */
  const defaultStartDateValue = new Date(); // Set your desired default Start Date
  const defaultEndDateValue = new Date(); // Set your desired default End Date
  // Format them to the desired format
  const formattedDefaultStartDate = defaultStartDateValue.toISOString();
  const formattedDefaultEndDate = defaultEndDateValue.toISOString();
  const [newItemData, setNewItemData] = useState<any>({ StartDate: formattedDefaultStartDate, EndDate: formattedDefaultEndDate });
  /* Id of the item to be deleted */
  const [deleteRecordId, setDeleteRecordId] = useState<number | null>(null);
  /* when item to be deleted this state is set to true */
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [projectList, setProjectList] = useState<DataItem[]>([]);
  const [clusterList, setClusterList] = useState<DataItem[]>([]);
  const [villageList, setVillageList] = useState<DataItem[]>([]);
  const [changeValue, setChangeValue] = useState<boolean>(false);
  const [changeValueList, setChangeValueList] = useState<boolean>(true);
  const [selectProjectId, setSelectProjectId] = useState<number | null>(0);
  const [selectClusterId, setSelectClusterId] = useState<number | null>(0);
  const [isActive, setIsactive] = useState<string>("");
  /* loading state */
  const [formLoader, setFormLoader] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const [selectedFromDate, setSelectedFromDate] = useState<Moment | null>(null);
  const formattedDates = moment().clone().seconds(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  /* Fetching all Master Tables list when visited using select */
  useEffect(() => {
    const renderMasterTablesList = async () => {
      try {
        setFormLoader(true);
        setSelectedList([]);
        const response: Response = await orgmasterServices.getOneOrgMasterListById(TOKEN!, selectedId, isActive);

        const res = response.AllRecordsList.map((item: any) => {
          if (selectedId === OrgMasterModulesAndMapping.PROJECT) {
            return {
              ...item,
              ProjectName: item.ProjectName,
              StartDate: item?.StartDate == null ? null : item?.StartDate,
              EndDate: item?.EndDate == null ? null : item?.EndDate,
              SponsorName: item?.SponsorName == null ? "" : item?.SponsorName,
              ImplementationPartnerName: item?.ImplementationPartnerName == null ? "" : item?.ImplementationPartnerName,
              ProjectDescription: item.ProjectDescription,
              id: item.ProjectId
            };
          } else if (selectedId === OrgMasterModulesAndMapping.CLUSTER) {
            return {
              ...item,
              ClusterName: item.ClusterName,
              ClusterId: item.ClusterId,
              ProjectId: item.Project?.ProjectId,
              ProjectName: item.Project.ProjectName,
              ClusterDescription: item.ClusterDescription,
              id: item.ClusterId
            };
          } else if (selectedId === OrgMasterModulesAndMapping.VILLAGE) {
            return {
              ...item,
              key: item.VillageId,
              VillageName: item.VillageName,
              VillageId: item.VillageId,
              VillageDescription: item.VillageDescription,
              ProjectId: item.Project?.ProjectId,
              ProjectName: item.Project.ProjectName,
              ClusterId: item.Cluster?.ClusterId,
              ClusterName: item.Cluster.ClusterName,
              id: item.VillageId
            };
          } else if (selectedId === OrgMasterModulesAndMapping.AREA) {
            return {
              ...item,
              key: item.AreaId,
              AreaName: item.AreaName,
              AreaId: item.AreaId,
              AreaDescription: item.AreaDescription,
              ProjectId: item.Project?.ProjectId,
              ProjectName: item.Project.ProjectName,
              ClusterId: item.Cluster?.ClusterId,
              ClusterName: item.Cluster.ClusterName,
              VillageName: item.Village?.VillageName,
              VillageId: item.Village?.VillageId,
              id: item.AreaId
            };
          } else if (selectedId === OrgMasterModulesAndMapping.CARETAKER) {
            return {
              ...item,
              key: item.CareTakerId,
              CareTakerName: item.CareTakerName,
              Description: item.Description,
              CaretakerPhoneNumber: item.CaretakerPhoneNumber,
              id: item.CareTakerId
            };
          } else if (selectedId === OrgMasterModulesAndMapping.FARMER) {
            return {
              ...item,
              key: item.FarmerId,
              FarmerName: item.FarmerName,
              Description: item.Description,
              FarmerPhoneNumber: item.FarmerPhoneNumber,
              ProjectId: item.Project?.ProjectId,
              ProjectName: item.Project.ProjectName,
              ClusterId: item.Cluster?.ClusterId,
              ClusterName: item.Cluster.ClusterName,
              VillageName: item.Village?.VillageName,
              VillageId: item.Village?.VillageId,
              id: item.FarmerId
            };
          } else {
            return {
              ...item,
              key: item.id
            };
          }
        });
        setFormLoader(false);
        setSelectedList(res);
        setRefresh(false);
      } catch (error: any) {
        setFormLoader(false);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (refresh) await renderMasterTablesList();
    };
    fetchList();
  }, [refresh, refreshIconList, selectedId]);

  /* Project list */
  useEffect(() => {
    const renderProjectList = async () => {
      try {
        setProjectList([]);
        const response: Response = await orgmasterServices.getOneOrgMasterListById(TOKEN!, OrgMasterModulesAndMapping.PROJECT, isActive);
        const res = response.AllRecordsList.map((item: DataItem) => {
          return {
            ...item,
            key: item.ProjectId,
            name: item.ProjectName,
            id: item.ProjectId
          };
        });
        setRefreshProject(false);
        setProjectList(res);
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (refreshProject) {
        await renderProjectList();
      }
    };
    fetchList();
  }, [refreshProject]);

  /* Cluster list */
  useEffect(() => {
    const renderClusterList = async () => {
      try {
        setClusterList([]);

        const response: Response = await orgmasterServices.getOneOrgMasterListByClusterId(TOKEN!, selectProjectId, isActive);

        const res = response.record.map((item: DataItem) => {
          return {
            ...item,
            key: item.ClusterId,
            name: item.ClusterName,
            id: item.ClusterId
          };
        });

        setRefreshCluster(false);
        setClusterList(res);
      } catch (error: any) {
        setRefreshCluster(true);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (refreshCluster) {
        await renderClusterList();
      }
    };
    fetchList();
  }, [refreshCluster, selectProjectId]);

  /* Village list */
  useEffect(() => {
    const renderVillageList = async () => {
      try {
        setVillageList([]);
        const response: Response = await orgmasterServices.getOneOrgMasterListByVillageId(TOKEN!, selectClusterId, isActive);
        const res = response.record.map((item: DataItem) => {
          return {
            ...item,
            key: item.VillageId,
            name: item.VillageName,
            id: item.VillageId
          };
        });

        setRefreshVillage(false);
        setVillageList(res);
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (refreshVillage) {
        await renderVillageList();
      }
    };
    fetchList();
  }, [refreshVillage, selectClusterId]);

  /* define initial state to store data */
  const updateNewItemData = (id: number | null, action: string, itemId: number) => {
    const formattedDate = moment().clone().seconds(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const selectedItem: any = selectedList.find((record) => record.id === itemId);
    switch (id) {
      case OrgMasterModulesAndMapping.PROJECT:
        if (action === "edit" && selectedItem.StartDate !== null) {
          setNewItemData({
            ProjectName: "",
            StartDate: "",
            EndDate: "",
            SponsorName: "",
            ImplementationPartnerName: "",
            ProjectDescription: "",
            id: 0
          });
          break;
        } else {
          setNewItemData({
            ProjectName: "",
            StartDate: String(formattedDate),
            EndDate: String(formattedDate),
            SponsorName: "",
            ImplementationPartnerName: "",
            ProjectDescription: "",
            id: 0
          });
          break;
        }

      case OrgMasterModulesAndMapping.CLUSTER:
        setNewItemData({
          ClusterName: "",
          ClusterDescription: "",
          ProjectName: "",
          ProjectId: "",
          ClusterId: "",
          id: 0
        });
        break;
      case OrgMasterModulesAndMapping.VILLAGE:
        setNewItemData({
          VillageName: "",
          VillageDescription: "",
          ProjectName: "",
          ClusterName: "",
          ProjectId: "",
          ClusterId: "",
          VillageId: "",
          id: 0
        });
        break;
      case OrgMasterModulesAndMapping.AREA:
        setNewItemData({
          AreaName: "",
          AreaDescription: "",
          ProjectName: "",
          ClusterName: "",
          VillageName: "",
          ProjectId: "",
          ClusterId: "",
          VillageId: "",
          AreaId: "",
          id: 0
        });
        break;
      case OrgMasterModulesAndMapping.CARETAKER:
        setNewItemData({
          CareTakerName: "",
          Description: "",
          CaretakerPhoneNumber: "",
          id: 0
        });
        break;
      case OrgMasterModulesAndMapping.FARMER:
        setNewItemData({
          FarmerName: "",
          Description: "",
          FarmerPhoneNumber: "",
          ProjectName: "",
          ClusterName: "",
          VillageName: "",
          ProjectId: "",
          ClusterId: "",
          VillageId: "",
          FarmerId: "",
          id: 0
        });
        break;
      default:
        setNewItemData({});
        break;
    }
  };

  // HANDLERS
  /* select id to get list of master */
  const handleListSelect = (value: number) => {
    /* when add is selected, we need to disable this button.  */
    setSelectedId(value);

    /* clear search input */
    setSearchedText("");
    /* when list is changed entire table should be refreshed as we are navigating. */
    setRefresh(true);
    /* initial state for that particular record is set */
    updateNewItemData(value, "add", 0);
    setEditingItemId(null);
    setHighlightedRow(null);
  };

  /* when user edits the UI should be updated accordingly  */
  const handleEditClick = async (itemId: number | null) => {
    if (itemId) {
      if (addView) {
        setRefresh(true);
      }

      updateNewItemData(selectedId, "edit", itemId);
      setAddView(true);
      setRefreshProject(true);
      setEditingItemId(itemId);
      /* BAG and LAG Icon List fetch*/
      setRefreshIconList(true);
      /* dropdown change to selected value reset */
      setChangeValue(false);

      /* Edit icon click to selected row of airport id get and call api to AirportByTerminals list*/

      const selectedItem: any = selectedList.find((record) => record.id === itemId);

      if (selectedId === OrgMasterModulesAndMapping.VILLAGE) {
        setSelectProjectId(selectedItem?.Project?.ProjectId ?? 0);

        setRefreshCluster(true);
      } else if (selectedId === OrgMasterModulesAndMapping.AREA) {
        setSelectClusterId(selectedItem?.Cluster?.ClusterId ?? 0);
        setSelectProjectId(selectedItem?.Project?.ProjectId ?? 0);
        setRefreshCluster(true);

        setRefreshVillage(true);
      } else if (selectedId === OrgMasterModulesAndMapping.FARMER) {
        setSelectClusterId(selectedItem?.Cluster?.ClusterId ?? 0);
        setSelectProjectId(selectedItem?.Project?.ProjectId ?? 0);
        setRefreshCluster(true);

        setRefreshVillage(true);
      }
    }
  };

  /* handle add and update data */
  const handleSaveClick = async () => {
    try {
      // double check validation name field
      try {
        if (selectedId === OrgMasterModulesAndMapping.PROJECT && !validateInput) await validateName(null, newItemData?.name);
      } catch (error: any) {
        message.destroy();
        message.error(error?.message);
      }

      /* check valid name field empty and not special characters */
      if (!validateInput) return;

      if (selectedId === OrgMasterModulesAndMapping.AREA) {
        if (newItemData?.ProjectId !== 0 && newItemData?.ClusterId !== 0 && newItemData?.VillageId === 0) {
          message.destroy();
          message.error("Please Select Village");
          return;
        }
      }

      if (selectedId === OrgMasterModulesAndMapping.FARMER) {
        if (newItemData?.ProjectId !== 0 && newItemData?.ClusterId !== 0 && newItemData?.VillageId === 0) {
          message.destroy();
          message.error("Please Select Village");
          return;
        }
      }

      setRefresh(false);

      setFormLoader(true);

      if (selectedId !== null) {
        const formData: Record<string, string> = {};

        // Accept select record data and use update no change data field
        const selectedItem = selectedList.find((record) => record.id === editingItemId);

        // Add state-defined data to the formData object
        for (const key in newItemData) {
          if (newItemData.hasOwnProperty(key)) {
            let value: any = newItemData[key];

            if (editingItemId) {
              // Store update data to not change the table
              if (value === undefined || value === null || value === "" || !value) {
                value = selectedItem && selectedItem.hasOwnProperty(key) ? selectedItem[key] : "";
              }
            } else {
              if (value === undefined || value === null || value === "" || !value) {
                value = "";
              }
            }

            formData[key] = value.toString();
          }
        }

        formData.modelId = selectedId.toString();

        // Check if any value in formData is empty and add corresponding data from selectedItem
        for (const key in formData) {
          if (formData.hasOwnProperty(key) && formData[key] === "") {
            if (selectedItem && selectedItem.hasOwnProperty(key)) {
              formData[key] = selectedItem[key]?.toString() || "";
            }
          }
        }

        if (editingItemId) {
          // update api
          const response = await orgmasterServices.updateOrgMasterAPI(TOKEN!, editingItemId, formData);
          message.destroy();
          message.success(response.message);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setFormLoader(false);
          setRefresh(true);
          setAddView(false);
          updateNewItemData(selectedId, "add", 0);
          setEditingItemId(null);
          setRefresh(true);
        } else {
          let isValid = true;

          switch (selectedId) {
            case OrgMasterModulesAndMapping.PROJECT:
              if (!newItemData?.ProjectName || !newItemData?.StartDate || !newItemData?.EndDate || !newItemData?.SponsorName || !newItemData?.ImplementationPartnerName || !newItemData?.ProjectDescription) {
                isValid = false;
              }
              break;
            case OrgMasterModulesAndMapping.CLUSTER:
              if (!newItemData?.ClusterName || !newItemData?.ClusterDescription || !newItemData?.ProjectId) {
                isValid = false;
              }
              break;
            case OrgMasterModulesAndMapping.VILLAGE:
              if (!newItemData?.VillageName || !newItemData?.VillageDescription || !newItemData?.ProjectId || !newItemData?.ClusterId) {
                isValid = false;
              }
              break;
            case OrgMasterModulesAndMapping.AREA:
              if (!newItemData?.AreaName || !newItemData?.AreaDescription || !newItemData?.ProjectId || !newItemData?.ClusterId || !newItemData?.VillageId) {
                isValid = false;
              }
              break;
            case OrgMasterModulesAndMapping.CARETAKER:
              if (!newItemData?.CareTakerName || !newItemData?.Description || !newItemData?.CaretakerPhoneNumber) {
                isValid = false;
              }
              break;
            case OrgMasterModulesAndMapping.FARMER:
              if (!newItemData?.FarmerName || !newItemData?.Description || !newItemData?.ProjectId || !newItemData?.ClusterId || !newItemData?.VillageId || !newItemData?.FarmerPhoneNumber) {
                isValid = false;
              }
              break;
            default:
              break;
          }

          if (!isValid) {
            setFormLoader(false);
            setRefresh(false);
            message.destroy();
            message.error("Please fill in all fields.");
            return;
          }

          // add api
          const response: Response = await orgmasterServices.addOrgMasterAPI(TOKEN!, formData);
          message.destroy();
          message.success(response.message);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setFormLoader(false);
          setRefresh(true);
          setAddView(false);
          updateNewItemData(selectedId, "add", 0);
        }
        setEditingItemId(null);
      }
    } catch (error: any) {
      message.destroy();
      message.error("Error: " + (error?.message || ""));
      setAddView(true);
      setFormLoader(false);
    }
  };

  /* handle cancel button click to close edit or add table cell */
  const handleCancelClick = () => {
    setRefresh(true);
    setAddView(false);
    setEditingItemId(null);
  };

  /* SAFE CHECK handle display column name bases on selectId */
  const getDisplayedColumns = (selectedId: number | null): string[] => {
    switch (selectedId) {
      case OrgMasterModulesAndMapping.PROJECT:
        return ["ProjectName", "StartDate", "EndDate", "SponsorName", "ImplementationPartnerName", "ProjectDescription"];
        return ["ClusterName", "ClusterDescription", "ProjectName"];
      case OrgMasterModulesAndMapping.VILLAGE:
        return ["VillageName", "VillageDescription", "ProjectName", "ClusterName"];
      case OrgMasterModulesAndMapping.AREA:
        return ["AreaName", "AreaDescription", "ProjectName", "ClusterName", "VillageName"];
      case OrgMasterModulesAndMapping.CARETAKER:
        return ["CareTakerName", "Description", "CaretakerPhoneNumber"];
      case OrgMasterModulesAndMapping.FARMER:
        return ["FarmerName", "Description", "FarmerPhoneNumber", "ProjectName", "ClusterName", "VillageName"];
      default:
        return []; // Default empty array
    }
  };

  /* handle new table cell on 1st table row to add data */
  const handleAddClick = () => {
    setPagination({ current: 1, pageSize: 10 });

    setAddView(true);

    /* BAG and LAG Icon List fetch*/
    setRefreshIconList(true);

    /* clear search input */
    setSearchedText("");

    /* when atrs is selected airport list API is called. */
    setRefreshProject(true);

    let newItem: DataItem;

    const formattedDate = moment().clone().seconds(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    switch (selectedId) {
      case OrgMasterModulesAndMapping.PROJECT:
        newItem = {
          id: 0,
          ProjectName: "",
          StartDate: String(formattedDate),
          EndDate: String(formattedDate),
          SponsorName: "",
          ImplementationPartnerName: "",
          ProjectDescription: ""
        };
        break;
      case OrgMasterModulesAndMapping.CLUSTER:
        newItem = {
          id: 0,
          ClusterName: "",
          ClusterDescription: "",
          ProjectName: ""
        };
        break;
      case OrgMasterModulesAndMapping.VILLAGE:
        newItem = {
          id: 0,
          VillageName: "",
          VillageDescription: "",
          ProjectName: "",
          ClusterName: ""
        };
        break;
      case OrgMasterModulesAndMapping.AREA:
        newItem = {
          id: 0,
          AreaName: "",
          AreaDescription: "",
          ProjectName: "",
          ClusterName: "",
          VillageName: ""
        };
        break;
      case OrgMasterModulesAndMapping.CARETAKER:
        newItem = {
          id: 0,
          CareTakerName: "",
          Description: "",
          CaretakerPhoneNumber: ""
        };
        break;
      case OrgMasterModulesAndMapping.FARMER:
        newItem = {
          id: 0,
          FarmerName: "",
          Description: "",
          FarmerPhoneNumber: "",
          ProjectName: "",
          ClusterName: "",
          VillageName: ""
        };
        break;
      default:
        newItem = {};
        break;
    }

    /* SAFE CHECK for a particular table accept these only when adding or editing a record */
    const displayedColumns = getDisplayedColumns(selectedId);

    Object.keys(newItem).forEach((column) => {
      if (!displayedColumns?.includes(column)) {
        // delete newItem[column];
      }
    });

    setSelectedList([newItem, ...selectedList]);
    setEditingItemId(newItem.id as number | null);
  };

  /* when adding or editing the columns data is stored in the setNewItemData state */
  const handleCellChange = (value: any, record: DataItem, dataIndex: keyof DataItem) => {
    // Validate the CaretakerPhoneNumber length
    if (dataIndex === "CaretakerPhoneNumber" && value.length === 11) {
      // Display an error message or handle the validation error as needed
      // message.error("CaretakerPhoneNumber must be 10 characters long.");
      return; // Do not update the state if the validation fails
    }
    if (dataIndex === "FarmerPhoneNumber" && value.length === 11) {
      // Display an error message or handle the validation error as needed
      // message.error("FarmerPhoneNumber must be 10 characters long.");
      return; // Do not update the state if the validation fails
    }

    switch (dataIndex) {
      case "ProjectName":
        setNewItemData((prevData: any) => {
          if (selectedId === OrgMasterModulesAndMapping.CLUSTER || selectedId === OrgMasterModulesAndMapping.VILLAGE || selectedId === OrgMasterModulesAndMapping.AREA || selectedId === OrgMasterModulesAndMapping.FARMER) {
            setSelectProjectId(value);
            setRefreshCluster(true);
            return {
              ...prevData,
              ProjectName: value,
              ProjectId: value,
              id: 0,
              ClusterId: 0,

              VillageId: 0
            };
          } else {
            return {
              ...prevData,
              ProjectName: value,
              ProjectId: value,
              id: 0
            };
          }
        });
        break;

      case "StartDate":
        setNewItemData((prevData: any) => {
          const formattedFromDate = value ? moment(value).clone().seconds(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : "";

          return {
            ...prevData,
            StartDate: formattedFromDate
            // EndDate: formattedFromDate
          };
        });
        break;

      case "EndDate":
        setNewItemData((prevData: any) => {
          const formattedToDate = value ? moment(value).clone().seconds(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : "";
          return {
            ...prevData,
            EndDate: formattedToDate
          };
        });
        break;

      case "SponsorName":
        setNewItemData((prevData: any) => ({
          ...prevData,
          SponsorName: value
        }));
        break;

      case "ImplementationPartnerName":
        setNewItemData((prevData: any) => ({
          ...prevData,
          ImplementationPartnerName: value
        }));
        break;

      case "ProjectDescription":
        setNewItemData((prevData: any) => ({
          ...prevData,
          ProjectDescription: value,
          id: 0
        }));
        break;

      case "ClusterName":
        setNewItemData((prevData: any) => {
          if (selectedId === OrgMasterModulesAndMapping.VILLAGE || selectedId === OrgMasterModulesAndMapping.AREA || selectedId === OrgMasterModulesAndMapping.FARMER) {
            setSelectClusterId(value);

            setRefreshVillage(true);
            return {
              ...prevData,

              ClusterName: value,
              ClusterId: value,
              id: 0,

              VillageId: 0
            };
          } else {
            return {
              ...prevData,
              ClusterName: value,
              ClusterId: value,
              id: 0
            };
          }
        });
        break;

      case "ClusterDescription":
        setNewItemData((prevData: any) => ({
          ...prevData,
          ClusterDescription: value,
          id: 0
        }));
        break;
      case "VillageName":
        setNewItemData((prevData: any) => {
          if (selectedId === OrgMasterModulesAndMapping.AREA || selectedId === OrgMasterModulesAndMapping.FARMER) {
            return {
              ...prevData,

              VillageName: value,
              VillageId: value,
              id: 0,

              AreaId: 0
            };
          } else {
            return {
              ...prevData,
              VillageName: value,
              VillageId: value,
              id: 0
            };
          }
        });
        break;
      case "VillageDescription":
        setNewItemData((prevData: any) => ({
          ...prevData,
          VillageDescription: value,
          id: 0
        }));
        break;
      case "AreaName":
        setNewItemData((prevData: any) => ({
          ...prevData,
          AreaName: value,
          id: 0
        }));
        break;
      case "AreaDescription":
        setNewItemData((prevData: any) => ({
          ...prevData,
          AreaDescription: value,
          id: 0
        }));
        break;
      case "CareTakerName":
        setNewItemData((prevData: any) => ({
          ...prevData,
          CareTakerName: value,
          id: 0
        }));
        break;
      case "Description":
        setNewItemData((prevData: any) => ({
          ...prevData,
          Description: value,
          id: 0
        }));
        break;
      case "CaretakerPhoneNumber":
        setNewItemData((prevData: any) => ({
          ...prevData,
          CaretakerPhoneNumber: value,
          id: 0
        }));
        break;
      case "FarmerName":
        setNewItemData((prevData: any) => ({
          ...prevData,
          FarmerName: value,
          id: 0
        }));
        break;
      case "Description":
        setNewItemData((prevData: any) => ({
          ...prevData,
          Description: value,
          id: 0
        }));
        break;
      case "FarmerPhoneNumber":
        setNewItemData((prevData: any) => ({
          ...prevData,
          FarmerPhoneNumber: value,
          id: 0
        }));
        break;
      default:
        break;
    }

    const newData: any = selectedList.map((item) => {
      if (item.id === record.id) {
        item[dataIndex] = value;
      }
      return item;
    });
    setSelectedList(newData);
  };

  /* handle table changes pagination and others */
  const handleChange = (pagination: TablePaginationConfig): void => {
    setPagination(pagination);
  };

  /* editing by search on time row */
  const selectedListRef = useRef(selectedList);
  useEffect(() => {
    if (editingItemId && searchedText) {
      const itemIndex = selectedList.findIndex((item: any) => item.id === editingItemId);
      if (itemIndex > -1) {
        const updatedList = [...selectedList];
        const editedItem = updatedList.splice(itemIndex, 1)[0];
        updatedList.unshift(editedItem);
        setSelectedList(updatedList);
        setPagination({ current: 1, pageSize: 10 });
      }
    }
    /* IMP Not add selectedList dependency  */
  }, [editingItemId, searchedText]);

  useEffect(() => {
    if (selectedList !== selectedListRef.current) {
      selectedListRef.current = selectedList;
    }
  }, [selectedList]);

  /* handle table filtering data from search text fields */
  const filterDataSource = (data: any) => {
    const searchText = searchedText.toLowerCase();
    return data.filter((item: any) => {
      switch (selectedId) {
        case OrgMasterModulesAndMapping.PROJECT:
          return String(item.ProjectName).toLowerCase()?.includes(searchText) || String(item.SponsorName).toLowerCase()?.includes(searchText) || String(item.ImplementationPartnerName).toLowerCase()?.includes(searchText) || String(item.ProjectDescription).toLowerCase()?.includes(searchText);
        case OrgMasterModulesAndMapping.CLUSTER:
          return String(item.ClusterName).toLowerCase()?.includes(searchText) || String(item.ClusterDescription).toLowerCase()?.includes(searchText) || String(item.ProjectName).toLowerCase()?.includes(searchText);
        case OrgMasterModulesAndMapping.VILLAGE:
          return String(item.VillageName).toLowerCase()?.includes(searchText) || String(item.VillageDescription).toLowerCase()?.includes(searchText) || String(item.ProjectName).toLowerCase()?.includes(searchText) || String(item.ClusterName).toLowerCase()?.includes(searchText);
        case OrgMasterModulesAndMapping.AREA:
          return String(item.AreaName).toLowerCase()?.includes(searchText) || String(item.AreaDescription).toLowerCase()?.includes(searchText) || String(item.ProjectName).toLowerCase()?.includes(searchText) || String(item.ClusterName).toLowerCase()?.includes(searchText) || String(item.VillageName).toLowerCase()?.includes(searchText);
        case OrgMasterModulesAndMapping.CARETAKER:
          return String(item.CareTakerName).toLowerCase()?.includes(searchText) || String(item.Description).toLowerCase()?.includes(searchText) || String(item.CaretakerPhoneNumber).toLowerCase()?.includes(searchText);
        case OrgMasterModulesAndMapping.FARMER:
          return String(item.FarmerName).toLowerCase()?.includes(searchText) || String(item.Description).toLowerCase()?.includes(searchText) || String(item.FarmerPhoneNumber).toLowerCase()?.includes(searchText) || String(item.ProjectName).toLowerCase()?.includes(searchText) || String(item.ClusterName).toLowerCase()?.includes(searchText) || String(item.VillageName).toLowerCase()?.includes(searchText);
        default:
          return String(item.ClusterName).toLowerCase()?.includes(searchText) || String(item.ClusterDescription).toLowerCase()?.includes(searchText) || String(item.ProjectName).toLowerCase()?.includes(searchText);
      }
    });
  };

  /* handle confirm model to yes or no */
  const showDeleteConfirm = (itemId: number) => {
    setDeleteRecordId(itemId);
    setOpenModal(true);
    setHighlightedRow(itemId);
  };

  /* handle delete record */
  const handleDeleteClick = async (itemId: number | null) => {
    setOpenModal(false);
    setRefresh(false);
    if (itemId) {
      try {
        const response: Response = await orgmasterServices.deleteOrgMasterAPI(TOKEN!, selectedId!, itemId);
        message.destroy();
        message.success(response.message);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setRefresh(true);
      } catch (error: any) {
        setRefresh(false);
        setHighlightedRow(null);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    }
  };

  /* handle cancel for modal clicks No click or close icon click */
  const handleDeleteCancelClick = () => {
    setOpenModal(false);
    setHighlightedRow(null);
  };

  /* handler to validate special characters in name */
  const validateName = (_: any, value: any) => {
    const regex = /^[a-zA-Z0-9\s]+$/; // Regular expression to allow only numbers and characters
    if (!regex.test(value)) {
      return Promise.reject(new Error("Please enter a valid name without special characters."));
    }
    return Promise.resolve();
  };

  const disabledFromDate = (current: Moment | null): boolean => {
    return !!current && current > moment().endOf("day");
  };

  const disabledToDate = (current: Moment | null, fromDate: Moment | null): boolean => {
    // Get the selected date from disabledFromDate
    const selectedFromDate = fromDate || moment();
    // Disable dates before the selected date
    return !!current && current < moment(selectedFromDate).startOf("day");
  };

  /* columns for all modules are defined here */
  const columns: ColumnsType<any> =
    /* safe check added to see if API data is fetched */
    selectedList.length > 0
      ? Object.keys(selectedList[0])
          .filter((key) => {
            switch (selectedId) {
              case OrgMasterModulesAndMapping.PROJECT:
                return key === "ProjectName" || key === "StartDate" || key === "EndDate" || key === "SponsorName" || key === "ImplementationPartnerName" || key === "ProjectDescription";
              case OrgMasterModulesAndMapping.CLUSTER:
                return key === "ClusterName" || key === "ClusterDescription" || key === "ProjectName";
              case OrgMasterModulesAndMapping.VILLAGE:
                return key === "VillageName" || key === "VillageDescription" || key === "ProjectName" || key === "ClusterName";
              case OrgMasterModulesAndMapping.AREA:
                return key === "AreaName" || key === "AreaDescription" || key === "ProjectName" || key === "ClusterName" || key === "VillageName";
              case OrgMasterModulesAndMapping.CARETAKER:
                return key === "CareTakerName" || key === "Description" || key === "CaretakerPhoneNumber";
              case OrgMasterModulesAndMapping.FARMER:
                return key === "FarmerName" || key === "Description" || key === "FarmerPhoneNumber" || key === "ProjectName" || key === "ClusterName" || key === "VillageName";
              default:
                return false;
            }
          })
          .map((key, index) => {
            let columnName = key;
            let order = index + 1; // Initialize order with index value
            switch (key) {
              case "ProjectName":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.PROJECT:
                    columnName = "Project Name";
                    // order = 1;
                    break;
                  case OrgMasterModulesAndMapping.CLUSTER:
                    columnName = "Project Name";
                    // order = 2;
                    break;
                  case OrgMasterModulesAndMapping.VILLAGE:
                    columnName = "Project Name";
                    // order = 3;
                    break;
                  case OrgMasterModulesAndMapping.AREA:
                    columnName = "Project Name";
                    order = 3;
                    break;
                  case OrgMasterModulesAndMapping.FARMER:
                    columnName = "Project Name";
                    order = 4;
                }
                break;
              case "StartDate":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.PROJECT:
                    columnName = "Start Date";
                    // order = 1;
                    break;
                }
                break;
              case "EndDate":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.PROJECT:
                    columnName = "End Date";
                    // order = 1;
                    break;
                }
                break;
              case "SponsorName":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.PROJECT:
                    columnName = "Sponsor Name";
                    // order = 1;
                    break;
                }
                break;
              case "ImplementationPartnerName":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.PROJECT:
                    columnName = "Implementation Partner";
                    // order = 1;
                    break;
                }
                break;
              case "ProjectDescription":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.PROJECT:
                    columnName = "Project Description";
                    order = 7;
                    break;
                }
                break;

              case "ClusterName":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.CLUSTER:
                    columnName = "Cluster Name";
                    // order = 2;
                    break;
                  case OrgMasterModulesAndMapping.VILLAGE:
                    columnName = "Cluster Name";
                    // order = 3;
                    break;
                  case OrgMasterModulesAndMapping.AREA:
                    columnName = "Cluster Name";
                    order = 4;
                    break;
                  case OrgMasterModulesAndMapping.FARMER:
                    columnName = "Cluster Name";
                    order = 5;
                    break;
                }
                break;
              case "ClusterDescription":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.CLUSTER:
                    columnName = "Cluster Description";
                    // order = 2;
                    break;
                }
                break;
              case "VillageName":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.VILLAGE:
                    columnName = "Village Name";
                    // order = 3;
                    break;
                  case OrgMasterModulesAndMapping.AREA:
                    columnName = "Village Name";
                    order = 5;
                    break;
                  case OrgMasterModulesAndMapping.FARMER:
                    columnName = "Village Name";
                    order = 6;
                    break;
                }
                break;
              case "VillageDescription":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.VILLAGE:
                    columnName = "Village Description";
                    // order = 3;
                    break;
                }
                break;
              case "AreaName":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.AREA:
                    columnName = "Area Name";
                    // order = 4;
                    break;
                }
                break;
              case "AreaDescription":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.AREA:
                    columnName = "Area Description";
                    // order = 4;
                    break;
                }
                break;
              case "CareTakerName":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.CARETAKER:
                    columnName = "Caretaker Name";
                    // order = 5;
                    break;
                }
                break;
              case "CaretakerPhoneNumber":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.CARETAKER:
                    columnName = "Caretaker Phone Number";
                    // order = 5;
                    break;
                }
                break;
              case "FarmerName":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.FARMER:
                    columnName = "Farmer Name";
                    // order = 4;
                    break;
                }
                break;
              case "FarmerPhoneNumber":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.FARMER:
                    columnName = "Farmer Phone Number";
                    // order = 5;
                    break;
                }
                break;
              case "Description":
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.FARMER:
                    columnName = "Description";
                    // order = 4;
                    break;
                }
                break;
              default:
                columnName = key;
            }

            columnName = columnName.charAt(0).toUpperCase() + columnName.slice(1); // Capitalize the first letter

            return {
              title: columnName,
              dataIndex: key,
              order,
              sorter: (a: any, b: any) => {
                switch (selectedId) {
                  case OrgMasterModulesAndMapping.PROJECT:
                    return a.ProjectName.localeCompare(b.ProjectName) || a.StartDate.localeCompare(b.StartDate) || a.EndDate.localeCompare(b.EndDate) || a.SponsorName.localeCompare(b.SponsorName) || a.ImplementationPartnerName.localeCompare(b.ImplementationPartnerName) || a.ProjectDescription.localeCompare(b.ProjectDescription);
                  case OrgMasterModulesAndMapping.CLUSTER:
                    return a.ClusterName.localeCompare(b.ClusterName) || a.ClusterDescription.localeCompare(b.ClusterDescription) || a.ProjectName.localeCompare(b.ProjectName);
                  case OrgMasterModulesAndMapping.VILLAGE:
                    return a.VillageName.localeCompare(b.VillageName) || a.VillageDescription.localeCompare(b.VillageDescription) || a.ProjectName.localeCompare(b.ProjectName) || a.ClusterName.localeCompare(b.ClusterName);
                  case OrgMasterModulesAndMapping.AREA:
                    return a.AreaName.localeCompare(b.AreaName) || a.AreaDescription.localeCompare(b.AreaDescription) || a.ProjectName.localeCompare(b.ProjectName) || a.ClusterName.localeCompare(b.ClusterName) || a.VillageName.localeCompare(b.VillageName);
                  case OrgMasterModulesAndMapping.CARETAKER:
                    return a.CareTakerName.localeCompare(b.CareTakerName) || a.Description.localeCompare(b.Description) || a.CaretakerPhoneNumber.localeCompare(b.CaretakerPhoneNumber);
                  case OrgMasterModulesAndMapping.FARMER:
                    return a.FarmerName.localeCompare(b.FarmerName) || a.Description.localeCompare(b.Description) || a.FarmerPhoneNumber.localeCompare(b.FarmerPhoneNumber) || a.ProjectName.localeCompare(b.ProjectName) || a.ClusterName.localeCompare(b.ClusterName) || a.VillageName.localeCompare(b.VillageName);
                  default:
                    return false;
                }
              },
              render: (text: any, record: any) => {
                const renderInput = (value: any, key: any) => (
                  <Input
                    value={String(value)}
                    name={String(key)}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (!inputValue.startsWith(" ")) {
                        handleCellChange(inputValue, record, key);
                      }
                    }}
                    onBlur={async (e) => {
                      try {
                        const inputValue = e.target.value;
                        if (!inputValue.startsWith(" ") && (key === "name" || key === "ProjectName")) {
                          await validateName(null, inputValue);
                        }
                        if (!inputValue.trim()) {
                          throw new Error(`Please Enter ${key}`); // Throw error for empty input
                        }

                        setValidateInput(true); // Validation successful
                      } catch (error: any) {
                        setValidateInput(false); // Handle validation error
                        message.destroy();
                        message.error(error.message);
                      }
                    }}
                  />
                );

                const renderSelect = (value: any, key: any, options: any) => {
                  return (
                    <Select
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      optionFilterProp="children"
                      className={`${styles["input-dropdown"]}`}
                      placeholder={`Select ${key} name`}
                      // disabled={isFarmerUpdate}
                      onChange={(value: any) => {
                        if (key === "ProjectName") {
                          setChangeValue(true);
                          setChangeValueList(true);
                        } else if (key === "ClusterName") {
                          setChangeValue(false);
                          setChangeValueList(true);
                        } else if (key === "VillageName") {
                          setChangeValueList(false);
                        }
                        handleCellChange(value === `Select ${key} name` && (changeValue || changeValueList) ? 0 : value, record, key);
                      }}
                      value={value ? value : changeValue || changeValueList ? `Select ${key} ` : `Select ${key} `}
                      filterOption={(input: any, option: any) => {
                        const children = option?.children as unknown;
                        if (typeof children === "string") {
                          return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }
                        return false;
                      }}
                    >
                      {options?.map((option: any, index: any) => (
                        <Select.Option value={option.id} key={index}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  );
                };

                const renderText = (value: any) => (
                  <Link to={``}>
                    <span className="entity-name-selected">{String(value)}</span>
                  </Link>
                );

                /* nested switch case to check for edit and delete */

                switch (true) {
                  case editingItemId === record?.id:
                    switch (selectedId) {
                      case OrgMasterModulesAndMapping.PROJECT:
                        if (key === "StartDate") {
                          return <DatePicker format="DD-MM-YYYY" allowClear={false} disabledDate={(value) => disabledFromDate(value)} value={moment.utc(record?.StartDate ?? formattedDates)} placeholder="Select Start Date" onChange={(value) => handleCellChange(value, record, key)} />;
                        } else if (key === "EndDate") {
                          return <DatePicker format="DD-MM-YYYY" allowClear={false} disabledDate={(current) => disabledToDate(current, selectedFromDate || newItemData?.StartDate)} value={moment.utc(record?.EndDate ?? formattedDates)} placeholder="Select End Date" onChange={(value) => handleCellChange(value, record, key)} />;
                        } else {
                          return renderInput(text, key);
                        }

                      case OrgMasterModulesAndMapping.CLUSTER:
                        if (key === "ProjectName") {
                          return renderSelect(record.ProjectName, "ProjectName", projectList);
                        } else if (key === "ProjectDescription") {
                          return renderSelect(record.ProjectDescription, "ProjectDescription", projectList);
                        } else {
                          return renderInput(text, key);
                        }

                      case OrgMasterModulesAndMapping.VILLAGE:
                        if (key === "ProjectName") {
                          return renderSelect(record.ProjectName, "ProjectName", projectList);
                        } else if (key === "ProjectDescription") {
                          return renderSelect(record.ProjectDescription, "ProjectDescription", projectList);
                        } else if (key === "ClusterName") {
                          return renderSelect(record.ClusterName, "ClusterName", clusterList);
                        } else if (key === "ClusterDescription") {
                          return renderSelect(record.ClusterDescription, "ClusterDescription", clusterList);
                        } else {
                          return renderInput(text, key);
                        }

                      case OrgMasterModulesAndMapping.AREA:
                        if (key === "ProjectName") {
                          return renderSelect(record.ProjectName, "ProjectName", projectList);
                        } else if (key === "ProjectDescription") {
                          return renderSelect(record.ProjectDescription, "ProjectDescription", projectList);
                        } else if (key === "ClusterName") {
                          return renderSelect(record.ClusterName, "ClusterName", clusterList);
                        } else if (key === "ClusterDescription") {
                          return renderSelect(record.ClusterDescription, "ClusterDescription", clusterList);
                        } else if (key === "VillageName") {
                          return renderSelect(record.VillageName, "VillageName", villageList);
                        } else if (key === "VillageDescription") {
                          return renderSelect(record.VillageDescription, "VillageDescription", villageList);
                        } else {
                          return renderInput(text, key);
                        }

                      case OrgMasterModulesAndMapping.CARETAKER:
                        return renderInput(text, key);
                      case OrgMasterModulesAndMapping.FARMER:
                        if (key === "ProjectName") {
                          return renderSelect(record.ProjectName, "ProjectName", projectList);
                        } else if (key === "ProjectDescription") {
                          return renderSelect(record.ProjectDescription, "ProjectDescription", projectList);
                        } else if (key === "ClusterName") {
                          return renderSelect(record.ClusterName, "ClusterName", clusterList);
                        } else if (key === "ClusterDescription") {
                          return renderSelect(record.ClusterDescription, "ClusterDescription", clusterList);
                        } else if (key === "VillageName") {
                          return renderSelect(record.VillageName, "VillageName", villageList);
                        } else if (key === "VillageDescription") {
                          return renderSelect(record.VillageDescription, "VillageDescription", villageList);
                        } else {
                          return renderInput(text, key);
                        }
                      default:
                        return renderInput(text, key);
                    }
                  case highlightedRow === record.id:
                    if (key === "name" || key === "ProjectName" || key === "SponsorName" || key === "ImplementationPartnerName" || key === "ProjectDescription" || key === "ClusterName" || key === "ClusterDescription" || key === "VillageName" || key === "VillageDescription" || key === "AreaName" || key === "AreaDescription" || key === "CareTakerName" || key === "Description" || key === "CaretakerPhoneNumber" || key === "FarmerName" || key === "FarmerPhoneNumber") {
                      return renderText(text);
                    } else if (key === "StartDate" || key === "EndDate") {
                      return text ? renderText(moment(text).format("DD-MM-YYYY")) : "";
                    }

                    return text;
                  default:
                    if (key === "StartDate" || key === "EndDate") {
                      return text ? moment.utc(text).format("DD-MM-YYYY") : "";
                    } else {
                      return text;
                    }
                }
              }
            };
          })
          .sort((a, b) => a.order - b.order)
      : [];

  /* extra column added for action. */
  if (selectedList.length > 0) {
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, record: any) => {
        if (editingItemId === record.id) {
          return (
            <Space>
              <span className="gx-link" onClick={handleSaveClick}>
                <SaveOutlined className={`${styles["icon-save"]}`} />
              </span>
              <span className="gx-link" onClick={handleCancelClick}>
                <CloseCircleFilled className={`${styles["icon-delete-close"]}`} />
              </span>
            </Space>
          );
        }
        return (
          <Space>
            <span className="gx-link" onClick={() => handleEditClick(record?.id)}>
              <i className={`icon icon-edit ${styles["icon-edit"]}`} />
            </span>
            <span className="gx-link" onClick={() => showDeleteConfirm(record?.id)}>
              <DeleteOutlined className={`${styles["icon-delete-close"]}`} />
            </span>
          </Space>
        );
      }
    });
  }

  return (
    <div>
      {formLoader ? (
        <div className="loading loading--center">
          <Space direction="vertical" className="space--width" size="large">
            <Spin tip="Loading" size="large" />
          </Space>
        </div>
      ) : (
        <Card title="Master Management" className="card--height">
          <div className={`${styles["div-container"]}`}>
            <div style={{ width: "30%" }}>
              <Select
                getPopupContainer={(trigger) => trigger.parentElement}
                className={`${styles["select-master-dropdown"]}`}
                placeholder="Select Master Name"
                onChange={(value: number) => {
                  handleListSelect(value);
                  setAddView(false);
                }}
                value={selectedId}
              >
                <Select.Option value={OrgMasterModulesAndMapping.PROJECT}>Projects</Select.Option>
                <Select.Option value={OrgMasterModulesAndMapping.CLUSTER}>Clusters</Select.Option>
                <Select.Option value={OrgMasterModulesAndMapping.VILLAGE}>Villages</Select.Option>
                <Select.Option value={OrgMasterModulesAndMapping.AREA}>Areas</Select.Option>
                <Select.Option value={OrgMasterModulesAndMapping.CARETAKER}>Caretakers</Select.Option>
                <Select.Option value={OrgMasterModulesAndMapping.FARMER}>Farmers</Select.Option>
              </Select>
            </div>

            <div className={` ${styles["search-container"]}`}>
              <Input.Search
                className={styles["input-search"]}
                placeholder={`Please Enter ${selectedId === OrgMasterModulesAndMapping.PROJECT ? "Project" : selectedId === OrgMasterModulesAndMapping.CLUSTER ? "Cluster" : selectedId === OrgMasterModulesAndMapping.VILLAGE ? "Village" : selectedId === OrgMasterModulesAndMapping.AREA ? "Area" : selectedId === OrgMasterModulesAndMapping.CARETAKER ? "Caretaker" : selectedId === OrgMasterModulesAndMapping.FARMER ? "Farmer" : "Item"} Name.`}
                onSearch={(value) => {
                  setSearchedText(value);
                }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (!inputValue.startsWith(" ")) {
                    setSearchedText(inputValue);
                  }
                }}
                value={searchedText}
              />
              <Button type="primary" disabled={addView} className={`${styles["add-button"]}`} onClick={handleAddClick}>
                Add
              </Button>
            </div>
          </div>

          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={editingItemId && searchedText ? selectedList : filterDataSource(selectedList)}
            onChange={handleChange}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              pageSizeOptions
            }}
            bordered
          />
        </Card>
      )}

      <Modal
        title={`Are you sure you want to delete this ${selectedId === OrgMasterModulesAndMapping.PROJECT ? "Project" : selectedId === OrgMasterModulesAndMapping.CLUSTER ? "Cluster" : selectedId === OrgMasterModulesAndMapping.VILLAGE ? "Village" : selectedId === OrgMasterModulesAndMapping.AREA ? "Area" : selectedId === OrgMasterModulesAndMapping.CARETAKER ? "Care Taker" : selectedId === OrgMasterModulesAndMapping.FARMER ? "Farmer" : "Item"} ?`}
        open={openModal}
        onOk={() => handleDeleteClick(deleteRecordId)}
        onCancel={handleDeleteCancelClick}
        okText="Yes"
        cancelText="No"
        width="500px"
        destroyOnClose
        maskClosable={true}
        bodyStyle={{ padding: 0 }}
        wrapClassName="delete-modal"
      />
    </div>
  );
};

export default OrganizationMasterTables;
