import { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import { useHistory } from "react-router-dom";
import ssf_tree from "../../assets/pictures/ssftree.png";
import styles from "./SignIn.module.css";
import * as authenticationService from "../../services/authenticationService";
import * as userServices from "../../services/userServices";
import addTokenAndExpiryTimeToLocalStorage from "../../util/addTokenAndExpiryTimeToLocalStorage";
import { getLatestDeployementLogAPI } from "../../services/aboutServices";
import { DeploymentLog } from "../../shared/interfaces/About.interface";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import { normalizeWhitespace } from "../../util/InputWhiteSpaceRemove";
import { projectName } from "../../util/projectName";
import Links from "./Links";
import TrackMyTreeLogo from "../../assets/pictures/trackMyTreeLogo.jpg";

interface UserLoginData {
  token: string;
  refreshToken: string;
  user: {
    FirstName: string;
    LastName: string;
    Role: string;
  };
  message?: string;
}

const SignIn = () => {
  // CONSTANTS
  const history = useHistory();
  const [version, setVersion] = useState<any | null>();
  const [tokenValue, setTokenValue] = useState("");
  const [showForgotPasswordMsg, setShowForgotPasswordMsg] = useState(false);
  const [showLinks, setShowLinks] = useState(false);

  // EFFECTS
  /* 
  If user revists the page on root url. this component is mounted, If token exists
  we move to the application url's.
  */

  useEffect(() => {
    const token = localStorage.getItem(LocalStorageConstants.TOKEN);
    if (token) history.push("/home");
  }, []);

  useEffect(() => {
    const fetchDeploymentLog = async () => {
      try {
        const response: { message: string; deploymentLog: DeploymentLog } = await getLatestDeployementLogAPI(); // Replace with your API call
        setVersion(response?.deploymentLog);
      } catch (error: any) {
        setVersion(null);
        message.destroy();
        //message.error("Error: " + (error?.message || ""));
      }
    };

    fetchDeploymentLog();
  }, []);

  const renderControlList = async (TOKEN: string | null) => {
    try {
      const response = await userServices.getAccessControlAPI(TOKEN);
      if (response?.roleFormMapping) {
        localStorage.setItem(LocalStorageConstants.FORM, JSON.stringify(response?.roleFormMapping));

        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      //message.error(error)
    }
  };

  // HANDLERS
  const handleFinish = async (formObj: { MobileNumber: string; Password: string; message: string }) => {
    try {
      const loginData: UserLoginData = await authenticationService.signIn(formObj.MobileNumber, formObj.Password, formObj.message);
      /* 
      - localstorage will have 3 key-values set, one is token and other is user (user related data).
      - tokenExpiryTimeStamp will have a timestamp of 1 hour from currentDate. (stored in GMT timezone)
      */

      /* store airport and terminal list data to localstorage */
      await renderControlList(loginData.token);

      /* setting values in localstorage */
      addTokenAndExpiryTimeToLocalStorage(loginData.token);
      localStorage.setItem(LocalStorageConstants.REFRESH_TOKEN, loginData.refreshToken);

      localStorage.setItem(LocalStorageConstants.TOKEN, loginData.token);
      localStorage.setItem(LocalStorageConstants.USER, JSON.stringify(loginData.user));
      setTokenValue(JSON.stringify(loginData.token));

      message.destroy();
      message.success(loginData?.message);
      // message.success("Login Successful!");
      history.push("/home");
    } catch (error: any) {
      /* destroy previous messages */
      message.destroy();
      // message.error("Invalid Credentials!");
      message.error("Error: " + (error?.message || ""));
    }
  };

  const handleForgotPassword = () => {
    setShowForgotPasswordMsg(true);
  };

  const handleForgotPasswordClose = () => {
    setShowForgotPasswordMsg(false);
  };

  const handleLinks = () => {
    setShowLinks(true);
  };
  const handleLinksClose = () => {
    setShowLinks(false);
  };

  const formatVersionAndDate = (major: number | null, minor: number | null, patch: number | null, releaseDate: any): string => {
    const formattedVersion = `${major?.toString().padStart(2, "0")}.${minor?.toString().padStart(2, "0")}.${patch?.toString().padStart(2, "0")}`;
    return `${formattedVersion} (${releaseDate ?? "00-00-0000"})`;
  };

  return (
    <div className={styles["login-wrap"]}>
      <div className={styles["login-container"]}>
        <div className={styles["login-main-content"]}>
          <Row gutter={24}>
            <Col sm={24} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0%", borderRight: "3px solid #1860ab", margin: "2% 0%" }}>
              <div>
                <img alt="adani_airport" src={TrackMyTreeLogo} style={{ objectFit: "cover", height: "100%", width: "100%", padding: "14%", paddingLeft: "16%" }} />
              </div>
            </Col>
            <Col sm={24} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className={styles["login-content"]}>
                <h6 className={styles["signIn-description"]}>
                  Welcome to&nbsp;<span style={{ color: "#1860ab" }}>{projectName()?.slice(0, 5) || "Track"}</span>
                  <span style={{ color: "#46b82f" }}>{projectName()?.slice(5) || "MyTree"}</span>&nbsp;
                  {/* {projectName() ?? "TrackMyTree"} */}
                  {/* <img
                src={TrackMyTreeLogo}
                alt="TrackMyTree"
                height={70}
                style={{ marginTop: "-44px" }}
                onError={(e: any) => {
                  e.target.style.display = projectName(); // Hide the image
                }}
              /> */}
                </h6>
                {/* <h6 className={styles["signIn-description"]}>Welcome to SSF - Tree Tracker</h6> */}
                {showForgotPasswordMsg ? (
                  <div>
                    <p>Please contact your Administrator for New Password.</p>
                    <Button type="primary" onClick={() => handleForgotPasswordClose()}>
                      Back
                    </Button>
                  </div>
                ) : showLinks ? (
                  <Links handleLinksClose={handleLinksClose} />
                ) : (
                  <Form onFinish={handleFinish} autoComplete="off" initialValues={{ remember: true }} name="basic" style={{ width: "100%" }} className={`gx-signin-form gx-form-row0`}>
                    <Form.Item
                      name="MobileNumber"
                      normalize={normalizeWhitespace}
                      rules={[
                        {
                          type: "string",
                          message: "The input is not a valid Mobile Number."
                        },
                        {
                          required: true,
                          pattern: /^[0-9]{10}$/,
                          message: "Mobile No. must be a 10-digit number."
                        }
                      ]}
                    >
                      <Input maxLength={10} placeholder="Mobile No." />
                    </Form.Item>
                    <Form.Item name="Password" className={styles["signIn-form__password"]} normalize={normalizeWhitespace} rules={[{ required: true, message: "The password is required" }]}>
                      <Input.Password placeholder="Password" name="Password" />
                    </Form.Item>
                    <Form.Item>
                      <Button type="link" onClick={handleForgotPassword} style={{ marginLeft: "65%" }}>
                        Forgot Password?
                      </Button>
                      <Button type="primary" className={`${styles["signIn-btn"]} `} htmlType="submit">
                        Login
                      </Button>
                    </Form.Item>
                    {version && (
                      <span style={{ marginTop: "-15px" }} className={`${styles["version"]}`}>
                        Version: {formatVersionAndDate(version?.MajorVersion, version?.MinorVersion, version?.PatchVersion, version?.ReleaseDate)}
                      </span>
                    )}
                    <span className={`${styles["version"]}`} style={{ marginTop: "5px" }}>
                      - by CoReCo Technologies Private Limited.
                    </span>
                    <Button type="link" onClick={handleLinks} style={{ marginLeft: "42%", height: "0px" }}>
                      More
                    </Button>
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
