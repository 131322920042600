import React from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface BarChartDataPoint {
  name: string;
  Trees: number; // You can replace 'number' with the appropriate type for your data
}

interface StackedBarChartProps {
  barChartData: BarChartDataPoint[];
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({ barChartData }) => (
  <ResponsiveContainer width="100%" height={270}>
    {barChartData && barChartData?.length > 0 ? (
      <BarChart data={barChartData} margin={{ top: 10, right: 0, left: -15, bottom: 50 }}>
        <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey="Trees" stackId="a" fill="#003366" />
      </BarChart>
    ) : (
      <svg width="100%" height={260}>
        <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" className="h3">
          No data available!
        </text>
      </svg>
    )}
  </ResponsiveContainer>
);

export default StackedBarChart;
