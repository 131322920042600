import axios from "axios";

//tree list
export const getTreeListAPI = async (TOKEN: any, page: any, size: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/tree?page=${page}&pageSize=${size}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getTreeListFilterAPI = async (TOKEN: any, page: any, size: any, projectId: number[], clusterId: number[], villageId: number[], farmerId: number[], areaId: number[], healthConditionId: number[], search: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/tree?page=${page}&pageSize=${size}&projectIdList=[${projectId}]&clusterIdList=[${clusterId}]&villageIdList=[${villageId}]&farmerIdList=[${farmerId}]&areaIdList=[${areaId}]&healthConditionIdList=[${healthConditionId}]&searchText=${search}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    if (response.status !== 200) {
      throw new Error("Error fetching tree. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getTimelineListByTreeTag = async (TOKEN: any, TreeId: any, page: any, size: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/tree/${TreeId}?page=${page}&pageSize=${size}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getTreeTag = async (TOKEN: any, page: any, size: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/tree?page=${page}&pageSize=${size}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const generateBarcodes = async (TOKEN: any, AreaId: number | null, BarcodeCount: number) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/tree/barcodeAndTreeTag`,
      data: {
        AreaId,
        BarcodeCount
      },
      headers: {
        Authorization: `Bearer ${TOKEN}` // Include the token in the request headers
      }
    });

    if (res.status !== 200) {
      throw new Error("Unable to generate barcodes.");
    }

    return res.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getUnusedBarcodeList = async (TOKEN: any, areaId: number | null) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/tree/UnusedBarcodeList`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`
      },
      params: { AreaId: areaId } // Include areaId as a query parameter
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateTreeHeader = async (TOKEN: any, TreeId: number, UpdateData: object) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/tree/treeHeader/${TreeId}`, UpdateData, {
      headers: {
        Authorization: `Bearer ${TOKEN}`
      }
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
export const updateTreeTransaction = async (TOKEN: any, TreeId: number, UpdateData: object) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/tree/treeTransaction/${TreeId}`, UpdateData, {
      headers: {
        Authorization: `Bearer ${TOKEN}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
