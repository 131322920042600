import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Card, message, Spin, Space } from "antd";
import * as userService from "../../services/userServices";
import { UserDataByIdResponse, UserListInterface, UserFormInterface } from "../../shared/interfaces/User.interface";
import { useHistory, useParams } from "react-router-dom";
import type { History } from "history";
import { Response, DataItem } from "../../shared/interfaces/MasterManagement.interfaces";
import * as orgmasterServices from "../../services/orgmasterServices";
import { ModuleAndIdMapping, OrgMasterModulesAndMapping, UserRoleDATA } from "../../constants/databaseConstants";
import CircularProgressBar from "../CircularProgressBar";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import { normalizeWhitespace } from "../../util/InputWhiteSpaceRemove";
import { formatRoleName } from "../../util/formatRoleName";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const inputLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 }
  }
};

const UserForm = () => {
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const user = JSON.parse(localStorage.getItem(LocalStorageConstants.FORM) || "{}");
  const userToken: any = JSON.parse(localStorage.getItem(LocalStorageConstants.FORM) || "{}");
  const [form] = Form.useForm();
  const history = useHistory();
  const { id, view } = useParams<{ id: any; view: string }>();
  const [readEnabled] = useState(view ? true : false);
  /* when a module is selected this state stores list of records for that module. */
  const [selectedList, setSelectedList] = useState<DataItem[]>([]);
  const [selectRoleId, setSelectRoleId] = useState<any | null>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [areaList, setAreaList] = useState<DataItem[]>([]);
  const [projectList, setProjectList] = useState<DataItem[]>([]);
  const [areaByOrgIdList, setAreaByOrgIdList] = useState<DataItem[]>([]);
  const [projectByOrgIdList, setProjectByOrgIdList] = useState<DataItem[]>([]);
  /* selected module Id */
  const [selectedRole, setSelectedRole] = useState<number | null>(ModuleAndIdMapping.USERROLE);
  const [userData, setUserData] = useState<Array<UserListInterface>>([]);
  /* states for refresh when a module is refreshed */
  const [refresh, setRefresh] = useState<boolean>(true);
  const [userById, setUserById] = useState<any>();
  const [updateRoleList] = useState(userById?.user?.UserId);
  const isFormId4 = userToken?.Forms?.[1]?.FormId === 4;
  const isFormId123 = userToken?.Forms?.[0]?.FormId === 1;
  const [isActive, setIsactive] = useState<string>("");
  const [selectOrganizationId, setSelectOrganizationId] = useState<any | null>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [inputData, setInputData] = useState<UserFormInterface | any>({
    FirstName: "",
    LastName: "",
    MobileNumber: "",
    Password: "",
    LocalPartner: "",
    RoleId: null,
    OrganizationId: null,
    selectRoleId,
    areaIdList: [],
    projectIdList: []
  });

  const handleFinish = () => {
    if (userById?.UserId) {
      updateUserById(userById?.UserId, inputData, history);
    } else {
      addNewUserDetails(inputData);
    }
  };

  useEffect(() => {
    if (id) {
      getOneUserById(+id);
    }
  }, [id]);

  useEffect(() => {
    if (userById) {
      setInputData({
        FirstName: userById?.FirstName,
        LastName: userById?.LastName,
        MobileNumber: userById?.MobileNumber,
        LocalPartner: userById?.LocalPartner,
        RoleId: userById?.Role?.RoleId,
        OrganizationId: userById?.Organization?.OrganizationId,
        areaIdList: userById?.UserAreaMapping?.map((item: any, index: any) => {
          return item?.AreaId;
        }),
        projectIdList: userById?.UserProjectMappings?.map((item: any, index: any) => {
          return item?.ProjectId;
        })
      });
      form.setFieldsValue({
        FirstName: userById?.FirstName,
        LastName: userById?.LastName,
        MobileNumber: userById?.MobileNumber,
        LocalPartner: userById?.LocalPartner,
        RoleId: userById?.Role?.RoleId,
        OrganizationId: userById?.Organization?.OrganizationId,
        areaIdList: userById?.UserAreaMapping?.map((item: any, index: any) => {
          return item?.AreaId;
        }),
        projectIdList: userById?.UserProjectMappings?.map((item: any, index: any) => {
          return item?.ProjectId;
        })
      });
    }
  }, [userById, userById?.FirstName, userById?.LastName, userById?.MobileNumber, userById?.Role?.RoleId, userById?.LocalPartner, userById?.user?.UserId, JSON.stringify(updateRoleList) !== JSON.stringify(userById?.user?.UserId), form]);

  const getOneUserById = async (id: number) => {
    setLoading(true);
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
    try {
      const response: UserDataByIdResponse = await userService.getOneUserByIdAPI(TOKEN, id);
      if (response) {
        setLoading(false);
        setUserById(response?.user);
        setSelectRoleId(response?.user?.Role?.RoleId);
        setSelectOrganizationId(response?.user.Organization.OrganizationId);
        // message.destroy();
        // message.success(response?.message);
      }
    } catch (error: any) {
      setLoading(false);
      // message.error("Unable to fetch user data");
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  const addNewUserDetails = async (inputData: UserFormInterface) => {
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);

    try {
      setLoader(true);
      const response: any = await userService.addNewUserDetailsAPI(inputData, TOKEN);
      if (response) {
        message.destroy();
        message.success(response.message);
        history.push("/users");
      }
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  const updateUserById = async (UserId: number, updateUserDetails: UserFormInterface, history: History) => {
    const updatedData: UserFormInterface | any = {
      ...updateUserDetails
    };

    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);

    try {
      setLoader(true);
      if (updatedData.RoleId === 0) {
        message.error("Please select Role");
        return;
      }
      const response: any = await userService.updateUserDetailsAPI(UserId, updatedData, history, TOKEN);
      if (response) {
        message.destroy();
        message.success(response.message);
        history.push("/users");
      }
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  /* Fetching all Master Tables list when visited using select */
  useEffect(() => {
    const renderMasterTablesList = async () => {
      try {
        setSelectedList([]);
        const response: Response = await userService.getOneMasterRoleList(TOKEN!, selectedRole);

        const res = response.AllRecordsList;
        setSelectedList(res);
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };
    const fetchList = async () => {
      if (refresh) await renderMasterTablesList();
    };
    fetchList();
  }, [selectedRole]);

  /* Fetching all Area Tables list when visited using select */
  useEffect(() => {
    const renderAreaByOrgList = async () => {
      try {
        setAreaByOrgIdList([]);
        if (selectOrganizationId <= 0) {
          return;
        }
        const response: Response = await userService.getOneAreaListByOrganizationId(TOKEN!, selectOrganizationId);

        const res = response.record;

        setAreaByOrgIdList(res);
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };
    const fetchAreaByOrgList = async () => {
      await renderAreaByOrgList();
    };
    fetchAreaByOrgList();
  }, [selectOrganizationId]);

  /* Fetching all project Tables list when visited using select */
  useEffect(() => {
    const renderprojectByOrgList = async () => {
      try {
        setProjectByOrgIdList([]);
        if (selectOrganizationId <= 0) {
          return;
        }
        const response: Response = await userService.getOneProjectListByOrganizationId(TOKEN!, selectOrganizationId);

        const res = response.record;

        setProjectByOrgIdList(res);
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };
    const fetchProjectByOrgList = async () => {
      if (user && user?.RoleName === UserRoleDATA.SUPER_ADMIN) {
        renderprojectByOrgList();
      }
      await renderprojectByOrgList();
    };
    fetchProjectByOrgList();
  }, [selectOrganizationId]);

  /* Fetching all Area Tables list when visited using select */
  useEffect(() => {
    const renderAreaList = async () => {
      try {
        setAreaList([]);
        const response: Response = await userService.getOneMasterAreaList(TOKEN!);

        const res = response.areaList;
        setAreaList(res);
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };
    const fetchAreaList = async () => {
      if (refresh && !isFormId123) {
        await renderAreaList();
      }
    };
    fetchAreaList();
  }, [refresh, isFormId123]);

  /* Fetching all project Tables list when visited using select */

  /* project list */
  useEffect(() => {
    const fetchlist = async () => {
      try {
        setProjectList([]);
        const response = await orgmasterServices.getOneOrgMasterListById(TOKEN!, OrgMasterModulesAndMapping.PROJECT, isActive);
        const res = response.AllRecordsList.map((item: any) => {
          return {
            ...item,
            key: item.ProjectId,
            name: item.ProjectName,
            id: item.ProjectId
          };
        });
        if (res.length > 0) {
          setProjectList(res);
        } else {
          setProjectList([]);
        }
      } catch (error: any) {
        setProjectList([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };
    if (user && user?.RoleName === UserRoleDATA.ORGANIZATION_ADMIN) {
      fetchlist();
    }
  }, []);

  useEffect(() => {
    const renderOrganizationList = async () => {
      const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
      try {
        const response = await userService.getOrganizationListAPI(TOKEN);
        if (response?.organizationList) {
          setUserData(response?.organizationList);
        }
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };
    const fetchList = async () => {
      if (refresh && !isFormId4) {
        await renderOrganizationList();
      }
    };
    fetchList();
  }, [refresh, isFormId4]);

  const handleInputData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInputData((prevState: any) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  // Handle role selection
  const handleRoleList = (value: number | string, name: string) => {
    // Update inputData state with the selected CountryId
    setSelectRoleId(value);

    setInputData((prevState: any) => ({
      ...prevState,
      [name]: value,

      RoleName: "Please select Role"
    }));
    form.setFieldsValue({ StateId: "Please select role", StateName: "Please select role" });
  };

  const handleAreaList = (value: number | Array<number> | string, name: string) => {
    setInputData((prevState: any) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const handleProjectList = (value: number | Array<number> | string, name: string) => {
    setInputData((prevState: any) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };
  // Handle organization selection
  const handleOrganizationList = (value: number | string, name: string) => {
    // Update inputData state with the selected CountryId
    setSelectOrganizationId(value);
    setInputData((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div>
      {loading === true ? (
        <div className="loading loading--center">
          <Space direction="vertical" className="space--width" size="large">
            <Spin tip="Loading" size="large"></Spin>
          </Space>
        </div>
      ) : (
        <Card className="card--height" title={` ${id && view ? "User" : id ? "Update" : "Add"} Details`}>
          <Form {...formItemLayout} validateTrigger="onBlur" onFinish={handleFinish} colon={false} form={form} name="register" scrollToFirstError>
            {isFormId4 ? (
              " "
            ) : (
              <Form.Item
                {...inputLayout}
                name="OrganizationId"
                label="Organization"
                rules={[
                  {
                    required: true,
                    message: "Please select organization."
                  },
                  {
                    type: "number",
                    message: "OrganizationId must be a number"
                  }
                ]}
              >
                <Select disabled={readEnabled} placeholder="Select organization" getPopupContainer={(trigger) => trigger.parentElement} defaultValue={userById?.OrganizationId} onChange={(value) => handleOrganizationList(value, "OrganizationId")}>
                  {userData?.map((item: any, index: any) => {
                    return (
                      <Select.Option key={item?.OrganizationId} value={item?.OrganizationId}>
                        {item?.OrganizationName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item
              {...inputLayout}
              name="FirstName"
              label="First Name"
              normalize={normalizeWhitespace}
              rules={[
                {
                  required: true,
                  message: "Please input your first name."
                },
                {
                  message: "Only characters allowed, no leading/trailing spaces.",
                  validator: (_, value) => {
                    if (value && (value.startsWith(" ") || value.endsWith(" "))) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  }
                },
                { min: 3, message: "First name should be minimum 3 characters long" }
              ]}
            >
              <Input disabled={readEnabled} name="FirstName" onChange={handleInputData} />
            </Form.Item>

            <Form.Item {...inputLayout} name="LastName" label="Last Name" normalize={normalizeWhitespace}>
              <Input disabled={readEnabled} name="LastName" onChange={handleInputData} />
            </Form.Item>

            <Form.Item
              {...inputLayout}
              name="MobileNumber"
              label="Mobile No."
              normalize={normalizeWhitespace}
              rules={[
                {
                  required: true,
                  message: "Please input your Mobile No."
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Mobile No. must be a 10-digit number."
                }
              ]}
            >
              <Input disabled={readEnabled} name="MobileNumber" onChange={handleInputData} maxLength={10} />
            </Form.Item>

            {userById?.UserId ? (
              " "
            ) : (
              <div>
                <Form.Item
                  {...inputLayout}
                  name="Password"
                  label="Password"
                  normalize={normalizeWhitespace}
                  rules={[
                    { required: true, message: "Please enter your new password" },
                    { min: 8, message: "New password must be minimum 8 characters." }
                  ]}
                >
                  <Input.Password disabled={readEnabled} placeholder="Password" name="Password" onChange={handleInputData} />
                </Form.Item>
                <Form.Item
                  {...inputLayout}
                  name="confirmPassword"
                  label="Confirm Password"
                  dependencies={["newPassword"]}
                  normalize={normalizeWhitespace}
                  rules={[
                    { required: true, message: "Please confirm your new password" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("Password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("The two passwords do not match"));
                      }
                    })
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </div>
            )}

            <Form.Item {...inputLayout} name="LocalPartner" label="Local Partner" normalize={normalizeWhitespace}>
              <Input disabled={readEnabled} name="LocalPartner" onChange={handleInputData} />
            </Form.Item>

            <Form.Item
              {...inputLayout}
              name="RoleId"
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Please select role."
                },
                {
                  type: "number",
                  message: "RoleId must be a number"
                }
              ]}
            >
              <Select placeholder="Select role" disabled={readEnabled} getPopupContainer={(trigger) => trigger.parentElement} defaultValue={userById?.Role?.RoleId} onChange={(value) => handleRoleList(value, "RoleId")}>
                {selectedList?.map((item: any, index: any) => {
                  return (
                    <Select.Option key={item?.RoleId} value={item?.RoleId}>
                      {formatRoleName(item.RoleName)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            {selectRoleId ? (
              <>
                {selectRoleId === 3 && (
                  <Form.Item
                    {...inputLayout}
                    name="areaIdList"
                    label="Area"
                    rules={[
                      {
                        required: true,
                        message: "Please select area."
                      }
                    ]}
                  >
                    <Select getPopupContainer={(trigger) => trigger.parentElement} mode="multiple" placeholder="Select Area" disabled={readEnabled} onChange={(value) => handleAreaList(value, "areaIdList")}>
                      {areaByOrgIdList && areaByOrgIdList.length !== 0 ? (
                        <>
                          {areaByOrgIdList?.map((item: any, index: any) => (
                            <Select.Option key={item?.AreaId} value={item?.AreaId}>
                              {`${item?.Project?.ProjectName} >> ${item?.Cluster?.ClusterName} >> ${item?.Village?.VillageName} >>${item.AreaName}`}
                            </Select.Option>
                          ))}
                        </>
                      ) : (
                        <>
                          {areaList?.map((item: any, index: any) => (
                            <Select.Option key={item?.AreaId} value={item?.AreaId}>
                              {`${item?.Project?.ProjectName} >> ${item?.Cluster?.ClusterName} >> ${item?.Village?.VillageName} >> ${item.AreaName}`}
                            </Select.Option>
                          ))}
                        </>
                      )}
                    </Select>
                  </Form.Item>
                )}

                {selectRoleId === 4 && (
                  <Form.Item
                    {...inputLayout}
                    name="projectIdList"
                    label="Project"
                    rules={[
                      {
                        required: true,
                        message: "Please select project."
                      }
                    ]}
                  >
                    <Select getPopupContainer={(trigger) => trigger.parentElement} mode="multiple" placeholder="Select Project" disabled={readEnabled} onChange={(value) => handleProjectList(value, "projectIdList")}>
                      {projectByOrgIdList && projectByOrgIdList.length !== 0 ? (
                        <>
                          {projectByOrgIdList?.map((project: any) => (
                            <Select.Option key={project?.ProjectId} value={project?.ProjectId}>
                              {`${project?.ProjectName}`}
                            </Select.Option>
                          ))}
                        </>
                      ) : (
                        <>
                          {projectList?.map((project: any) => (
                            <Select.Option key={project?.ProjectId} value={project?.ProjectId}>
                              {`${project?.ProjectName}`}
                            </Select.Option>
                          ))}
                        </>
                      )}
                    </Select>
                  </Form.Item>
                )}
              </>
            ) : null}

            <div style={{ display: "flex", justifyContent: "flex-end", gap: "2%", marginRight: "20px", marginTop: "60px" }}>
              <Form.Item className="form-button form-button-pr ">
                <Button type="primary" style={{ display: "block", margin: "0 auto" }} className="gx-mb-0 " htmlType="submit" onClick={() => history.push(`/users`)}>
                  Back
                </Button>
              </Form.Item>

              {!readEnabled && (
                <Form.Item {...tailFormItemLayout} className="form-button">
                  <Button type="primary" style={{ display: "block", margin: "0 auto" }} className="gx-mb-0 " htmlType="submit" disabled={loader}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                      {id && loader ? <CircularProgressBar /> : id ? "" : loader ? <CircularProgressBar /> : ""}
                      {id ? "Update" : "Submit"}
                    </div>
                  </Button>
                </Form.Item>
              )}
            </div>
          </Form>
        </Card>
      )}
    </div>
  );
};

export default UserForm;
