import React, { useEffect } from "react";
import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";

import redMarkerIconUrl from "../../assets/pictures/DEAD.webp";

interface Location {
  latitude: number;
  longitude: number;
  name: string;
  treeTag: string;
}

interface MapProps {
  location: Location;
}

const Map: React.FC<MapProps> = ({ location }) => {
  const mapStyles = {
    width: "100%",
    height: "30vh"
  };

  const layer = L.tileLayer(`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`, {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  });

  const mapParams = {
    center: [location?.latitude, location?.longitude] as LatLngExpression,
    zoom: 13,
    layers: [layer],
    attributionControl: false
  };

  const customIcon = L.icon({
    iconUrl: redMarkerIconUrl, // Use the red marker icon image
    iconSize: [25, 41],
    iconAnchor: [12, 41]
  });

  useEffect(() => {
    const map = L.map("map", mapParams);

    // Add a marker with a popup displaying the name
    const marker = L.marker([location.latitude, location.longitude], {
      icon: customIcon
    }).addTo(map);

    marker.bindPopup(`<b>${location.treeTag}</b><br>${location.name}`);

    // marker.bindPopup(location.name).openPopup();

    return () => {
      map.remove();
    };
  }, [location]);

  return (
    <div>
      <div id="map" style={mapStyles} />
    </div>
  );
};

export default Map;
