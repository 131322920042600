import React, { Dispatch, SetStateAction } from "react";
import { Menu, MenuProps } from "antd";
import { useSelector } from "react-redux";
import SidebarLogo from "./SidebarLogo";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import type { RootState } from "../../appRedux/store";
import { useHistory } from "react-router-dom";
import { UserRoleDATA, allowedRoles } from "../../constants/databaseConstants";
import { BarcodeOutlined } from "@ant-design/icons";
import { LocalStorageConstants } from "../../constants/localStorageConstants";

type SidebarContentProps = {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
};

type MenuItem = Required<MenuProps>["items"][number];

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: "group"): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem;
};

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }: SidebarContentProps) => {
  const form = JSON.parse(localStorage.getItem(LocalStorageConstants.FORM) || "{}");
  const User = form?.RoleName;
  const history = useHistory();
  const { themeType } = useSelector(({ settings }: RootState) => settings);
  const pathname = useSelector(({ common }: RootState) => common.pathname);
  const selectedKeys = pathname?.substr(1)?.split("/")?.at(0);
  const defaultOpenKeys = selectedKeys?.split("/")[1];

  // HANDLERS

  const handleOnClick: MenuProps["onClick"] = (menuItem) => {
    const keyPath = menuItem.keyPath.at(0);

    if (keyPath === "users") return history.push("/users");
    if (keyPath === "master_management") return history.push("/master_management");
    if (keyPath === "organization_management") return history.push("/organization_management");
    if (keyPath === "organization_master_management") return history.push("/organization_master_management");
    if (keyPath === "tree") return history.push("/tree");
    if (keyPath === "timeline") return history.push("/timeline");
    if (keyPath === "report") return history.push("/report");
    if (keyPath === "report2") return history.push("/report/replantation");
    if (keyPath === "report3") return history.push("/report/missing_tag");
    if (keyPath === "report4") return history.push("/report/geo_report");
    if (keyPath === "generate_barcodes") return history.push("/generate_barcodes");
    if (keyPath === "log") return history.push("/log");

    /* If no keypath found then move to root */
    return history.push("/home");
  };

  // Define your menu item generator functions

  // for SUPER_ADMIN
  const generateSettings = () => [getItem(<span style={{ marginLeft: "8%" }}>User Management</span>, "users"), getItem(<span style={{ marginLeft: "8%" }}>Common Master</span>, "master_management"), getItem(<span style={{ marginLeft: "8%" }}>Organization Master</span>, "organization_management")];

  // for ORGANIZATION_ADMIN
  const generateSettings2 = () => [getItem(<span style={{ marginLeft: "8%" }}>User Management</span>, "users"), getItem(<span style={{ marginLeft: "8%" }}>Organization Master</span>, "organization_master_management")];

  const Tree1 = () => [getItem(<span style={{ marginLeft: "8%" }}>Tree</span>, "tree"), getItem(<span style={{ marginLeft: "8%" }}>Log</span>, "log"), getItem(<span style={{ marginLeft: "8%" }}>Tree Geo-Accuracy Error Report</span>, "report4")];

  // for FIELD_EXECUTIVE
  const generateSettings3 = () => [];

  // for ORGANIZATION_ADMIN
  const generateReports = () => [getItem(<span style={{ marginLeft: "8%" }}>Tree Statistics</span>, "report"), getItem(<span style={{ marginLeft: "8%" }}>Replantation Tree Statistics</span>, "report2"), getItem(<span style={{ marginLeft: "8%" }}>Missing TreeTag Statistics</span>, "report3")];
  // for CSR-PARTNER
  const generateReports1 = () => [getItem(<span style={{ marginLeft: "8%" }}>Tree Statistics</span>, "report"), getItem(<span style={{ marginLeft: "8%" }}>Replantation Tree Statistics</span>, "report2")];

  // Create an array of menu items based on User

  const Home = () => [getItem(<span style={{ marginLeft: "8%" }}>Dashboard</span>, "dashboard")];
  const Tree = () => [getItem(<span style={{ marginLeft: "8%" }}>Tree</span>, "tree")];
  const GenerateBarCodes = () => [getItem(<span style={{ marginLeft: "8%" }}>Generate Barcodes</span>, "generate_barcodes")];

  let items: MenuItem[] = [];
  if (User) {
    if (allowedRoles?.includes(User)) {
      items = [getItem("Dashboard", "dashboard", <i className="icon icon-home" />, Home())];

      if (User === UserRoleDATA.CSR_PARTNER) {
        items.push(getItem("Report", "report", <i className="icon icon-chart" />, generateReports1()), getItem("Tree", "tree", <i className="icon icon-listing-dbrd" />, Tree()));
      } else if (User === UserRoleDATA.SUPER_ADMIN) {
        items.push(getItem("Settings", "users", <i className="icon icon-setting" />, generateSettings()));
      } else if (User === UserRoleDATA.ORGANIZATION_ADMIN) {
        items.push(
          getItem("Report", "report", <i className="icon icon-chart" />, generateReports()),
          getItem(
            <span style={{ marginLeft: "2%" }}>Generate Barcodes</span>,
            "generate_barcodes",
            <span style={{ marginLeft: "2%", marginTop: sidebarCollapsed ? "70%" : "" }}>
              <BarcodeOutlined />
            </span>,
            GenerateBarCodes()
          ),
          getItem("Tree", "tree", <i className="icon icon-listing-dbrd" />, Tree1()),
          getItem("Settings", "users", <i className="icon icon-setting" />, generateSettings2())
        );
      } else if (User === UserRoleDATA.FIELD_EXECUTIVE) {
        items.push(getItem("Tree", "tree", <i className="icon icon-listing-dbrd" />, Tree()));
      }
    } else {
      items = [];
    }
  }

  return (
    <>
      {/* hamburger icon */}
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <Menu onClick={handleOnClick} style={{ paddingTop: "10px" }} defaultOpenKeys={[defaultOpenKeys!]} defaultSelectedKeys={[selectedKeys!]} theme={themeType === THEME_TYPE_LITE ? "light" : "dark"} mode="inline" items={items}></Menu>
    </>
  );
};

export default React.memo(SidebarContent);
