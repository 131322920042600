import React from "react";
import { Form, Input, Button, Card, message } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import { normalizeWhitespace } from "../../util/InputWhiteSpaceRemove";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const inputLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 }
  }
};

const UserList: React.FC = () => {
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const history = useHistory();
  const [form] = Form.useForm();

  const handleFinish = async (values: { currentpassword: string; newpassword: string }) => {
    try {
      // You can perform any login/authentication logic here.
      // For simplicity, let's assume the login is successful.

      const endpoint = `${process.env.REACT_APP_API_URL}/user/changePassword`;

      const requestBody = {
        currentPassword: values.currentpassword,
        newPassword: values.newpassword
      };

      // Use the Authorization header to pass the token for API authorization
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}` // Assuming your API expects the token in this format
        }
      };

      const response = await axios.patch(endpoint, requestBody, config);
      message.destroy();
      message.success(response.data.message);

      // Redirect to the home page
      history.push("/home");
    } catch (error: any) {
      // Handle errors here
      message.destroy();
      message.error("Error: " + (error?.response?.data?.message || ""));
    }
  };

  return (
    <div>
      <Card title="Change Password">
        <Form {...formItemLayout} onFinish={handleFinish} validateTrigger="onBlur" colon={false} form={form} name="register" scrollToFirstError>
          <Form.Item
            {...inputLayout}
            name="currentpassword"
            label="Current Password"
            normalize={normalizeWhitespace}
            rules={[
              { required: true, message: "Please enter your current password" },
              { min: 8, message: "Current Password must be minimum 8 characters." }
            ]}
          >
            <Input.Password placeholder="Current Password" type="password" name="currentPassword" />
          </Form.Item>

          <Form.Item
            {...inputLayout}
            name="newpassword"
            label="New Password"
            normalize={normalizeWhitespace}
            rules={[
              { required: true, message: "Please enter your new password" },
              { min: 8, message: "New password must be minimum 8 characters." }
            ]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>

          <Form.Item
            {...inputLayout}
            name="confirmPassword"
            label="Confirm Password"
            normalize={normalizeWhitespace}
            dependencies={["newpassword"]}
            rules={[
              { required: true, message: "Please confirm your new password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newpassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The two passwords do not match"));
                }
              })
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item {...tailFormItemLayout} className="form-button">
            <div style={{ display: "flex", flexDirection: "row", gap: "30%", marginRight: "40px" }}>
              <Button type="primary" style={{ display: "block", margin: "0 auto" }} onClick={() => history.push(`/home`)}>
                Back
              </Button>

              <Button type="primary" style={{ display: "block", margin: "0 auto" }} htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default UserList;
