import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Card, message, Row, Col, Space, Spin } from "antd";
import * as organizationService from "../../services/organizationService";
import { OrganizationFormInterface } from "../../shared/interfaces/Oranization.interface";
import { useHistory, useParams } from "react-router-dom";
import type { History } from "history";
import { Response, DataItem } from "../../shared/interfaces/MasterManagement.interfaces";
import { ModuleAndIdMapping } from "../../constants/databaseConstants";
import CircularProgressBar from "../CircularProgressBar";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import { normalizeWhitespace } from "../../util/InputWhiteSpaceRemove";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const inputLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 }
  }
};

const OrganizationForm = () => {
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const [form] = Form.useForm();
  const history = useHistory();
  const { id, view } = useParams<{ id: any; view: string }>();
  const [readEnabled] = useState(view ? true : false);
  /* when a module is selected this state stores list of records for that module. */
  const [selectedList, setSelectedList] = useState<DataItem[]>([]);
  const [selectedStateList, setSelectedStateList] = useState<DataItem[]>([]);
  /* selected module Id */
  const [selectedCountry, setSelectedCountry] = useState<number | null>(ModuleAndIdMapping.COUNTRY);
  /* states for refresh when a module is refreshed */
  const [refresh, setRefresh] = useState<boolean>(true);
  const [organizationById, setOrganizationById] = useState<any>();
  const [updateOrganizationList] = useState(organizationById?.organization?.OrganizationId);
  const [loading, setLoading] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectCountryId, setSelectCountryId] = useState<any | null>(0);
  // const countryObject = {selectCountryId};
  const [inputData, setInputData] = useState<OrganizationFormInterface | any>({
    OrganizationName: "",
    OrgAbbreviation: "",
    OrganizationDescription: "",
    Address1: "",
    Address2: "",
    City: "",
    PostalCode: "",
    StateId: "",
    CountryId: "",
    Website: "",
    Email: "",
    ContactPerson: "",
    ContactPersonPhoneNumber: "",
    PhoneNumber: ""
  });

  const handleFinish = () => {
    if (organizationById?.OrganizationId) {
      updateOrganizationById(organizationById?.OrganizationId, inputData, history);
    } else {
      addNewOrganizationDetails(inputData);
    }
  };

  useEffect(() => {
    if (id) {
      getOneOrganizationById(+id);
    }
  }, [id]);

  useEffect(() => {
    if (organizationById) {
      setInputData({
        OrganizationName: organizationById?.OrganizationName,
        OrgAbbreviation: organizationById?.OrgAbbreviation,
        OrganizationDescription: organizationById?.OrganizationDescription,
        Address1: organizationById?.Address1,
        Address2: organizationById?.Address2,
        City: organizationById?.City,
        PostalCode: organizationById?.PostalCode,
        CountryId: organizationById?.Country?.CountryId,
        StateId: organizationById?.State?.StateId,
        Website: organizationById?.Website,
        Email: organizationById?.Email,
        ContactPerson: organizationById?.ContactPerson,
        ContactPersonPhoneNumber: organizationById?.ContactPersonPhoneNumber,
        PhoneNumber: organizationById?.PhoneNumber
      });
      form.setFieldsValue({
        OrganizationName: organizationById?.OrganizationName,
        OrgAbbreviation: organizationById?.OrgAbbreviation,
        OrganizationDescription: organizationById?.OrganizationDescription,
        Address1: organizationById?.Address1,
        Address2: organizationById?.Address2,
        City: organizationById?.City,
        PostalCode: organizationById?.PostalCode,
        CountryId: organizationById?.Country?.CountryId,
        StateId: organizationById?.State?.StateId,
        Website: organizationById?.Website,
        Email: organizationById?.Email,
        ContactPerson: organizationById?.ContactPerson,
        ContactPersonPhoneNumber: organizationById?.ContactPersonPhoneNumber,
        PhoneNumber: organizationById?.PhoneNumber
      });
    }
  }, [
    organizationById,
    organizationById?.OrganizationName,
    organizationById?.OrgAbbreviation,
    organizationById?.OrganizationDescription,
    organizationById?.Address1,
    organizationById?.Address2,
    organizationById?.City,
    organizationById?.PostalCode,
    organizationById?.Country?.CountryId,
    organizationById?.State?.StateId,
    organizationById?.Website,
    organizationById?.Email,
    organizationById?.ContactPerson,
    organizationById?.ContactPersonPhoneNumber,
    organizationById?.PhoneNumber,
    JSON.stringify(updateOrganizationList) !== JSON.stringify(organizationById?.organization?.OrganizationId),
    form
  ]);

  //first list
  const getOneOrganizationById = async (id: number) => {
    setLoading(true);
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
    try {
      const response = await organizationService.getOneOrganizationByIdAPI(TOKEN, id);
      if (response) {
        setLoading(false);
        setOrganizationById(response?.organization);
        setSelectCountryId(response?.organization?.Country.CountryId);
        // message.destroy();
        // message.success(response?.message);
        // message.success("User data fetched successfully ");
      }
    } catch (error: any) {
      setLoading(false);
      // message.error("Unable to fetch user data");
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  const addNewOrganizationDetails = async (inputData: OrganizationFormInterface) => {
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
    try {
      setLoader(true);
      const response: any = await organizationService.addNewOrganizationDetailsAPI(inputData, history, TOKEN);

      if (response) {
        message.destroy();
        message.success(response.message);

        history.push("/organization_management");
      }
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  const updateOrganizationById = async (OrganizationId: number, updateOrganizationDetails: OrganizationFormInterface, history: History) => {
    const updatedData: OrganizationFormInterface | any = {
      ...updateOrganizationDetails
    };

    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
    try {
      setLoader(true);
      if (updatedData.StateId === 0) {
        message.error("Please select state");
        return;
      }
      const response: any = await organizationService.updateOrganizationDetailsAPI(OrganizationId, updatedData, history, TOKEN);
      if (response) {
        message.destroy();
        message.success(response.message);
        history.push("/organization_management");
      }
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  /* Fetching all Master Tables list when visited using select */
  useEffect(() => {
    const renderMasterTablesLists = async () => {
      try {
        setSelectedList([]);
        const response: Response = await organizationService.getOneMasterCountryList(TOKEN!, selectedCountry);

        const res = response.AllRecordsList;
        setSelectedList(res);
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      await renderMasterTablesLists();
    };
    fetchList();
  }, [selectedCountry]);

  useEffect(() => {
    const renderMasterTablesList = async () => {
      try {
        setSelectedStateList([]);
        if (selectCountryId <= 0) {
          return;
        }
        const response: Response = await organizationService.getOneStateListByCountryId(TOKEN!, selectCountryId);

        const res = response.record;
        setSelectedStateList(res);

        setRefresh(true);
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (refresh) await renderMasterTablesList();
    };
    fetchList();
  }, [refresh, selectCountryId]);

  const handleInputData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInputData((prevState: any) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  // Handle country selection
  const handleCountryList = (value: number | string, name: string) => {
    // Update inputData state with the selected CountryId

    setSelectCountryId(value);
    setInputData((prevState: any) => ({
      ...prevState,
      [name]: value,
      StateId: 0,
      StateName: "Please select State"
    }));
    form.setFieldsValue({ StateId: "Please select state", StateName: "Please select State" });
  };

  // Handle state selection
  const handleStateList = (value: number | string, name: string) => {
    // Update inputData state with the selected StateId
    setInputData((prevState: any) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  return (
    <div>
      {loading === true ? (
        <div className="loading loading--center">
          <Space direction="vertical" className="space--width" size="large">
            <Spin tip="Loading" size="large"></Spin>
          </Space>
        </div>
      ) : (
        <Card className="card--height" title={` ${id && view ? "Organization" : id ? "Update" : "Add"} Details`}>
          <Form {...formItemLayout} validateTrigger="onBlur" onFinish={handleFinish} colon={false} form={form} name="register" scrollToFirstError>
            <Row gutter={[16, 16]}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  {...inputLayout}
                  name="OrganizationName"
                  label="Organization name"
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your organization name."
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="OrganizationName" onChange={handleInputData} />
                </Form.Item>
                <Form.Item
                  {...inputLayout}
                  name="OrgAbbreviation"
                  label="Abbreviation Name"
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Abbreviation name."
                    },
                    // Add a custom validator to check for lowercase letters
                    {
                      validator: (rule, value) => {
                        if (value && value !== value.toUpperCase()) {
                          return Promise.reject("Please enter capital letters only.");
                        } else {
                          return Promise.resolve();
                        }
                      }
                    },
                    // Add a custom validator to check for 3-character length
                    {
                      validator: (rule, value) => {
                        if (value && value.length === 3) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Abbreviation name must be 3 characters long.");
                        }
                      }
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="OrgAbbreviation" onChange={handleInputData} />
                </Form.Item>

                <Form.Item
                  {...inputLayout}
                  name="OrganizationDescription"
                  label="Organization Description"
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your organization description."
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="OrganizationDescription" onChange={handleInputData} />
                </Form.Item>

                <Form.Item
                  {...inputLayout}
                  name="PhoneNumber"
                  label="Phone No."
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Phone no."
                    },
                    {
                      pattern: /^[0-9]{10}$/, // Regular expression to allow only 10 digits
                      message: "The input is not a valid Phone no."
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="PhoneNumber" autoComplete="new-number" onChange={handleInputData} />
                </Form.Item>

                <Form.Item
                  {...inputLayout}
                  name="Email"
                  label="Email "
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email."
                    },
                    {
                      type: "email",
                      message: "The input is not a valid e-mail address."
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="Email" onChange={handleInputData} />
                </Form.Item>

                <Form.Item
                  {...inputLayout}
                  name="ContactPerson"
                  label="Contact Person "
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your contact person name."
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="ContactPerson" onChange={handleInputData} />
                </Form.Item>

                <Form.Item
                  {...inputLayout}
                  name="ContactPersonPhoneNumber"
                  label="Contact Person Phone Number "
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your contacted person phone number ."
                    },
                    {
                      pattern: /^[0-9]{10}$/, // Regular expression to allow only 10 digits
                      message: "The input is not a valid Phone no."
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="ContactPersonPhoneNumber" onChange={handleInputData} />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  {...inputLayout}
                  name="Address1"
                  label="Address 1"
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Address1."
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="Address1" onChange={handleInputData} />
                </Form.Item>

                <Form.Item {...inputLayout} name="Address2" label="Address 2 " normalize={normalizeWhitespace}>
                  <Input disabled={readEnabled} name="Address2" onChange={handleInputData} />
                </Form.Item>

                <Form.Item
                  {...inputLayout}
                  name="City"
                  label="City "
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your city ."
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="City" onChange={handleInputData} />
                </Form.Item>

                <Form.Item
                  {...inputLayout}
                  name="PostalCode"
                  label="Postal Code "
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your postal code ."
                    },
                    {
                      pattern: /^[0-9]{6}$/, // Regular expression to allow only 10 digits
                      message: "The input is not a valid postal code."
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="PostalCode" onChange={handleInputData} />
                </Form.Item>

                <Form.Item
                  {...inputLayout}
                  name="CountryId"
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Please select country."
                    },
                    {
                      type: "number",
                      message: "CountryId must be a number"
                    }
                  ]}
                >
                  <Select placeholder="Select Country" disabled={readEnabled} getPopupContainer={(trigger) => trigger.parentElement} defaultValue={organizationById?.Country?.CountryId} onChange={(value) => handleCountryList(value, "CountryId")}>
                    {selectedList?.map((item: any, index: any) => {
                      return (
                        <Select.Option key={item?.CountryId} value={item?.CountryId}>
                          {item?.CountryName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...inputLayout}
                  name="StateId"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: "Please select state."
                    },
                    {
                      type: "number",
                      message: "Please select state"
                    }
                  ]}
                >
                  <Select disabled={readEnabled} placeholder="Select State" onChange={(value) => handleStateList(value, "StateId")}>
                    {selectedStateList?.map((item: any, index: any) => {
                      return (
                        <Select.Option key={item?.StateId} value={item?.StateId}>
                          {item?.StateName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...inputLayout}
                  name="Website"
                  label="Website"
                  normalize={normalizeWhitespace}
                  rules={[
                    {
                      required: true,
                      message: "Please input your website ."
                    }
                  ]}
                >
                  <Input disabled={readEnabled} name="Website" onChange={handleInputData} />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "flex-end", gap: "2%", marginRight: "20px" }}>
              <Form.Item className="form-button form-button-pr ">
                <Button type="primary" style={{ display: "block", margin: "0 auto" }} className="gx-mb-0 button-gradiant" htmlType="submit" onClick={() => history.push(`/organization_management`)}>
                  Back
                </Button>
              </Form.Item>

              {!readEnabled && (
                <Form.Item {...tailFormItemLayout} className="form-button">
                  <Button type="primary" className="gx-mb-0 button-gradiant" htmlType="submit" disabled={loader}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                      {id && loader ? <CircularProgressBar /> : id ? "" : loader ? <CircularProgressBar /> : ""}
                      {id ? "Update" : "Submit"}
                    </div>
                    {/* {id && loader ? <CircularProgressBar /> : id ? "Update" : loader ?
                     <CircularProgressBar /> : "Submit"} */}
                  </Button>
                </Form.Item>
              )}
            </div>
          </Form>
        </Card>
      )}
    </div>
  );
};

export default OrganizationForm;
