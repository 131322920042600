import { Table, Space, Card, message, Select, Button, Spin, DatePicker, Tooltip, Tag, Checkbox, Modal } from "antd";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import * as reportService from "../../../services/reportService";
import * as orgmasterServices from "../../../services/orgmasterServices";
import styles from "../reports.module.css";
import { TablePaginationConfig } from "antd/lib/table/interface";
import { RootState } from "../../../appRedux/store";
import Dropdown from "../filterComponent";
import CommonModal from "../../Modal";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { OrgMasterModulesAndMapping, ModuleAndIdMapping } from "../../../constants/databaseConstants";
import { Column } from "../../../shared/interfaces/Report.interface";
import * as masterServices from "../../../services/masterServices";
import jsPDF from "jspdf";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { LocalStorageConstants } from "../../../constants/localStorageConstants";

const IMAGE_BASE_URL = process.env.REACT_APP_API_URL?.split("/").slice(0, 3).join("/");

const MissingTreeTagReport = () => {
  // CONSTANTS
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);

  const { navCollapsed } = useSelector(({ common }: RootState) => common);

  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const pageSizeOptions = ["10", "20", "50", "100"];
  const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 10 });
  const [reportsData, setReportsData] = useState<any>();

  const [projectlist, setProjectlist] = useState<number[]>([]);
  const [clusterlist, setClusterlist] = useState<number[]>([]);
  const [villagelist, setVillagelist] = useState<number[]>([]);
  const [farmerlist, setFarmerlist] = useState<number[]>([]);
  const [arealist, setArealist] = useState<number[]>([]);
  const [healthConditionlist, setHealthConditionlist] = useState<number[]>([]);

  const [projectId, setProjectId] = useState<number | null>(null);
  const [clusterId, setClusterId] = useState<number | null>(null);
  const [villageId, setVillageId] = useState<number | null>(null);
  const [farmerId, setFarmerId] = useState<number | null>(null);
  const [areaId, setAreaId] = useState<number[]>([]);
  const [healthConditionId, setHealthConditionId] = useState<number[] | []>([]);

  const [loadingExportFile, setLoadingExportFile] = useState(false);
  const [modalVisibleExport, setModalVisibleExport] = useState(false); // Add this state for modal visibility
  const [treeTagList, setTreeTagList] = useState<string[]>([]);
  const [batchSize, setBatchSize] = useState<any>(null);
  const [totalFiles, setTotalFiles] = useState<any>(null);
  const [selectedTreeIds, setSelectedTreeIds] = useState<number[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  /* export table data in excel sheet*/
  const [reportExportData, setReportExportData] = useState<any | null>();

  const [selectDate, setSelectDate] = useState<number | null>(90);

  const [filterSelectOption, setFilterSelectOption] = useState<string | null>("1");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [handleSubmit, setHandleSubmit] = useState(false);

  const [qrCodePaths, setQRCodePaths] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [isActive, setIsactive] = useState<string>("");
  const [isActiveTransactionHistory, setIsactiveTransactionHistory] = useState<string>("false");
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  /* loading state */
  const [formLoader, setFormLoader] = useState<boolean>(false);

  /* formDate and toDate converted to YYYY-MM-DDTHH:mm:ss.SSS[Z] this format  */
  const formattedFromDate = fromDate ? fromDate.clone().seconds(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : "";
  const formattedToDate = toDate ? toDate.clone().seconds(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : "";

  const dateTimeValidation = (fromDateIsValid: boolean, toDateIsValid: boolean, formattedFromDate: string, formattedToDate: string) => {
    /* Validations */
    if (!fromDateIsValid) {
      message.error("Invalid 'From' date format.");
      return false;
    }

    if (!toDateIsValid) {
      message.error("Invalid 'To' date format.");
      return false;
    }

    if (formattedFromDate === formattedToDate) {
      message.error("The selected times should be different.");
      return false;
    }

    if (formattedFromDate > formattedToDate) {
      message.error("Invalid date range: 'From' date should be smaller than 'To' date.");
      return false;
    }
    return true;
  };

  const getDefaulthealthConditionIds = (healthConditionlist: any) => {
    if (!healthConditionlist || !Array.isArray(healthConditionlist)) {
      return [];
    }

    return healthConditionlist.map((item) => item.id);
  };

  useEffect(() => {
    handleChangeHealthCondition(getDefaulthealthConditionIds(healthConditionlist));
  }, [healthConditionlist]);

  useEffect(() => {
    if (filterSelectOption === "1") {
      const defaultFromDate = moment().subtract(90, "day").startOf("day").hours(0).minutes(0);
      const defaultToDate = moment();
      setFromDate(defaultFromDate);
      setToDate(defaultToDate);
    } else if (filterSelectOption === "2") {
      const defaultFromDate = moment().subtract(1, "day").startOf("day").hours(0).minutes(0);
      const defaultToDate = moment();
      setFromDate(defaultFromDate);
      setToDate(defaultToDate);
    }
  }, [filterSelectOption]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSelectedTreeIds([]);
        setHandleSubmit(false);
        // Validating date and time formats.
        const fromDateIsValid = moment(formattedFromDate, "YYYY-MM-DDTHH:mm:ss.SSS[Z]", true).isValid();
        const toDateIsValid = moment(formattedToDate, "YYYY-MM-DDTHH:mm:ss.SSS[Z]", true).isValid();

        if (!dateTimeValidation(fromDateIsValid, toDateIsValid, formattedFromDate, formattedToDate)) {
          return;
        }

        if (!projectId || projectId === null) {
          message.error("Please select appropriate filter. At least project selection is mandatory");
          return;
        }

        setReportsData([]);
        setFormLoader(true);

        /* check Report is Replantation or not*/
        const isReplantationData: boolean = false;
        const isTagMissingData: boolean = true;

        const response = await reportService.ReportTableData(
          TOKEN!,
          projectId === null ? [] : Array.isArray(projectId) ? projectId : [projectId],
          clusterId === null ? [] : Array.isArray(clusterId) ? clusterId : [clusterId],
          villageId === null ? [] : Array.isArray(villageId) ? villageId : [villageId],
          farmerId === null ? [] : Array.isArray(farmerId) ? farmerId : [farmerId],
          areaId,
          healthConditionId,
          formattedFromDate,
          formattedToDate,
          isActiveTransactionHistory,
          isReplantationData,
          isTagMissingData
        );

        const processedData =
          response &&
          response.treeReportData.map((item: any, index: number) => {
            return {
              key: index,
              ...item,
              TreeId: item.TreeId,
              TreeGeneralName: item.TreeBotanicalName.TreeGeneralName,
              BotanicalName: item.TreeBotanicalName.BotanicalName,
              TrackingDate: item.TreeTransaction[0].TrackingDate,
              HeightInCm: item.TreeTransaction[0].HeightInCm,
              GirthInCm: item.TreeTransaction[0]?.GirthInCm ?? 0,
              TreeHealth: item.TreeTransaction[0].TreeHealthCondition.TreeHealth,
              ProjectName: item.Area.Project.ProjectName,
              ClusterName: item.Area.Cluster.ClusterName,
              VillageName: item.Area.Village.VillageName,
              AreaName: item.Area.AreaName,
              FarmerName: item.Farmer?.FarmerName || "-"
            };
          });
        setReportsData(processedData);

        setQRCodePaths([]);
        const extractedPaths = response && response.treeReportData?.map((tree: any) => tree?.TreeIdQRCDocumentPath);
        setQRCodePaths(extractedPaths);
        const extractedTreeTag = response && response.treeReportData?.map((tree: any) => tree?.TreeTag);
        setTreeTagList(extractedTreeTag);

        /* table data export in excel sheet */
        const processedExcelData =
          response &&
          response.treeReportData.map((item: any, index: number) => {
            return {
              "Tree Tag": item.TreeTag,
              "General Name": item.TreeBotanicalName.TreeGeneralName,
              "Botanical Name": item.TreeBotanicalName.BotanicalName,
              "Plantation Date": moment(item.PlantationDate).format("DD-MM-YYYY"),
              "Last Tracking Date": moment(item.TreeTransaction[0].TrackingDate).format("DD-MM-YYYY"),
              "Height(cm)": item.TreeTransaction[0].HeightInCm,
              "Girth(cm)": item.TreeTransaction[0]?.GirthInCm ?? 0,
              "Health Status": item.TreeTransaction[0].TreeHealthCondition.TreeHealth,
              "Project Name": item.Area.Project.ProjectName,
              "Cluster Name": item.Area.Cluster.ClusterName,
              "Village Name": item.Area.Village.VillageName,
              "Area Name": item.Area.AreaName,
              "Farmer Name": item.Farmer?.FarmerName || "-"
            };
          });

        setReportExportData(processedExcelData);
      } catch (error) {
        message.destroy();
        setReportsData([]);
        setIsModalOpen(true);
        setHandleSubmit(false);
      } finally {
        setFormLoader(false);
        setHandleSubmit(false);
      }
    };

    const fetchList = async () => {
      if (handleSubmit) {
        await fetchData();
      }
    };

    fetchList();
  }, [handleSubmit]);

  /* project list */
  useEffect(() => {
    const fetchlist = async () => {
      try {
        setProjectlist([]);
        const response = await orgmasterServices.getOneOrgMasterListById(TOKEN!, OrgMasterModulesAndMapping.PROJECT, isActive);
        const res = response.AllRecordsList.map((item: any) => {
          return {
            ...item,
            key: item.ProjectId,
            name: item.ProjectName,
            id: item.ProjectId
          };
        });
        if (res.length > 0) {
          setProjectlist(res);
        } else {
          setProjectlist([]);
        }
      } catch (error: any) {
        setProjectlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    fetchlist();
  }, [isActive]);

  /* cluster list */
  useEffect(() => {
    const renderList = async () => {
      if (!projectId || projectId === 0) {
        return;
      }
      try {
        setClusterlist([]);
        const response = await orgmasterServices.getOneOrgMasterListByClusterId(TOKEN!, projectId, isActive);

        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.ClusterId,
              name: item.ClusterName,
              id: item.ClusterId
            };
          });
        if (res.length > 0) {
          setClusterlist(res);
        } else {
          setClusterlist([]);
        }
      } catch (error: any) {
        setClusterlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (projectId) {
        await renderList();
      }
    };
    fetchList();
  }, [projectId]);

  /* village list */
  useEffect(() => {
    const renderList = async () => {
      if (!clusterId || clusterId === 0) {
        return;
      }
      try {
        setVillagelist([]);
        const response = await orgmasterServices.getOneOrgMasterListByVillageId(TOKEN!, clusterId, isActive);
        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.VillageId,
              name: item.VillageName,
              id: item.VillageId
            };
          });
        if (res.length > 0) {
          setVillagelist(res);
        } else {
          setVillagelist([]);
        }
      } catch (error: any) {
        setVillagelist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (clusterId) {
        await renderList();
      }
    };
    fetchList();
  }, [clusterId]);

  /* farmer list */
  useEffect(() => {
    const renderList = async () => {
      if (!villageId || villageId === 0) {
        return;
      }
      try {
        setArealist([]);
        const response = await reportService.getFarmerListByVillageId(TOKEN!, villageId, String(isActive));

        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.FarmerId,
              name: item.FarmerName,
              id: item.FarmerId
            };
          });
        if (res.length > 0) {
          setFarmerlist(res);
        } else {
          setFarmerlist([]);
        }
      } catch (error: any) {
        setFarmerlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (villageId) {
        await renderList();
      }
    };
    fetchList();
  }, [villageId]);

  /* area list */
  useEffect(() => {
    const renderList = async () => {
      if (!villageId || villageId === 0) {
        return;
      }
      try {
        setArealist([]);
        const response = await reportService.getOneOrgMasterListByAreaId(TOKEN!, villageId, isActive);

        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.AreaId,
              name: item.AreaName,
              id: item.AreaId
            };
          });
        if (res.length > 0) {
          setArealist(res);
        } else {
          setArealist([]);
        }
      } catch (error: any) {
        setArealist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (villageId) {
        await renderList();
      }
    };
    fetchList();
  }, [villageId]);

  /* health condition list */
  useEffect(() => {
    const fetchlist = async () => {
      try {
        setHealthConditionlist([]);
        const response = await masterServices.getOneMasterListById(TOKEN!, ModuleAndIdMapping.TREEHEALTH);

        const res =
          response &&
          response.AllRecordsList.map((item: any) => {
            return {
              ...item,
              key: item.TreeHealthId,
              name: item.TreeHealth,
              id: item.TreeHealthId
            };
          });
        setHealthConditionlist(res);
      } catch (error: any) {
        setHealthConditionlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    fetchlist();
  }, []);

  const disabledFromDate = (current: Moment | null): boolean => {
    return !!current && current > moment().endOf("day");
  };

  const disabledToDate = (current: Moment | null): boolean => {
    return !!current && !!fromDate && (current < moment(fromDate).startOf("day") || current > moment().endOf("day"));
  };

  const handleFromDateChange = (value: Moment | null) => {
    if (value) {
      setFromDate(value);

      // Update toDate if fromDate is later than current toDate
      if (value.isAfter(toDate)) {
        setToDate(moment(value).hours(23).minutes(45));
      }
    }
  };

  const handleToDateChange = (value: Moment | null) => {
    setToDate(value);
  };

  const handleChange = (pagination: TablePaginationConfig): void => {
    setPagination(pagination);
  };

  const handleGo = () => {
    if (filterSelectOption === "1") {
      if (selectDate === 7) {
        handleLastSevenDay();
      } else if (selectDate === 30) {
        handleLastThirtyDay();
      } else if (selectDate === 60) {
        handleLastSixtyDay();
      } else if (selectDate === 90) {
        handleLastNinetyDay();
      }
    }
    setHandleSubmit(true);
  };

  /* filters all function */

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const handleOptionSelection = (selectedOption: string) => {
    // Split the selectedOption to get the month and year separately
    const [monthOption, yearOption] = selectedOption.split(" - ");
    setFilterSelectOption(selectedOption);
    setSelectDate(0);
    if (selectedOption === "1") {
      setSelectDate(90);
    }

    const selectedMonthIndex = monthNames.findIndex((month) => month === monthOption);

    // Generate the fromDate and toDate strings in the format "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    const currentDate = moment();
    const currentMonth = moment().format("MMMM");

    if (currentMonth === monthOption) {
      setFromDate(
        currentDate
          .clone()
          .date(1) // Set the day to the 1st day of the month
          .month(selectedMonthIndex) // Set the month to the selected month index (0-based)
          .hour(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
      );

      setToDate(currentDate.clone().seconds(0).milliseconds(0));
    } else {
      // Handle the case when the selected month is different from the current month
      const daysInSelectedMonth = moment(selectedOption, "MMMM").daysInMonth();
      setFromDate(currentDate.clone().month(selectedMonthIndex).year(+yearOption).date(1).hour(0).minutes(0).seconds(0).milliseconds(0));

      setToDate(currentDate.clone().month(selectedMonthIndex).year(+yearOption).date(daysInSelectedMonth).hour(23).minutes(45).seconds(0).milliseconds(0));
    }
  };

  const handleLastSevenDay = () => {
    const currentDate = moment();
    const lastOneDay = moment().subtract(7, "day");

    setFromDate(lastOneDay.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(7);
  };

  const handleLastThirtyDay = () => {
    const currentDate = moment();
    const lastSevenDays = moment().subtract(30, "days");

    setFromDate(lastSevenDays.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(30);
  };

  const handleLastSixtyDay = () => {
    const currentDate = moment();
    const lastFifteenDays = moment().subtract(60, "days");

    setFromDate(lastFifteenDays.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(60);
  };

  const handleLastNinetyDay = () => {
    const currentDate = moment();
    const lastThirtyDays = moment().subtract(90, "days");

    setFromDate(lastThirtyDays.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(90);
  };

  const showFromDate = fromDate ? fromDate.format("DD-MM-YYYY") : "";
  const showToDate = toDate ? toDate.format("DD-MM-YYYY") : "";

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const exportToExcel = () => {
    const csvData = reportExportData || [];
    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Specify the type as "array" to avoid type error
    const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `Include_Missing_Tag_List_${showFromDate}--${showToDate}.xlsx`);
  };

  const showConfirmModal = () => {
    setConfirmModalVisible(true);
  };

  const handleCancel = () => {
    setConfirmModalVisible(false);
  };

  const handleConfirm = async () => {
    try {
      setLoader(true);
      const response = await reportService.updateMissingTreeTag(TOKEN!, areaId, false, selectedTreeIds);
      if (response) {
        message.destroy();
        message.success(response.message);
        setHandleSubmit(true);
        setSelectedTreeIds([]);
      }
    } catch (error: any) {
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    } finally {
      setLoader(false);
      setConfirmModalVisible(false);
    }
  };

  const handleResetTagsClick = () => {
    showConfirmModal();
  };

  const columns: Column[] = [
    {
      align: "right",
      title: "SN",
      dataIndex: "key",
      key: "key",
      sorter: {
        compare: (a, b) => a.key - b.key
      },
      render: (text, record, index) => <div>{record.key + 1}</div>,
      width: 50
    },
    {
      align: "left",
      title: "Tree Tag",
      dataIndex: "TreeTag",
      key: "TreeTag",
      sorter: {
        compare: (a, b) => a.TreeTag.localeCompare(b.TreeTag)
      },
      render: (text) => <div>{text}</div>,
      width: "9%"
    },
    {
      align: "left",
      title: "General Name",
      dataIndex: "TreeGeneralName",
      key: "TreeGeneralName",
      sorter: {
        compare: (a, b) => a.TreeGeneralName.localeCompare(b.TreeGeneralName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Botanical Name",
      dataIndex: "BotanicalName",
      key: "BotanicalName",
      sorter: {
        compare: (a, b) => a.BotanicalName.localeCompare(b.BotanicalName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "right",
      title: "Plantation Date",
      dataIndex: "PlantationDate",
      key: "PlantationDate",
      sorter: {
        compare: (a, b) => a.PlantationDate.localeCompare(b.PlantationDate)
      },
      render: (text) => <div>{moment(text).format("DD-MM-YYYY")}</div>
    },
    {
      align: "right",
      title: "Last Tracking Date",
      dataIndex: "TrackingDate",
      key: "TrackingDate",
      sorter: {
        compare: (a, b) => a.TrackingDate.localeCompare(b.TrackingDate)
      },
      render: (text) => <div>{moment(text).format("DD-MM-YYYY")}</div>
    },
    {
      align: "right",
      title: "Height(cm)",
      dataIndex: "HeightInCm",
      key: "HeightInCm",
      sorter: {
        compare: (a, b) => a.HeightInCm.localeCompare(b.HeightInCm)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "right",
      title: "Girth(cm)",
      dataIndex: "GirthInCm",
      key: "GirthInCm",
      sorter: {
        compare: (a, b) => {
          // Convert the values to numbers before comparing
          const girthA = parseFloat(a.GirthInCm);
          const girthB = parseFloat(b.GirthInCm);
          return girthA - girthB;
        }
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Health Status",
      dataIndex: "TreeHealth",
      key: "TreeHealth",
      sorter: {
        compare: (a, b) => a.TreeHealth.localeCompare(b.TreeHealth)
      },
      render: (text) => (
        <div>
          {text === "GOOD" ? (
            <Tag className="gx-rounded-xs" color="#64e764">
              {text}
            </Tag>
          ) : text === "NEED-ATTENTION" ? (
            <Tag className="gx-rounded-xs" color="#F7B239">
              {text}
            </Tag>
          ) : text === "VERY GOOD" ? (
            <Tag className="gx-rounded-xs" color="#4CB706">
              {text}
            </Tag>
          ) : text === "DEAD" ? (
            <Tag className="gx-rounded-xs" color="#F75039">
              {text}
            </Tag>
          ) : (
            <Tag className="gx-rounded-xs" color="#B6FA8A">
              {text}
            </Tag>
          )}
        </div>
      )
    },
    {
      align: "left",
      title: "Project",
      dataIndex: "ProjectName",
      key: "ProjectName",
      sorter: {
        compare: (a, b) => a.ProjectName.localeCompare(b.ProjectName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Cluster",
      dataIndex: "ClusterName",
      key: "ClusterName",
      sorter: {
        compare: (a, b) => a.ClusterName.localeCompare(b.ClusterName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Village",
      dataIndex: "VillageName",
      key: "VillageName",
      sorter: {
        compare: (a, b) => a.VillageName.localeCompare(b.VillageName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Area",
      dataIndex: "AreaName",
      key: "AreaName",
      sorter: {
        compare: (a, b) => a.AreaName.localeCompare(b.AreaName)
      },

      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Farmer",
      dataIndex: "FarmerName",
      key: "FarmerName",
      sorter: {
        compare: (a, b) => a.FarmerName.localeCompare(b.FarmerName)
      },
      render: (text) => <div>{text}</div>
    },

    {
      align: "center",
      title: "Action",
      key: "actions",
      render: (text, record) => <Checkbox onChange={() => handleCheckboxChange(record)} />
    }
  ];

  const handleCheckboxChange = (record: any) => {
    const treeId = record.TreeId;
    setSelectedTreeIds((prevSelectedTreeIds: number[]) => {
      if (prevSelectedTreeIds.includes(treeId)) {
        // If already selected, remove from the array
        return prevSelectedTreeIds.filter((id) => id !== treeId);
      } else {
        // If not selected, add to the array
        return [...prevSelectedTreeIds, treeId];
      }
    });
  };

  const handleChangeProject = (value: number) => {
    setSelectedTreeIds([]);
    setReportsData([]);
    const matchingProject: any = projectlist?.find((item: any) => item?.id === value);
    setProjectName(`${matchingProject?.name}`);
    setProjectId(value);
    setClusterlist([]);
    setVillagelist([]);
    setFarmerId(null);
    setFarmerlist([]);
    setArealist([]);
    setClusterId(null);
    setVillageId(null);
    setAreaId([]);
  };
  const handleChangeCluster = (value: number) => {
    setSelectedTreeIds([]);
    setReportsData([]);
    setClusterId(value);
    setVillagelist([]);
    setFarmerId(null);
    setFarmerlist([]);
    setArealist([]);
    setVillageId(null);
    setAreaId([]);
  };
  const handleChangeVillage = (value: number) => {
    setSelectedTreeIds([]);
    setReportsData([]);
    setVillageId(value);
    setFarmerId(null);
    setFarmerlist([]);
    setArealist([]);
    setAreaId([]);
  };
  const handleChangeArea = (value: number[]) => {
    setSelectedTreeIds([]);
    setReportsData([]);
    setAreaId(value);
  };
  const handleChangeFarmer = (value: number) => {
    setSelectedTreeIds([]);
    setReportsData([]);
    setFarmerId(value);
  };
  const handleChangeHealthCondition = (value: any) => {
    setSelectedTreeIds([]);
    setReportsData([]);
    setHealthConditionId(value);
  };

  const renderSelect = (key: string, value: any, handleChange: any, options: any) => {
    const selectAllOption = { id: "all", name: "Select All" };
    const unselectAllOption = { id: "none", name: "Unselect All" };

    // Function to handle "Select All" click
    const handleSelectAll = () => {
      const allOptionIds = options.map((option: any) => option.id);
      handleChange([...allOptionIds]);
    };

    // Function to handle "Unselect All" click
    const handleUnselectAll = () => {
      handleChange([]);
    };

    const isSelectAllSelected = Array.isArray(value) && value.includes(selectAllOption.id);

    // If "Select All" is selected, update value to include all option IDs
    const updatedValue = isSelectAllSelected ? options.map((option: any) => option.id) : value;

    return (
      <Select
        getPopupContainer={(trigger) => trigger.parentElement}
        showSearch
        allowClear
        maxTagCount="responsive"
        dropdownStyle={{ overflowY: "auto" }}
        mode={key === "Area" || key === "Health Condition" ? "multiple" : undefined}
        optionFilterProp="children"
        disabled={options.length === 0}
        className={`${styles["input-dropdown"]}`}
        placeholder={`Select ${key}`}
        style={{ minWidth: "100%", width: 200 }}
        filterOption={(input: any, option: any) => {
          const children = option?.children as unknown;
          if (typeof children === "string") {
            return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        onChange={(newValue: any) => {
          if (key === "Area" || key === "Health Condition") {
            if (Array.isArray(newValue) && newValue.includes(selectAllOption.id)) {
              handleSelectAll();
            } else if (Array.isArray(newValue) && newValue.includes(unselectAllOption.id)) {
              handleUnselectAll();
            } else {
              handleChange(Array.isArray(newValue) ? newValue.filter((id: any) => id !== selectAllOption.id && id !== unselectAllOption.id) : []);
            }
          } else {
            handleChange(newValue);
          }
        }}
        value={key === "Area" || key === "Health Condition" ? updatedValue : value}
      >
        {/* Add the "Select All" option */}
        {key === "Area" || key === "Health Condition" ? (
          <Select.Option value={selectAllOption.id} key={selectAllOption.id}>
            {selectAllOption.name}
          </Select.Option>
        ) : null}

        {/* Add the "Unselect All" option */}
        {/* Add the "Placeholder option" option */}
        {key === "Area" || key === "Health Condition" ? (
          <Select.Option value={unselectAllOption.id} key={unselectAllOption.id}>
            {unselectAllOption.name}
          </Select.Option>
        ) : (
          <Select.Option value={[]}>{`Select ${key}`}</Select.Option>
        )}

        {/* Render other options */}
        {options?.map((option: any) => (
          <Select.Option value={option.id} key={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  /* Barcode Imge and Tree tag added dashed Boarder  */

  async function loadImages(imagePath: string[]) {
    const imagesPromises = imagePath.map(async (image: string) => {
      const blob = await fetch(`${IMAGE_BASE_URL}${image}`).then((response) => response.blob());
      return URL.createObjectURL(blob);
    });

    return Promise.all(imagesPromises);
  }

  function drawDashedBorder(pdf: any, x: number, y: number, width: number, height: number, dashLength: number, gapLength: number) {
    // Draw top border
    for (let i = x; i <= x + width; i += dashLength + gapLength) {
      const endX = Math.min(i + dashLength, x + width);
      pdf.line(i, y, endX, y);
    }

    // Draw bottom border
    for (let i = x; i <= x + width; i += dashLength + gapLength) {
      const endX = Math.min(i + dashLength, x + width);
      pdf.line(i, y + height, endX, y + height);
    }

    // Draw left border
    for (let i = y; i <= y + height; i += dashLength + gapLength) {
      const endY = Math.min(i + dashLength, y + height);
      pdf.line(x, i, x, endY);
    }

    // Draw right border
    for (let i = y; i <= y + height; i += dashLength + gapLength) {
      const endY = Math.min(i + dashLength, y + height);
      pdf.line(x + width, i, x + width, endY);
    }
  }

  function reverseArrayInGroups(data: any, groupSize: number) {
    const reversedData = [];
    for (let i = 0; i < data.length; i += groupSize) {
      const chunk = data.slice(i, i + groupSize).reverse();
      reversedData.push(...chunk);
    }
    return reversedData;
  }

  async function handleGeneratePdf(imagePath: string[], treeTagList: string[], batch: number) {
    // setLoadingExportFile(true);
    // setModalVisibleExport(true);

    const pdf = new jsPDF();
    const images = await loadImages(imagePath);

    const imagesPerRow = 5;
    const rowsPerPage = 16;
    const spacingX = 3;
    const spacingY = 3;

    const totalImages = images.length;
    const totalTexts = treeTagList.length;
    const totalPages = Math.max(Math.ceil(totalImages / (imagesPerRow * rowsPerPage)), Math.ceil(totalTexts / (imagesPerRow * rowsPerPage)));

    for (let page = 1; page <= totalPages; page++) {
      const startImageIndex = (page - 1) * imagesPerRow * rowsPerPage;
      const endImageIndex = Math.min(startImageIndex + imagesPerRow * rowsPerPage, totalImages);
      const startTextIndex = (page - 1) * imagesPerRow * rowsPerPage;
      const endTextIndex = Math.min(startTextIndex + imagesPerRow * rowsPerPage, totalTexts);

      const pageData = {
        images: images.slice(startImageIndex, endImageIndex),
        texts: treeTagList.slice(startTextIndex, endTextIndex)
      };

      let xOffset = 7;
      let yOffset = 14;
      let imagesCount = 0;

      for (const imageUrl of pageData.images) {
        if (imagesCount === imagesPerRow) {
          xOffset = 7;
          yOffset += 14 + spacingY;
          imagesCount = 0;
        }

        const smallPadding = -2; // Adjust the padding value

        pdf.setDrawColor(0);
        pdf.setLineWidth(0.5);

        drawDashedBorder(pdf, xOffset + smallPadding, yOffset + smallPadding, 36 - 2 * smallPadding, 13 - 2 * smallPadding, 2, 2);

        pdf.addImage(imageUrl, "JPEG", xOffset, yOffset, 36, 13);
        xOffset += 37 + spacingX;
        imagesCount++;
      }

      pdf.addPage();

      // Reuse the same code for drawing text pages
      xOffset = 7;
      yOffset = 14;
      imagesCount = 0;

      const reversedData = reverseArrayInGroups(pageData?.texts, 5);

      for (const text of reversedData) {
        if (imagesCount === imagesPerRow) {
          xOffset = 7;
          yOffset += 14 + spacingY;
          imagesCount = 0;
        }

        const fontSize = 10;
        pdf.setFontSize(fontSize);

        const smallPadding = -2; // Adjust the padding value

        pdf.setDrawColor(0);
        pdf.setLineWidth(0.5);

        drawDashedBorder(pdf, xOffset + smallPadding, yOffset + smallPadding, 36 - 2 * smallPadding, 13 - 2 * smallPadding, 2, 3);

        pdf.text(text, xOffset + 2, yOffset + 7);
        xOffset += 37 + spacingX;
        imagesCount++;
      }

      pdf.addPage();
    }

    // setLoadingExportFile(false);
    // setModalVisibleExport(false);
    pdf.save(`${projectName}_${moment().format("DD-MM-YYYY HH:mm")}_${batch}.pdf`);
  }

  async function handleArraysList(array1: string[], array2: string[]) {
    const batchSize = 640;

    // Check the lengths of the input arrays
    if (array1.length === 0 || array2.length === 0) {
      return;
    }

    setLoadingExportFile(true);
    setModalVisibleExport(true);

    // Divide array1 into batches
    const batches1: string[][] = [];
    for (let i = 0; i < array1.length; i += batchSize) {
      const batch = array1.slice(i, i + batchSize);
      batches1.push(batch);
    }

    // Divide array2 into batches
    const batches2: string[][] = [];
    for (let i = 0; i < array2.length; i += batchSize) {
      const batch = array2.slice(i, i + batchSize);
      batches2.push(batch);
    }
    setTotalFiles(batches1.length);
    // Use a single loop to iterate through both batches1 and batches2
    for (let index = 0; index < Math.max(batches1.length, batches2.length); index++) {
      const batch1 = index < batches1.length ? batches1[index] : [];
      const batch2 = index < batches2.length ? batches2[index] : [];

      setBatchSize(index + 1);
      await handleGeneratePdf(batch1, batch2, index + 1);
    }
    setLoadingExportFile(false);
    setModalVisibleExport(false);
  }

  return (
    <>
      <Card className="card--height">
        <CommonModal resetModal={handleOk} showModal={isModalOpen} modalMessage={"No data found for this selection."} title="Message" />
        <h3 style={{ paddingBottom: "10px" }}>Missing TreeTag Statistics</h3>

        <div className={styles["filter-container-filter1"]}>
          <div className={styles["filter-container-filter1-container"]}>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Project", projectId, handleChangeProject, projectlist)}</div>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Cluster", clusterId, handleChangeCluster, clusterlist)}</div>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Village", villageId, handleChangeVillage, villagelist)}</div>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Area", areaId, handleChangeArea, arealist)}</div>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Farmer", farmerId, handleChangeFarmer, farmerlist)}</div>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Health Condition", healthConditionId, handleChangeHealthCondition, healthConditionlist)}</div>
          </div>
        </div>
        <div className={styles["filter-container-filter2"]}>
          <div className={styles["filter-dropdown-container"]} style={{ width: navCollapsed ? "160px" : "200px" }}>
            <Dropdown onSelectOption={handleOptionSelection} />
          </div>
          {filterSelectOption === "1" ? (
            <div className={styles["filter-Lastdays"]}>
              <div
                className={`${styles["filter-dashboard-option"]} ${selectDate === 7 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color: selectDate === 7 ? "#fff" : "",
                  opacity: 1
                }}
                onClick={handleLastSevenDay}
              >
                7
              </div>
              <div
                className={`${styles["filter-dashboard-option"]} ${selectDate === 30 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color: selectDate === 30 ? "#fff" : "",
                  opacity: 1
                }}
                onClick={handleLastThirtyDay}
              >
                30
              </div>
              <div
                className={`${styles["filter-dashboard-option"]} ${selectDate === 60 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color: selectDate === 60 ? "#fff" : "",
                  opacity: 1
                }}
                onClick={handleLastSixtyDay}
              >
                60
              </div>
              <div
                className={`${styles["filter-dashboard-option"]} ${selectDate === 90 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color: selectDate === 90 ? "#fff" : "",
                  opacity: 1
                }}
                onClick={handleLastNinetyDay}
              >
                90
              </div>
            </div>
          ) : filterSelectOption === "2" ? (
            <div className={styles["filter-customDate"]}>
              <DatePicker style={{ width: "150px" }} format="DD-MM-YYYY" className={styles["datepicker"]} allowClear={false} disabledDate={disabledFromDate} value={fromDate} placeholder="Select Start Date" onChange={handleFromDateChange} />
              <DatePicker style={{ width: "150px" }} format="DD-MM-YYYY" className={styles["datepicker"]} allowClear={false} disabledDate={disabledToDate} value={toDate} placeholder="Select End Date" onChange={handleToDateChange} />
            </div>
          ) : (
            ""
          )}

          <div className={styles["filter-showDate-container"]}>
            <div className={styles["filter-showDate-container-1"]}>
              <span>From Date :</span>
              <span>{showFromDate}</span>
            </div>
            <div className={styles["filter-showDate-container-1"]}>
              <span>To Date :</span>
              <span>{showToDate}</span>
            </div>
          </div>

          <div className={styles["filterClick"]} style={{ display: "flex", justifyContent: "space-between" }}>
            <Button type="primary" style={{ marginLeft: "12px", margin: "0 auto" }} onClick={handleGo}>
              Show Details
            </Button>
            <Button type="primary" disabled={loader || !reportsData || selectedTreeIds.length == 0} style={{ marginLeft: "22px", margin: "0 auto auto 22px" }} onClick={handleResetTagsClick}>
              Reset Missing TreeTags
            </Button>
          </div>

          <div className={`${styles["trecords"]}`}>
            <h4>Total Records: {(reportsData && reportsData?.length) ?? 0}</h4>
          </div>
          <div className={`${styles["exportButton"]}`}>
            <Tooltip title="Download Report">
              {reportsData && reportsData?.length ? (
                <Button onClick={exportToExcel} className={styles["export-button-gradiant"]}>
                  Export
                </Button>
              ) : (
                <Button className={styles["export-button-gradiant-reset"]}>Export</Button>
              )}
            </Tooltip>
          </div>
          <div className={`${styles["exportButtonQRCode"]}`}>
            <Tooltip title="Download BarCodes">
              {reportsData && reportsData?.length ? (
                <Button onClick={() => handleArraysList(qrCodePaths, treeTagList)} className={styles["export-button-gradiant"]}>
                  Export BarCodes
                </Button>
              ) : (
                <Button className={styles["export-button-gradiant-reset"]}>Export BarCodes</Button>
              )}
            </Tooltip>
          </div>
        </div>

        {formLoader ? (
          <div className="loading loading--center">
            <Space direction="vertical" className="space--width" size="large">
              <Spin tip="Loading" size="large" />
            </Space>
          </div>
        ) : (
          <>
            <Table
              className="gx-table-responsive"
              columns={columns}
              dataSource={reportsData || undefined}
              bordered
              onChange={handleChange}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                showSizeChanger: true,
                pageSizeOptions
              }}
            ></Table>
          </>
        )}
      </Card>
      {/* Modal for loader Export file Unused BarCodes */}
      <Modal
        // title="Generating Barcodes"
        visible={modalVisibleExport}
        // header={null}
        footer={null}
        closable={false}
        centered // Add this centered prop
      >
        <h4>Total Download Files: {totalFiles}</h4>
        <h4>Downloaded Files: {batchSize - 1}</h4>
        <Spin tip="Downloading Un-used Barcodes, Please wait..." spinning={loadingExportFile}>
          <Space size="middle">
            <Button type="primary" style={{ display: "block", margin: "0 auto" }} onClick={() => setModalVisibleExport(false)}>
              {""}
            </Button>
          </Space>
        </Spin>
      </Modal>
      <Modal title={`Are you sure you want to reset missing tags?`} visible={confirmModalVisible} onOk={handleConfirm} onCancel={handleCancel} okText="Yes" cancelText="No" width="500px" destroyOnClose maskClosable={true} bodyStyle={{ padding: 0 }} wrapClassName="delete-modal"></Modal>
    </>
  );
};

export default MissingTreeTagReport;
