const enum LocalStorageConstants {
  TOKEN_EXPIRY_TIMESTAMP = "tokenExpiryTimeStamp",
  USER = "user",
  REFRESH_TOKEN = "refreshToken",
  FORM = "Form",
  TOKEN = "token",
  IS_TOKEN_EXPIRED = "isTokenExpired",
  IS_USER_ACTIVE = "isUserActive",
  FILTERS_DATA = "filtersData"
}

export { LocalStorageConstants };
