import { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, Card, message, Spin, Space, Row, Col } from "antd";
import jsPDF from "jspdf";
import styles from "../Reports/reports.module.css";
import * as userService from "../../services/userServices";
import moment from "moment-timezone";
import * as treeServices from "../../services/treeServices";
import { LocalStorageConstants } from "../../constants/localStorageConstants";

const IMAGE_BASE_URL = process.env.REACT_APP_API_URL?.split("/").slice(0, 3).join("/");

const GeneateBarcodes: React.FC = () => {
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const [arealist, setArealist] = useState<number[]>([]);
  const [areaId, setAreaId] = useState<number | null>(null);
  const [customRange, setCustomRange] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [unUsedQrCode, setUnusedQrCode] = useState<any>([]);
  const [treeTagList, setTreeTagList] = useState<string[]>([]);
  const [handleSubmit, setHandleSubmit] = useState(false);
  const [batchSize, setBatchSize] = useState<any>(null);
  const [totalFiles, setTotalFiles] = useState<any>(null);

  const [error, setError] = useState<string>("");
  const [areaName, setAreaName] = useState("");
  const [unUsedBarCodeRefresh, setUnUsedBarCodeRefresh] = useState(false);

  const [loading, setLoading] = useState(false); // Add this state for the loader
  const [modalVisible, setModalVisible] = useState(false); // Add this state for modal visibility

  const [loadingExportFile, setLoadingExportFile] = useState(false);
  const [modalVisibleExport, setModalVisibleExport] = useState(false); // Add this state for modal visibility

  /* Fetching all Area Tables list when visited using select */
  useEffect(() => {
    const renderAreaList = async () => {
      try {
        // setFormLoader(true);
        setArealist([]);
        const response = await userService.getOneMasterAreaList(TOKEN!);
        const processedData: any = response.areaList.map((item: any) => {
          return { id: item?.AreaId, name: `${item?.Project.ProjectName} >> ${item?.AreaName}`, ...item };
        });

        setArealist(processedData);
      } catch (error: any) {
        setArealist([]);
        // setFormLoader(false);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    renderAreaList();
  }, []);

  // Fetch unused barcode count when areaId changes
  useEffect(() => {
    const handleUnusedBarcodes = async () => {
      try {
        if (!areaId || areaId === null) return;
        setUnusedQrCode(null);
        const response = await treeServices.getUnusedBarcodeList(TOKEN, areaId);

        const extractedPaths = response && response?.UnusedBarcodeList?.map((tree: any) => tree?.TreeIdQRCDocumentPath);
        const extractedTreeTag = response && response?.UnusedBarcodeList?.map((tree: any) => tree?.TreeTag);
        setTreeTagList(extractedTreeTag);
        setUnusedQrCode(extractedPaths);
        setUnUsedBarCodeRefresh(false);
      } catch (error: any) {
        setTreeTagList([]);
        setUnusedQrCode(null);
        setUnUsedBarCodeRefresh(false);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    if (unUsedBarCodeRefresh) handleUnusedBarcodes();
  }, [areaId, unUsedBarCodeRefresh]);

  useEffect(() => {
    const fetchBarcodes = async () => {
      try {
        if (!areaId || error !== "") {
          message.error("Please select an area and specify a valid range.");
          return;
        }
        // Show the modal and start loading
        setModalVisible(true);
        setLoading(true);
        const response = await treeServices.generateBarcodes(TOKEN, areaId, customRange);

        const extractedPaths = response && response?.newTree?.map((tree: any) => tree?.treeHeader?.TreeIdQRCDocumentPath);
        const extractedTreeTag = response && response?.newTree?.map((tree: any) => tree?.treeHeader?.TreeTag);

        if (extractedPaths && extractedTreeTag && extractedPaths.length > 0) {
          await handleArraysList(extractedPaths, extractedTreeTag); // Then call handleGeneratePdf
          setUnUsedBarCodeRefresh(true);
        } else {
          message.error("No barcodes were generated.");
        }

        setModalVisible(false);
        setLoading(false);
        setHandleSubmit(false);
      } catch (error: any) {
        message.destroy();
        setModalVisible(false);
        setLoading(false);
        setHandleSubmit(false);
        message.error("Error: " + (error?.message || ""));
      }
    };
    if (handleSubmit) fetchBarcodes();
  }, [handleSubmit]);

  useEffect(() => {
    message.destroy();
    if (error !== "") message.error(error);
  }, [error]);

  const handleChangeAreaList = (value: number) => {
    const matchingArea: any = arealist?.find((area: any) => area?.id === value);
    setAreaName(`${matchingArea?.AreaName}`);
    setAreaId(value);
    setUnUsedBarCodeRefresh(true);
    setHandleSubmit(false);
  };

  const renderSelect = (key: string, value: any, handleChange: any, options: any) => {
    return (
      <Select
        getPopupContainer={(trigger) => trigger.parentElement}
        showSearch
        allowClear
        maxTagCount="responsive"
        dropdownStyle={{ overflowY: "auto" }}
        optionFilterProp="children"
        disabled={options.length === 0}
        className={`${styles["input-dropdown"]}`}
        placeholder={`Select ${key}`}
        style={{ minWidth: "100%", width: 480 }}
        filterOption={(input: any, option: any) => {
          const children = option?.children as unknown;
          if (typeof children === "string") {
            return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        onChange={(newValue: any) => {
          handleChange(newValue);
        }}
        value={value}
      >
        <Select.Option value={[]}>{`Select ${key}`}</Select.Option>

        {/* Render other options */}
        {options?.map((option: any) => (
          <Select.Option value={option.id} key={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const handleInputChange = async (e: React.FocusEvent<HTMLInputElement>) => {
    try {
      const inputValue = e.target.value;

      if (/^\d+$/.test(inputValue)) {
        // Input contains only digits, no decimals
        const numericValue = parseInt(inputValue, 10);

        if (numericValue > 0 && numericValue >= 1 && numericValue <= 500) {
          setError("");
          setCustomRange(numericValue);
        } else {
          setError("Please enter a positive integer between 1 to 500.");
        }
      } else {
        setError("Please enter a positive integer with no decimals between 1 to 500.");
      }

      if (inputValue.trim() === "") {
        setError("Invalid input. Please enter a non-empty integer.");
      }
    } catch (error: any) {
      setError("");
      message.destroy();
      message.error(error.message);
    }
  };

  /* Barcode Imge and Tree tag added dashed Boarder  */

  async function loadImages(imagePath: string[]) {
    const imagesPromises = imagePath.map(async (image: string) => {
      const blob = await fetch(`${IMAGE_BASE_URL}${image}`).then((response) => response.blob());
      return URL.createObjectURL(blob);
    });

    return Promise.all(imagesPromises);
  }

  function drawDashedBorder(pdf: any, x: number, y: number, width: number, height: number, dashLength: number, gapLength: number) {
    // Draw top border
    for (let i = x; i <= x + width; i += dashLength + gapLength) {
      const endX = Math.min(i + dashLength, x + width);
      pdf.line(i, y, endX, y);
    }

    // Draw bottom border
    for (let i = x; i <= x + width; i += dashLength + gapLength) {
      const endX = Math.min(i + dashLength, x + width);
      pdf.line(i, y + height, endX, y + height);
    }

    // Draw left border
    for (let i = y; i <= y + height; i += dashLength + gapLength) {
      const endY = Math.min(i + dashLength, y + height);
      pdf.line(x, i, x, endY);
    }

    // Draw right border
    for (let i = y; i <= y + height; i += dashLength + gapLength) {
      const endY = Math.min(i + dashLength, y + height);
      pdf.line(x + width, i, x + width, endY);
    }
  }

  function reverseArrayInGroups(data: any, groupSize: number) {
    const reversedData = [];
    for (let i = 0; i < data.length; i += groupSize) {
      const chunk = data.slice(i, i + groupSize).reverse();
      reversedData.push(...chunk);
    }
    return reversedData;
  }

  async function handleGeneratePdf(imagePath: string[], treeTagList: string[], batch: number) {
    // setLoadingExportFile(true);
    // setModalVisibleExport(true);

    const pdf = new jsPDF();
    const images = await loadImages(imagePath);

    const imagesPerRow = 5;
    const rowsPerPage = 16;
    const spacingX = 3;
    const spacingY = 3;

    const totalImages = images.length;
    const totalTexts = treeTagList.length;
    const totalPages = Math.max(Math.ceil(totalImages / (imagesPerRow * rowsPerPage)), Math.ceil(totalTexts / (imagesPerRow * rowsPerPage)));

    for (let page = 1; page <= totalPages; page++) {
      const startImageIndex = (page - 1) * imagesPerRow * rowsPerPage;
      const endImageIndex = Math.min(startImageIndex + imagesPerRow * rowsPerPage, totalImages);
      const startTextIndex = (page - 1) * imagesPerRow * rowsPerPage;
      const endTextIndex = Math.min(startTextIndex + imagesPerRow * rowsPerPage, totalTexts);

      const pageData = {
        images: images.slice(startImageIndex, endImageIndex),
        texts: treeTagList.slice(startTextIndex, endTextIndex)
      };

      let xOffset = 7;
      let yOffset = 14;
      let imagesCount = 0;

      for (const imageUrl of pageData.images) {
        if (imagesCount === imagesPerRow) {
          xOffset = 7;
          yOffset += 14 + spacingY;
          imagesCount = 0;
        }

        const smallPadding = -2; // Adjust the padding value

        pdf.setDrawColor(0);
        pdf.setLineWidth(0.5);

        drawDashedBorder(pdf, xOffset + smallPadding, yOffset + smallPadding, 36 - 2 * smallPadding, 13 - 2 * smallPadding, 2, 2);

        pdf.addImage(imageUrl, "JPEG", xOffset, yOffset, 36, 13);
        xOffset += 37 + spacingX;
        imagesCount++;
      }

      pdf.addPage();

      // Reuse the same code for drawing text pages
      xOffset = 7;
      yOffset = 14;
      imagesCount = 0;

      const reversedData = reverseArrayInGroups(pageData?.texts, 5);

      for (const text of reversedData) {
        if (imagesCount === imagesPerRow) {
          xOffset = 7;
          yOffset += 14 + spacingY;
          imagesCount = 0;
        }

        const fontSize = 10;
        pdf.setFontSize(fontSize);

        const smallPadding = -2; // Adjust the padding value

        pdf.setDrawColor(0);
        pdf.setLineWidth(0.5);

        drawDashedBorder(pdf, xOffset + smallPadding, yOffset + smallPadding, 36 - 2 * smallPadding, 13 - 2 * smallPadding, 2, 3);

        pdf.text(text, xOffset + 2, yOffset + 7);
        xOffset += 37 + spacingX;
        imagesCount++;
      }

      pdf.addPage();
    }

    // setLoadingExportFile(false);
    // setModalVisibleExport(false);
    pdf.save(`${areaName}_${moment().format("DD-MM-YYYY HH:mm")}_${batch}.pdf`);
  }

  async function handleArraysList(array1: string[], array2: string[]) {
    const batchSize = 640;

    // Check the lengths of the input arrays
    if (array1.length === 0 || array2.length === 0) {
      return;
    }

    setLoadingExportFile(true);
    setModalVisibleExport(true);

    // Divide array1 into batches
    const batches1: string[][] = [];
    for (let i = 0; i < array1.length; i += batchSize) {
      const batch = array1.slice(i, i + batchSize);
      batches1.push(batch);
    }

    // Divide array2 into batches
    const batches2: string[][] = [];
    for (let i = 0; i < array2.length; i += batchSize) {
      const batch = array2.slice(i, i + batchSize);
      batches2.push(batch);
    }
    setTotalFiles(batches1.length);
    // Use a single loop to iterate through both batches1 and batches2
    for (let index = 0; index < Math.max(batches1.length, batches2.length); index++) {
      const batch1 = index < batches1.length ? batches1[index] : [];
      const batch2 = index < batches2.length ? batches2[index] : [];

      setBatchSize(index + 1);
      await handleGeneratePdf(batch1, batch2, index + 1);
    }
    setLoadingExportFile(false);
    setModalVisibleExport(false);
  }

  const handleChangeOptions = (value: number) => {
    setCustomRange(0);
    setError("");
    setSelectedOption(value);
  };

  const disableGenerateBarcodeButton = () => {
    if (error === "" && areaId && customRange > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className={styles["main-container"]}>
      <Card title="Barcodes" className="card--height">
        {/* <h1>Generate barcodes</h1> */}
        <div className={styles["flex-container"]}>
          <Row gutter={16} style={{ display: "flex", justifyContent: "space-between" }}>
            <Col span={8}>
              <div className={styles["filter-container-filter1-select"]}>{renderSelect("Area", areaId, handleChangeAreaList, arealist)}</div>
            </Col>

            <Col span={5} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <div className={`${styles["trecordss"]}`}>
                <h4 style={{ display: "block", marginRight: "50px" }}>Un-used Barcodes: {unUsedQrCode?.length ?? 0}</h4>
              </div>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "20px" }}>
            {/* <Col span={3} style={{ marginRight: "-14px" }}></Col> */}
            <Col span={6.5} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <div>
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  maxTagCount="responsive"
                  dropdownStyle={{ overflowY: "auto" }}
                  optionFilterProp="children"
                  className={`${styles["input-dropdown"]}`}
                  placeholder="Select Option"
                  style={{ minWidth: "80%", width: 150 }}
                  filterOption={(input: any, option: any) => {
                    const children = option?.children as unknown;
                    if (typeof children === "string") {
                      return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                  }}
                  onChange={(newValue: any) => {
                    handleChangeOptions(newValue);
                  }}
                  value={selectedOption}
                >
                  {/* <Select.Option value={0}>Select Option</Select.Option> */}

                  <Select.Option value={1} key={1}>
                    Default No.
                  </Select.Option>

                  <Select.Option value={2} key={2}>
                    Custom No.
                  </Select.Option>
                </Select>
              </div>
              <div style={{ marginLeft: "16px" }}>
                {selectedOption === 1 ? (
                  <>
                    <div className={styles["filter-Lastdays"]}>
                      <div
                        className={`${styles["filter-dashboard-option"]} ${customRange === 25 ? "gx-bg-primary" : ""} `}
                        style={{
                          cursor: "pointer",
                          color: customRange === 25 ? "#fff" : "",
                          opacity: 1
                        }}
                        // className={styles["filter-dashboard-option"]}
                        // style={{
                        //   backgroundColor: customRange === 25 ? "#1860ab" : "",
                        //   borderColor: customRange === 25 ? "#1860ab" : "",
                        //   color: customRange === 25 ? "#fff" : "",
                        //   cursor: "pointer",
                        //   opacity: 1
                        // }}
                        onClick={() => {
                          setCustomRange(25);
                        }}
                      >
                        25
                      </div>
                      <div
                        className={`${styles["filter-dashboard-option"]} ${customRange === 50 ? "gx-bg-primary" : ""} `}
                        style={{
                          cursor: "pointer",
                          color: customRange === 50 ? "#fff" : "",
                          opacity: 1
                        }}
                        // className={styles["filter-dashboard-option"]}
                        // style={{
                        //   backgroundColor: customRange === 50 ? "#1860ab" : "",
                        //   borderColor: customRange === 50 ? "#1860ab" : "",
                        //   color: customRange === 50 ? "#fff" : "",
                        //   cursor: "pointer",
                        //   opacity: 1
                        // }}
                        onClick={() => {
                          setCustomRange(50);
                        }}
                      >
                        50
                      </div>
                      <div
                        className={`${styles["filter-dashboard-option"]} ${customRange === 75 ? "gx-bg-primary" : ""} `}
                        style={{
                          cursor: "pointer",
                          color: customRange === 75 ? "#fff" : "",
                          opacity: 1
                        }}
                        // style={{
                        //   backgroundColor: customRange === 75 ? "#1860ab" : "",
                        //   borderColor: customRange === 75 ? "#1860ab" : "",
                        //   color: customRange === 75 ? "#fff" : "",
                        //   cursor: "pointer",
                        //   opacity: 1
                        // }}
                        // className={styles["filter-dashboard-option"]}
                        onClick={() => {
                          setCustomRange(75);
                        }}
                      >
                        75
                      </div>
                      <div
                        className={`${styles["filter-dashboard-option"]} ${customRange === 100 ? "gx-bg-primary" : ""} `}
                        style={{
                          cursor: "pointer",
                          color: customRange === 100 ? "#fff" : "",
                          opacity: 1
                        }}
                        // style={{
                        //   backgroundColor: customRange === 100 ? "#1860ab" : "",
                        //   borderColor: customRange === 100 ? "#1860ab" : "",
                        //   color: customRange === 100 ? "#fff" : "",
                        //   cursor: "pointer",
                        //   opacity: 1
                        // }}
                        // className={styles["filter-dashboard-option"]}
                        onClick={() => {
                          setCustomRange(100);
                        }}
                      >
                        100
                      </div>
                      <div
                        className={`${styles["filter-dashboard-option"]} ${customRange === 500 ? "gx-bg-primary" : ""} `}
                        style={{
                          cursor: "pointer",
                          color: customRange === 500 ? "#fff" : "",
                          opacity: 1
                        }}
                        onClick={() => {
                          setCustomRange(500);
                        }}
                      >
                        500
                      </div>
                    </div>
                  </>
                ) : (
                  <div style={{ minWidth: "200px" }}>
                    <Input placeholder="Enter No. 1 to 500" type="number" min={1} max={500} name="integerField" onChange={handleInputChange} />
                    {/* {error && (
                      <p className="error" style={{ color: "red", marginTop: "5px" }}>
                        {error}
                      </p>
                    )} */}
                  </div>
                )}
              </div>
            </Col>
            <Col span={8} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "16px" }}>
                <Button type="primary" style={{ display: "block", margin: "0 auto" }} className={`${styles["filter-handleGO"]} `} onClick={() => setHandleSubmit(true)} disabled={disableGenerateBarcodeButton()}>
                  {loading ? "Downloading Barcodes" : "Generate Barcodes"}
                </Button>

                <Button type="primary" style={{ display: "block", margin: "0 auto" }} className={`${styles["filter-handleGO"]} `} onClick={() => handleArraysList(unUsedQrCode, treeTagList)} disabled={!unUsedQrCode || unUsedQrCode.length === 0}>
                  Download Un-used Barcodes
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Card>

      {/* Modal for loader */}
      <Modal
        // title="Generating Barcodes"
        visible={modalVisible}
        // header={null}
        footer={null}
        closable={false}
        centered // Add this centered prop
      >
        <Spin tip="Generating and Downloading Barcodes, Please wait..." spinning={loading}>
          <Space size="middle">
            <Button type="primary" style={{ display: "block", margin: "0 auto" }} onClick={() => setModalVisible(false)}>
              {""}
            </Button>
          </Space>
        </Spin>
      </Modal>

      {/* Modal for loader Export file Unused BarCodes */}
      <Modal
        // title="Generating Barcodes"
        visible={modalVisibleExport}
        // header={null}
        footer={null}
        closable={false}
        centered // Add this centered prop
      >
        <h4>Total Download Files: {totalFiles}</h4>
        <h4>Downloaded Files: {batchSize - 1}</h4>
        <Spin tip="Downloading Un-used Barcodes, Please wait..." spinning={loadingExportFile}>
          <Space size="middle">
            <Button type="primary" style={{ display: "block", margin: "0 auto" }} onClick={() => setModalVisibleExport(false)}>
              {""}
            </Button>
          </Space>
        </Spin>
      </Modal>
    </div>
  );
};

export default GeneateBarcodes;
