// API CALLS RELATED TO USER MODULE
import axios from "axios";
import type { History } from "history";
import { UserFormInterface } from "../shared/interfaces/User.interface";
export const getProfileData = async (TOKEN: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/userprofile`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

//user list
export const getUsersListAPI = async (TOKEN: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getOneUserByIdAPI = async (TOKEN: any, id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${id}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

//comman master get api for Role list
export const getOneMasterRoleList = async (TOKEN: string, RoleId: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/commonMaster/${RoleId}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

//comman master get api for Area list
export const getOneMasterAreaList = async (TOKEN: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/tree/areaList`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

// get api for area list By Organization Id
export const getOneAreaListByOrganizationId = async (TOKEN: string, OranizationId: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organizationMaster/area/${OranizationId}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

// get api for project list By Organization Id
export const getOneProjectListByOrganizationId = async (TOKEN: string, OranizationId: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organizationMaster/project/${OranizationId}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

//Organization get api for organization list
export const getOrganizationListAPI = async (TOKEN: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organization`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

// add new user post api
export const addNewUserDetailsAPI = async (userDetails: UserFormInterface, TOKEN: any) => {
  const { FirstName, LastName, MobileNumber, Password, RoleId, OrganizationId, areaIdList, LocalPartner, projectIdList } = userDetails;

  let userObj = {
    FirstName,
    LastName,
    MobileNumber,
    Password,
    RoleId,
    OrganizationId,
    areaIdList,
    LocalPartner,
    projectIdList
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/user`,

      { ...userObj, FirstName: FirstName, LastName: LastName, MobileNumber: MobileNumber, Password: Password, RoleId: RoleId, OrganizationId: OrganizationId, areaIdList: areaIdList, LocalPartner: LocalPartner, projectIdList: projectIdList },

      {
        headers: {
          authorization: `Bearer ${TOKEN}`
        }
      }
    );
    if (response.status !== 200) throw new Error("Unable to add organization please try again.");

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateUserDetailsAPI = async (UserId: number, userDetails: UserFormInterface, history: History, TOKEN: any) => {
  const { FirstName, LastName, MobileNumber, Password, RoleId, OrganizationId, areaIdList, LocalPartner, projectIdList } = userDetails;
  let userObj = {
    FirstName,
    LastName,
    MobileNumber,
    Password,
    RoleId, //role are in arry formate[1] || [1,2]
    OrganizationId, //organization are in arry formate[1] || [1,2]
    areaIdList,
    LocalPartner,
    projectIdList
  };
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/user/${UserId}`,
      { ...userObj, FirstName: FirstName, LastName: LastName, MobileNumber: MobileNumber, Password: Password, RoleId: RoleId, OrganizationId: OrganizationId, areaIdList: areaIdList, LocalPartner: LocalPartner, projectIdList: projectIdList },
      {
        headers: {
          authorization: `Bearer ${TOKEN}`
        }
      }
    );
    if (response.status !== 200) throw new Error("Unable to update user please try again.");

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const deleteUserAPI = async (TOKEN: string, UserId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/user/${UserId}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error deleting user please try again.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

//Access control
export const getAccessControlAPI = async (TOKEN: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/accessControl`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const refreshTokenAPI = async (refreshToken: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/refresh`,
      {},
      {
        headers: {
          authorization: `Bearer ${refreshToken}`
        }
      }
    );

    if (response.status !== 200) {
      throw new Error("Error generating token from refresh token.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
