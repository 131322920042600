import React, { useEffect, useState } from "react";
import { Card, Table, Button, Tooltip, Input, Spin, Modal, message, Space } from "antd";
import { useHistory } from "react-router-dom";
import type { ColumnsType } from "antd/lib/table";
import type { TableProps } from "antd/lib/table";
import { Link } from "react-router-dom";
import type { SorterResult } from "antd/lib/table/interface";
import { DeleteOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { OrganizationDeleteResponse, OrganizationListInterface } from "../../shared/interfaces/Oranization.interface";
import * as organizationService from "../../services/organizationService";
import TitleCaseFunction from "../../util/TitleCaseFunction";
import { LocalStorageConstants } from "../../constants/localStorageConstants";

const OrganizationTables: React.FC = () => {
  const history = useHistory();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, any>>({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<OrganizationListInterface> | null>(null);
  const [searchedText, setSearchedText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState<number>(0);
  const [userOrganizationData, setUserOrganizationData] = useState<Array<OrganizationListInterface>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  useEffect(() => {
    renderOrganizationList();
  }, [refresh]);

  const renderOrganizationList = async () => {
    setLoading(true);
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
    try {
      const response = await organizationService.getOrganizationListAPI(TOKEN);
      if (response?.organizationList) {
        setLoading(false);
        setUserOrganizationData(response?.organizationList);
      }
    } catch (error: any) {
      setLoading(false);
      // message.error("Unable to load user list");
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  const columns: ColumnsType<OrganizationListInterface> = [
    {
      title: "Organization Name",
      key: "OrganizationName",
      render: (text, record) => (
        <span className={`gx-link ${highlightedRow === record.OrganizationId ? "entity-name-selected" : "entity-name"}`} onClick={() => renderView(text)}>
          <Link to={``}>
            <Tooltip title="View Organization Details">{TitleCaseFunction(text.OrganizationName)}</Tooltip>
          </Link>
        </span>
      ),
      onFilter: (value: any, record) => String(record.OrganizationName).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a.OrganizationName.localeCompare(b.OrganizationName)
      },
      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "OrganizationName", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },
    {
      title: "Abbreviation",
      key: "OrgAbbreviation",
      render: (text, record) => <span>{TitleCaseFunction(text.OrgAbbreviation)}</span>,
      onFilter: (value: any, record) => String(record.OrgAbbreviation).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a?.OrgAbbreviation?.localeCompare(b?.OrgAbbreviation)
      },
      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "OrgAbbreviation", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },
    {
      title: "Email",
      key: "Email",
      render: (text, record) => <span>{TitleCaseFunction(text.Email)}</span>,
      onFilter: (value: any, record) => String(record.Email).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a.Email.localeCompare(b.Email)
      },

      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "Email", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },
    {
      title: "Phone Number",
      key: "PhoneNumber",
      render: (text, record) => <span>{TitleCaseFunction(text.PhoneNumber)}</span>,
      onFilter: (value: any, record) => String(record.PhoneNumber).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a.PhoneNumber.localeCompare(b.PhoneNumber)
      },
      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "PhoneNumber", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },
    {
      title: "City",
      key: "City",
      render: (text, record) => <span>{TitleCaseFunction(text.City)}</span>,
      onFilter: (value: any, record) => String(record.City).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a.City.localeCompare(b.City)
      },
      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "City", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },
    {
      title: "ContactPerson ",
      key: "ContactPerson ",
      render: (text, record) => <span>{TitleCaseFunction(text.ContactPerson)}</span>,
      onFilter: (value: any, record) => String(record.ContactPerson).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a.ContactPerson.localeCompare(b.ContactPerson)
      },
      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "ContactPerson", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },

    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <>
          <span className="gx-link" onClick={() => renderEdit(text)}>
            <i className="icon icon-edit" style={{ fontSize: "18px", marginRight: "25px" }} />
          </span>
          &nbsp;&nbsp;|&nbsp;&nbsp; &nbsp;&nbsp;
          <span
            className="gx-link"
            onClick={() => {
              showDeleteConfirm(text);
            }}
          >
            <DeleteOutlined style={{ fontSize: "20px" }} />
          </span>
        </>
      )
    }
  ];

  const filterDataSource = (data: OrganizationListInterface[]) => {
    const searchText = searchedText.toLowerCase();
    return data.filter((item) => {
      return String(item.OrganizationName).toLowerCase()?.includes(searchText) || String(item.PhoneNumber).toLowerCase()?.includes(searchText) || String(item.Email).toLowerCase()?.includes(searchText) || String(item.City).toLowerCase()?.includes(searchText) || String(item.ContactPerson).toLowerCase()?.includes(searchText);
    });
  };

  const showDeleteConfirm = (deleteUser: OrganizationListInterface) => {
    setDeleteRecordId(deleteUser.OrganizationId);
    setOpenModal(true);
    setHighlightedRow(deleteUser.OrganizationId);
  };

  const handleOk = (id: number | null) => {
    setOpenModal(false);
    deleteOrganizationById(id);
  };

  const renderEdit = (editUser: OrganizationListInterface) => {
    history.push(`/organization/update/${editUser.OrganizationId}`);
  };

  const renderView = (view: OrganizationListInterface) => {
    history.push({
      pathname: `/organization/view/${view.OrganizationId} `
    });
  };

  const addUser = () => {
    history.push(`/organization/add/form`);
  };

  const deleteOrganizationById = async (OrganizationId: number | null) => {
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
    try {
      if (OrganizationId !== null) {
        const response: OrganizationDeleteResponse = await organizationService.deleteOrganizationAPI(TOKEN!, OrganizationId);
        setRefresh(true);
        message.destroy();
        message.success(response.message);
        setRefresh(false);
        setHighlightedRow(null);
      }
    } catch (error: any) {
      setHighlightedRow(null);
      // message.error("Failed to delete user !");
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setHighlightedRow(null);
  };

  const handleChange: TableProps<OrganizationListInterface>["onChange"] = (pagination: any, filters, sorter) => {
    setFilteredInfo(filters);
    if (Array.isArray(sorter)) {
      setSortedInfo(null);
    } else if (sorter && sorter.columnKey) {
      setSortedInfo({ columnKey: sorter.columnKey, order: sorter.order });
    } else {
      setSortedInfo(null);
    }
    setPagination(pagination);
  };

  return (
    <div>
      {loading ? (
        <div className="loading loading--center">
          <Space direction="vertical" className="space--width" size="large">
            <Spin tip="Loading" size="large" />
          </Space>
        </div>
      ) : (
        <Card title="Organization Management" className="card--height">
          <div className={`${styles["div-container"]}`}>
            <div className="card-list-data-wrapper">
              <div className="card-list-data-container">
                <Input.Search
                  placeholder="Search."
                  onSearch={(value) => {
                    setSearchedText(value);
                  }}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (!inputValue.startsWith(" ")) {
                      setSearchedText(e.target.value);
                    }
                  }}
                  value={searchedText}
                  style={{ width: "35%" }}
                />
              </div>
              <Button
                type="primary"
                // className="button-gradiant"
                onClick={() => addUser()}
                style={{
                  fontWeight: "500px",
                  display: "block",
                  margin: "0 auto"
                }}
              >
                Add Organization
              </Button>
            </div>
          </div>
          <Table className="gx-table-responsive" columns={columns} pagination={pagination} dataSource={filterDataSource(userOrganizationData)} onChange={handleChange} bordered />
        </Card>
      )}

      <Modal title="Are you sure you want to delete this Orgnization?" visible={openModal} onOk={() => handleOk(deleteRecordId)} onCancel={handleCancel} okText="Yes" cancelText="No" width="500px" destroyOnClose maskClosable={true} bodyStyle={{ padding: 0 }} wrapClassName="delete-modal"></Modal>
    </div>
  );
};

export default OrganizationTables;
