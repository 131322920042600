import axios from "axios";

export const getDashboardCardDetailsSuperAdmin = async (TOKEN: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
export const getDashboardCardDetailsOrgAdmin = async (TOKEN: string, projectId: number[]) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard?projectIdList=[${projectId}]`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getDashboardBarChartDetailsSuperAdmin = async (TOKEN: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/chartStats`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getDashboardBarChartDetailsOrgAdmin = async (TOKEN: string, projectId: number[]) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/chartStats?projectIdList=[${projectId}]`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
