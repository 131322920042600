import { useEffect, useState } from "react";
import { message, Space, Spin, Select } from "antd";
import styles from "./index.module.css";
import CommonModal from "../Modal";
import { OrgMasterModulesAndMapping, UserRoleDATA } from "../../constants/databaseConstants";
import * as orgmasterServices from "../../services/orgmasterServices";
import * as dashboardServices from "../../services/dashboardServices";
import OrgAdminDashboard from "./OrgAdminDashboard";
import SuperAdminDashboard from "./SuperAdminDashboard";
import FieldExecutiveDashboard from "./FieldExecutiveDashboard";
import { LocalStorageConstants } from "../../constants/localStorageConstants";

const Dashboard = () => {
  // CONSTANTS
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const user = JSON.parse(localStorage.getItem(LocalStorageConstants.FORM) || "{}");

  //STATES
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderRole2, setLoaderRole2] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [projectlist, setProjectlist] = useState<number[]>([]);
  const [dashboardCards, setDashboardCards] = useState<any | null>();
  const [barChartData, setBarChartData] = useState<any | null>();
  const [pieChartData, setPieChartData] = useState<any | null>();
  const [lineChartData, setLineChartData] = useState<any | null>();
  const [isActive, setIsactive] = useState<string>("");
  const [projectListCheck, setProjectListCheck] = useState(false);

  /* project list */
  useEffect(() => {
    const fetchlist = async () => {
      try {
        setProjectlist([]);
        setLoader(true);
        const response = await orgmasterServices.getOneOrgMasterListById(TOKEN!, OrgMasterModulesAndMapping.PROJECT, isActive);
        const res = response.AllRecordsList.map((item: any) => {
          return {
            ...item,
            key: item.ProjectId,
            name: item.ProjectName,
            id: item.ProjectId
          };
        });

        if (res.length > 0) {
          setProjectlist(res);
          if (user?.RoleName === UserRoleDATA.CSR_PARTNER) {
            setProjectId(res[0].ProjectId);
            setProjectListCheck(true);
          }
        } else {
          setProjectlist([]);
          if (user?.RoleName === UserRoleDATA.CSR_PARTNER) {
            setProjectListCheck(false);
            message.destroy();
            message.error("Project is not assigned.Please contact the administrator");
          }
          setLoaderRole2(false);
        }
        setLoader(false);
      } catch (error: any) {
        setLoader(false);
        setProjectlist([]);
        message.destroy();
        //message.error("Error: " + (error?.message || ""));
      }
    };

    if (user && (user?.RoleName === UserRoleDATA.ORGANIZATION_ADMIN || user?.RoleName === UserRoleDATA.CSR_PARTNER)) fetchlist();
  }, []);

  useEffect(() => {
    const fetchDataOrgAdmin = async () => {
      try {
        setLoaderRole2(true);
        setDashboardCards({});
        setBarChartData(null);
        setLineChartData(null);
        setPieChartData(null);

        if (user?.RoleName === UserRoleDATA.CSR_PARTNER && !projectId) {
          setLoaderRole2(false);
          message.destroy();
          message.error("Project is not assigned.Please contact the administrator");
          return;
        }

        const projectIdArray = projectId === null ? [] : Array.isArray(projectId) ? projectId : [projectId];

        const resDashboardCardsAndPiChartPromise = dashboardServices.getDashboardCardDetailsOrgAdmin(TOKEN!, projectIdArray);
        const resBarChartAndLineChartPromise = dashboardServices.getDashboardBarChartDetailsOrgAdmin(TOKEN!, projectIdArray);

        const [resDashboardCardsAndPieChart, resLineChartAndBarChart] = await Promise.allSettled([resDashboardCardsAndPiChartPromise, resBarChartAndLineChartPromise]);

        const dashboardCardsRecords = resDashboardCardsAndPieChart.status === "fulfilled" ? resDashboardCardsAndPieChart.value?.dashboardStats : null;
        const PieChartRecords = resDashboardCardsAndPieChart.status === "fulfilled" ? resDashboardCardsAndPieChart.value?.dashboardStats?.treeStatistics : null;
        const LastSixMonthsTreeRecords = resLineChartAndBarChart.status === "fulfilled" ? resLineChartAndBarChart.value?.TopTreeRecords?.LastSixMonthsTreeRecords : null;
        const topTenTreeRecords = resLineChartAndBarChart.status === "fulfilled" ? resLineChartAndBarChart.value?.TopTreeRecords?.topTenTreeRecords : null;

        if (resDashboardCardsAndPieChart.status !== "fulfilled" || resLineChartAndBarChart.status !== "fulfilled") {
          setIsModalOpen(true);
        }

        const formattedLineChartData = (LastSixMonthsTreeRecords || [])
          .map((item: any) => ({
            name: item?.monthName,
            uv: 0,
            Trees: item?.treeCount,
            amt: 0
          }))
          .reverse();

        const formattedBarChartData =
          topTenTreeRecords?.map((item: any) => ({
            name: item?.TreeName?.TreeGeneralName,
            uv: 0,
            Trees: item?.totalTreeCount,
            amt: 0
          })) || [];

        const formattedPieChartData = [
          { name: "GOOD", value: +PieChartRecords?.goodTreeCount, percentage: +PieChartRecords?.goodTreePercentage },
          { name: "VERY GOOD", value: +PieChartRecords?.veryGoodTreeCount, percentage: +PieChartRecords?.veryGoodTreePercentage },
          { name: "NEED-ATTENTION", value: +PieChartRecords?.needAttentionTreeCount, percentage: +PieChartRecords?.needAttentionTreePercentage },
          { name: "DEAD", value: +PieChartRecords?.deadTreeCount, percentage: +PieChartRecords?.deadTreePercentage }
        ];

        setDashboardCards(dashboardCardsRecords || {});
        setBarChartData(formattedBarChartData);
        setLineChartData(formattedLineChartData);
        setPieChartData(formattedPieChartData);
        setLoaderRole2(false);
      } catch (error) {
        setIsModalOpen(true);
        setLoaderRole2(false);
        setBarChartData(null);
        setLineChartData(null);
        setPieChartData(null);
        setDashboardCards({});
        message.destroy();
        // message.error("Error: " + (error?.message || ""));
      }
    };

    if (user && (user?.RoleName === UserRoleDATA.ORGANIZATION_ADMIN || (user?.RoleName === UserRoleDATA.CSR_PARTNER && projectListCheck))) {
      fetchDataOrgAdmin();
    }
  }, [projectId, projectListCheck]);

  useEffect(() => {
    const fetchDataSuperAdmin = async () => {
      try {
        setLoader(true);
        setDashboardCards({});
        setBarChartData(null);
        setLineChartData(null);
        setPieChartData(null);

        const resDashboardCardsAndPiChartPromise = dashboardServices.getDashboardCardDetailsSuperAdmin(TOKEN!);
        const resBarChartAndLineChartPromise = dashboardServices.getDashboardBarChartDetailsSuperAdmin(TOKEN!);

        const [resDashboardCardsAndPieChart, resLineChartAndBarChart] = await Promise.allSettled([resDashboardCardsAndPiChartPromise, resBarChartAndLineChartPromise]);

        const dashboardCardsRecords = resDashboardCardsAndPieChart.status === "fulfilled" ? resDashboardCardsAndPieChart.value?.dashboardStats : null;
        const PieChartRecords = resDashboardCardsAndPieChart.status === "fulfilled" ? resDashboardCardsAndPieChart.value?.dashboardStats?.treeStatistics : null;
        const LastSixMonthsTreeRecords = resLineChartAndBarChart.status === "fulfilled" ? resLineChartAndBarChart.value?.TopTreeRecords?.LastSixMonthsTreeRecords : null;
        const topTenTreeRecords = resLineChartAndBarChart.status === "fulfilled" ? resLineChartAndBarChart.value?.TopTreeRecords?.topTenTreeRecords : null;

        if (resDashboardCardsAndPieChart.status !== "fulfilled" || resLineChartAndBarChart.status !== "fulfilled") {
          setIsModalOpen(true);
        }

        const formattedLineChartData = (LastSixMonthsTreeRecords || [])
          .map((item: any) => ({
            name: item?.monthName,
            uv: 0,
            Trees: item?.treeCount,
            amt: 0
          }))
          .reverse();

        const formattedBarChartData =
          topTenTreeRecords?.map((item: any) => ({
            name: item?.TreeName?.TreeGeneralName,
            uv: 0,
            Trees: item?.totalTreeCount,
            amt: 0
          })) || [];

        const formattedPieChartData = [
          { name: "GOOD", value: +PieChartRecords?.goodTreeCount, percentage: +PieChartRecords?.goodTreePercentage },
          { name: "VERY GOOD", value: +PieChartRecords?.veryGoodTreeCount, percentage: +PieChartRecords?.veryGoodTreePercentage },
          { name: "NEED-ATTENTION", value: +PieChartRecords?.needAttentionTreeCount, percentage: +PieChartRecords?.needAttentionTreePercentage },
          { name: "DEAD", value: +PieChartRecords?.deadTreeCount, percentage: +PieChartRecords?.deadTreePercentage }
        ];

        setDashboardCards(dashboardCardsRecords || {});
        setPieChartData(formattedPieChartData);
        setBarChartData(formattedBarChartData);
        setLineChartData(formattedLineChartData);

        setLoader(false);
      } catch (error) {
        setIsModalOpen(true);
        setLoader(false);
        setBarChartData(null);
        setLineChartData(null);
        setDashboardCards({});
        setPieChartData(null);
        message.destroy();
        // message.error("Error: " + (error?.message || ""));
      }
    };
    if (user && user?.RoleName === UserRoleDATA.SUPER_ADMIN) fetchDataSuperAdmin();
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const renderSelect = (key: string, value: any, handleChange: any, options: any) => {
    return (
      <Select
        getPopupContainer={(trigger) => trigger.parentElement}
        showSearch
        maxTagCount="responsive"
        dropdownStyle={{ overflowY: "auto" }}
        optionFilterProp="children"
        // disabled={options.length === 0}
        className={`${styles["input-dropdown"]}`}
        placeholder={`Select ${key}`}
        style={{ minWidth: "100%", width: 200 }}
        filterOption={(input: any, option: any) => {
          const children = option?.children as unknown;
          if (typeof children === "string") {
            return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        onChange={(newValue: any) => {
          handleChange(newValue);
        }}
        value={value}
        allowClear
      >
        {user?.RoleName === UserRoleDATA.ORGANIZATION_ADMIN && <Select.Option value={null}>{`Select ${key}`}</Select.Option>}

        {/* Render other options */}
        {options?.map((option: any) => (
          <Select.Option value={option.id} key={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const handleChangeProject = (value: number) => {
    setProjectId(value);
  };

  const dashboardProps: any = {
    dashboardCards,
    pieChartData,
    lineChartData,
    barChartData
  };

  return (
    <div className={styles["dashboard-container"]}>
      {/* <CommonModal resetModal={handleOk} showModal={isModalOpen}
       modalMessage={"No data found for this selection."} title="Message" /> */}

      {/* card section */}

      {user && (user?.RoleName === UserRoleDATA.ORGANIZATION_ADMIN || user?.RoleName === UserRoleDATA.CSR_PARTNER) ? (
        <>
          <div className={styles["dashboard-title"]}>Dashboard</div>
          <div className={styles["filter-container-filter1"]}>
            <div className={styles["filter-container-filter1-container"]}>
              <div className={styles["filter-container-filter1-select"]}>{renderSelect("Project", projectId, handleChangeProject, projectlist)}</div>
            </div>
          </div>
          {loaderRole2 ? (
            <div className="loading loading--center">
              <Space direction="vertical" className="space--width" size="large">
                <Spin tip="Loading" size="large" />
              </Space>
            </div>
          ) : (
            <OrgAdminDashboard {...dashboardProps} />
          )}
        </>
      ) : user && user?.RoleName === UserRoleDATA.SUPER_ADMIN ? (
        <>
          <div className={styles["dashboard-title"]}>Dashboard</div>
          {loader ? (
            <div className="loading loading--center">
              <Space direction="vertical" className="space--width" size="large">
                <Spin tip="Loading" size="large" />
              </Space>
            </div>
          ) : (
            <SuperAdminDashboard {...dashboardProps} />
          )}
        </>
      ) : (
        <FieldExecutiveDashboard />
      )}
    </div>
  );
};

export default Dashboard;
