import { useEffect, useState } from "react";
import { Card, Spin, Space, Timeline, message } from "antd";
import { getDeploymentLogListAPI } from "../../services/aboutServices";
import { DeploymentLog } from "../../shared/interfaces/About.interface";
import { LocalStorageConstants } from "../../constants/localStorageConstants";

const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const ChangeLogs = () => {
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const [deploymentLogList, setDeploymentLogList] = useState<DeploymentLog[]>([]);
  const [loader, setLoader] = useState(true); // Initial value to show the loader

  useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await getDeploymentLogListAPI(TOKEN!);
        setDeploymentLogList(response?.deploymentLogList);
        setLoader(false); // After data fetching, hide the loader
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
        setLoader(false); // In case of error, hide the loader as well
      }
    };

    fetchList();
  }, []);

  return (
    <Card className="card--height" title="CHANGELOG">
      {loader ? (
        <div className="loading loading--center">
          <Space direction="vertical" className="space--width" size="large">
            <Spin tip="Loading" size="large" />
          </Space>
        </div>
      ) : (
        <Timeline>
          {deploymentLogList.reverse().map((log) => (
            <Timeline.Item key={log.Id}>
              <h4 className="gx-text-truncate">
                V-{log.MajorVersion ?? 0}.{log.MinorVersion ?? 0}.{log.PatchVersion ?? 0}
              </h4>
              <p className="gx-fs-sm">{formatDate(log.ReleaseDate ?? "00-00-0000")}</p>
              <ul>
                <li className="gx-fs-sm gx-text-grey">
                  {/* Include your description here */}
                  {log.ReleaseLog ? log.ReleaseLog : "No release log available."}
                </li>
              </ul>
            </Timeline.Item>
          ))}
        </Timeline>
      )}
    </Card>
  );
};

export default ChangeLogs;
