import React, { useState, useEffect } from "react";
import { Select } from "antd";

const { Option } = Select;

// Define the props for the Dropdown component
interface DropdownProps {
  onSelectOption: (selectedOption: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ onSelectOption }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>("1");
  const [months, setMonths] = useState<{ value: string; label: string }[]>([]);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    onSelectOption(value);
  };

  const getMonthName = (monthNumber: number): string => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames[monthNumber - 1];
  };

  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const previousYear = currentYear - 1;

    const monthOptions = [
      { value: "1", label: "Last N Days" },
      { value: "2", label: "Custom Range" }
    ];

    for (let i = 0; i < 12; i++) {
      if (currentMonth - i > 0) {
        monthOptions.push({
          value: `${getMonthName(currentMonth - i)} - ${currentYear}`,
          label: `${getMonthName(currentMonth - i)} - ${currentYear}`
        });
      } else {
        monthOptions.push({
          value: `${getMonthName(currentMonth - i + 12)} - ${previousYear}`,
          label: `${getMonthName(currentMonth - i + 12)} - ${previousYear}`
        });
      }
    }

    setMonths(monthOptions);
  }, []);

  return (
    <div style={{ flexBasis: "100%" }}>
      <Select getPopupContainer={(trigger) => trigger.parentElement} style={{ minWidth: "100%" }} value={selectedOption} onChange={handleOptionChange}>
        {months.map((month) => (
          <Option key={month.value} value={month.value}>
            {month.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default Dropdown;
