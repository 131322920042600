import { useState } from "react";
import { Avatar, Popover, message } from "antd";
import { useHistory } from "react-router";
import styles from "./UserProfile.module.css";
import axios from "axios";
import { LocalStorageConstants } from "../../constants/localStorageConstants";

// Define the interface for the response data (if needed)
interface SignOutResponse {
  message: string;
  // Add other properties if they exist in your actual response data
}

const UserProfile = () => {
  // CONSTANTS
  const history = useHistory();
  const user: any = JSON.parse(localStorage.getItem(LocalStorageConstants.USER) || "{}");

  // STATE
  const [open, setOpen] = useState<boolean>(false);

  // HANDLERS

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleAbout = () => {
    hide();
    history.push("/about");
  };

  const handleLogOut = async () => {
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);

    /*
    - destroy any active messages.
    - on logout clear any stored items in localstorage and redirect the user to sign in page.
    */

    const endpoint = `${process.env.REACT_APP_API_URL}/user/signout`;
    try {
      // Use Axios to make the POST request for sign-out
      const response = await axios.post<SignOutResponse>(endpoint, null, {
        headers: {
          Authorization: `Bearer ${TOKEN}`
        }
      });

      // Handle the successful response here
      // Perform any additional actions after successful sign-out
      // For example, you might want to clear the user's local session or redirect them to the login page.
      message.destroy();
      localStorage.clear();
      message.destroy();
      message.success(response.data.message);
      history.push("/");
    } catch (error: any) {
      // Handle errors here
      message.destroy();
      message.error("Error: " + (error?.response?.data?.message || ""));
    }
  };

  const handleUserInfo = () => {
    history.push(`/user_profile/`);
  };

  const changePassword = () => {
    history.push(`/changepassword/`);
  };
  const hide = () => {
    setOpen(false);
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          hide();
          handleUserInfo();
        }}
      >
        My Account
      </li>
      <li
        onClick={() => {
          hide();
          changePassword();
        }}
      >
        Change Password
      </li>
      <li onClick={handleAbout}>About</li>
      <li onClick={handleLogOut}>Logout</li>
    </ul>
  );

  return (
    <div className={`gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row ${styles["cursor"]}`}>
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click" open={open} onOpenChange={handleOpenChange}>
        <Avatar src={"https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000"} className="gx-size-40 gx-pointer gx-mr-3" alt="" />
        <span className={`gx-avatar-name ${styles["username"]}`}>
          {user?.FirstName} {user?.LastName}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
