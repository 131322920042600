import { Route, Switch, Redirect } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import ChangePassword from "../components/UserAccount/index";
import UserProfile from "../pages/MyInfo/index";
import MasterTables from "../components/Master_Management/index";
import UserList from "../components/Users/index";
import UserForm from "../components/Users/UserForm";
import OrganizationTables from "../components/Organization_Management/index";
import OrganizationForm from "../components/Organization_Management/OrganizationForm";
import OrganizationMasterTables from "../components/Organization_Master/index";
import TreeDetails from "../components/Tree/index";
import Timeline from "../components/Timeline";
import Dashboard from "../components/Dashboard";
import Report from "../components/Reports/index";
import ReplantationReport from "../components/Reports/Replantation/index";
import TreeGeoLoactionReport from "../components/Reports/TreeGeoLocation";
import MissingTreeTagReport from "../components/Reports/MissingTreeTag";
import About from "../components/About/index";
import GeneateBarcodes from "../components/Barcodes";

import ComingSoonPage from "../pages/ComingSoon";
import Error404Page from "../pages/Error404Page";
import Forbidden403Page from "../pages/Forbidden403Page";
import { AccessModules, allowedRoles } from "../constants/databaseConstants";
import { LocalStorageConstants } from "../constants/localStorageConstants";
import Log from "../components/Log";
import TreeLocations from "../components/TreeLocations";

// PAGE IMPORTS

const App = () => {
  const match = useRouteMatch();
  const isAuthenticated = !!localStorage.getItem(LocalStorageConstants.TOKEN);
  const form = JSON.parse(localStorage.getItem(LocalStorageConstants.FORM) || "{}");
  const modules = form?.Forms?.map((form: any) => form?.FormName);
  const User = form?.RoleName;

  return isAuthenticated ? (
    <div className="gx-main-content-wrapper">
      <Switch>
        {allowedRoles.includes(User) ? <Route exact path={`${match.path}home`} component={Dashboard} /> : <Route exact path={`${match.path}home`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) ? <Route exact path={`${match.path}changepassword`} component={ChangePassword} /> : <Route exact path={`${match.path}changepassword`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) ? <Route exact path={`${match.path}user_profile`} component={UserProfile} /> : <Route exact path={`${match.path}user_profile`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) && modules.includes(AccessModules.COMMON_MASTER) ? <Route exact path={`${match.path}master_management`} component={MasterTables} /> : <Route exact path={`${match.path}master_management`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) && modules.includes(AccessModules.USER) ? <Route exact path={`${match.path}users`} component={UserList} /> : <Route exact path={`${match.path}users`} component={Forbidden403Page} />}
        {allowedRoles.includes(User) && modules.includes(AccessModules.USER) ? <Route exact path={`${match.path}user/add/form`} component={UserForm} /> : <Route exact path={`${match.path}user/add/form`} component={Forbidden403Page} />}
        {allowedRoles.includes(User) && modules.includes(AccessModules.USER) ? <Route exact path={`${match.path}user/update/:id`} component={UserForm} /> : <Route exact path={`${match.path}user/update/:id`} component={Forbidden403Page} />}
        {allowedRoles.includes(User) && modules.includes(AccessModules.USER) ? <Route exact path={`${match.path}user/:view/:id`} component={UserForm} /> : <Route exact path={`${match.path}user/:view/:id`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) && modules.includes(AccessModules.ORG_MANAGEMENT) ? <Route exact path={`${match.path}organization_management`} component={OrganizationTables} /> : <Route exact path={`${match.path}organization_management`} component={Forbidden403Page} />}
        {allowedRoles.includes(User) && modules.includes(AccessModules.ORG_MANAGEMENT) ? <Route exact path={`${match.path}organization/add/form`} component={OrganizationForm} /> : <Route exact path={`${match.path}organization/add/form`} component={Forbidden403Page} />}
        {allowedRoles.includes(User) && modules.includes(AccessModules.ORG_MANAGEMENT) ? <Route exact path={`${match.path}organization/update/:id`} component={OrganizationForm} /> : <Route exact path={`${match.path}organization/update/:id`} component={Forbidden403Page} />}
        {allowedRoles.includes(User) && modules.includes(AccessModules.ORG_MANAGEMENT) ? <Route exact path={`${match.path}organization/:view/:id`} component={OrganizationForm} /> : <Route exact path={`${match.path}organization/:view/:id`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) && modules.includes(AccessModules.ORG_MASTER_MANAGEMENT) ? <Route exact path={`${match.path}organization_master_management`} component={OrganizationMasterTables} /> : <Route exact path={`${match.path}organization_master_management`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) && modules.includes(AccessModules.TREE) ? <Route exact path={`${match.path}tree`} component={TreeDetails} /> : <Route exact path={`${match.path}tree`} component={Forbidden403Page} />}
        {allowedRoles.includes(User) && modules.includes(AccessModules.TREE) ? <Route exact path={`${match.path}tree-locations`} component={TreeLocations} /> : <Route exact path={`${match.path}tree`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) && modules.includes(AccessModules.TIMELINE) ? <Route exact path={`${match.path}timeline/:id`} component={Timeline} /> : <Route exact path={`${match.path}timeline/:id`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) && modules.includes(AccessModules.REPORT) ? <Route exact path={`${match.path}report`} component={Report} /> : <Route exact path={`${match.path}report`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) && modules.includes(AccessModules.REPORT) ? <Route exact path={`${match.path}report/replantation`} component={ReplantationReport} /> : <Route exact path={`${match.path}report/replantation`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) && modules.includes(AccessModules.REPORT) ? <Route exact path={`${match.path}report/missing_tag`} component={MissingTreeTagReport} /> : <Route exact path={`${match.path}report/missing_tag`} component={Forbidden403Page} />}
        {allowedRoles.includes(User) && modules.includes(AccessModules.TREE) ? <Route exact path={`${match.path}report/geo_report`} component={TreeGeoLoactionReport} /> : <Route exact path={`${match.path}report/geo_report`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) ? <Route exact path={`${match.path}about`} component={About} /> : <Route exact path={`${match.path}about`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) && modules.includes(AccessModules.ERROR_LOG) ? <Route exact path={`${match.path}log`} component={Log} /> : <Route exact path={`${match.path}log`} component={Forbidden403Page} />}

        {allowedRoles.includes(User) ? <Route exact path={`${match.path}generate_barcodes`} component={GeneateBarcodes} /> : <Route exact path={`${match.path}generate_barcodes`} component={Forbidden403Page} />}

        {/* Coming Soon */}
        <Route exact path={`${match.path}coming-soon`} component={ComingSoonPage} />

        {/* Page not found */}
        <Route exact path={`*`} component={Error404Page} />
      </Switch>
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default App;
