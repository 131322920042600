import React, { useEffect, useState } from "react";
import { Card, message, Button, Space, Spin, Row, Col } from "antd";
import * as treeServices from "../../services/treeServices";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import TreeDetails from "./treeDetails";
import styles from "./index.module.css";
import Map from "./treeLocation";
import { useHistory } from "react-router-dom";

const TreeLocations: React.FC = () => {
  const filtersDataFromLocalStorage = localStorage.getItem("TreeDetailsFilters");
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const history = useHistory();
  const [treeLocationList, setTreeLocationList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTree, setSelectedTree] = useState(null);
  const [selectedTreeLocation, setSelectedTreeLocation] = useState<Location | null>(null);
  const [selectedTreeIndex, setSelectedTreeIndex] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (filtersDataFromLocalStorage && TOKEN) {
        const filters = JSON.parse(filtersDataFromLocalStorage);
        const projectId = filters?.projectId === null ? [] : Array.isArray(filters?.projectId) ? filters?.projectId : [filters?.projectId];
        const clusterId = filters?.clusterId === null ? [] : Array.isArray(filters?.clusterId) ? filters?.clusterId : [filters?.clusterId];
        const villageId = filters?.villageId === null ? [] : Array.isArray(filters?.villageId) ? filters?.villageId : [filters?.villageId];
        const farmerId = filters?.farmerId === null ? [] : Array.isArray(filters?.farmerId) ? filters?.farmerId : [filters?.farmerId];
        const areaId = filters?.areaId ? filters?.areaId : null;
        const healthConditionId = filters?.healthConditionId ? filters?.healthConditionId : null;
        const totalCount = filters?.totalCount ? filters?.totalCount : null;
        const searchText = filters?.searchText ? filters?.searchText : "";

        try {
          const response = await treeServices.getTreeListFilterAPI(TOKEN, 1, totalCount, projectId, clusterId, villageId, farmerId, areaId, healthConditionId, searchText);
          const formattedResponse = response?.treeList?.map((item: any) => ({
            latitude: +item?.Latitude,
            longitude: +item?.Longitude,
            name: item?.TreeBotanicalName?.TreeGeneralName,
            treeTag: item?.TreeTag,
            TreeHealthCondition: item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth,
            AreaName: item?.Area?.AreaName,
            ImageUrl: item?.TreeTransaction[0]?.DocumentPath[0]
          }));
          setTreeLocationList(formattedResponse);
          setLoading(false);
        } catch (error: any) {
          setLoading(false);
          message.destroy();
          message.error(error?.message);
        }
      }
    };

    fetchData();
  }, [filtersDataFromLocalStorage]);

  const handleMarkerClick = (tree: any, index: number) => {
    setSelectedTree(tree);
    // Update the selected tree location based on your clustering logic
    // const clusterRadius = 0.1;
    // const treeInClusters = findNearestTreeInClusters(tree, treeLocationList, clusterRadius);
    //setSelectedTreeLocation(treeInClusters);
    setSelectedTreeLocation(tree);
    setSelectedTreeIndex(index);
  };

  const handleForwardClick = () => {
    if (selectedTreeIndex !== null && selectedTreeIndex < treeLocationList.length - 1) {
      const nextIndex = selectedTreeIndex + 1;
      const nextTree = treeLocationList[nextIndex];
      setSelectedTree(nextTree);
      setSelectedTreeLocation(nextTree); // This line needs to be updated
      setSelectedTreeIndex(nextIndex);
    }
  };

  const handleBackwardClick = () => {
    if (selectedTreeIndex !== null && selectedTreeIndex > 0) {
      const prevIndex = selectedTreeIndex - 1;
      const prevTree = treeLocationList[prevIndex];
      setSelectedTree(prevTree);
      setSelectedTreeLocation(prevTree); // This line needs to be updated
      setSelectedTreeIndex(prevIndex);
    }
  };
  const handleCloseTreeDetails = () => {
    setSelectedTree(null);
    setSelectedTreeIndex(null);
  };

  return (
    <>
      <Card
        headStyle={{ height: "auto" }}
        title="Tree Locations"
        extra={
          <Button type="primary" style={{ display: "block", margin: "0px" }} onClick={() => history.goBack()}>
            Back
          </Button>
        }
        style={{ minHeight: "76vh" }}
      >
        {loading === true ? (
          <div className="loading loading--center">
            <Space direction="vertical" className="space--width" size="large">
              <Spin tip="Loading" size="large"></Spin>
            </Space>
          </div>
        ) : (
          <div style={{ width: "100%", minHeight: "60vh" }}>
            <Row gutter={24}>
              {!selectedTree ? (
                <Col span={24}>
                  <div className={styles["map-container"]}>
                    <Map locations={treeLocationList} onMarkerClick={handleMarkerClick} selectedTree={selectedTree} selectedTreeLocation={selectedTreeLocation} selectedTreeIndex={selectedTreeIndex} />
                  </div>
                </Col>
              ) : (
                <>
                  <Col sm={24} md={24} lg={12} xl={8} xxl={8}>
                    <TreeDetails tree={selectedTree} loading={loading} onClose={handleCloseTreeDetails} onForwardClick={handleForwardClick} onBackwardClick={handleBackwardClick} />
                  </Col>
                  <Col sm={24} md={24} lg={12} xl={16} xxl={16}>
                    <div className={styles["map-container2"]} style={{ borderBottom: "1px solid rgba(73, 71, 71, 0.208)" }}>
                      <Map locations={treeLocationList} onMarkerClick={handleMarkerClick} selectedTree={selectedTree} selectedTreeLocation={selectedTreeLocation} selectedTreeIndex={selectedTreeIndex} />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </div>
        )}
      </Card>
    </>
  );
};

export default TreeLocations;
