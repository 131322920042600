// API CALLS RELATED TO USER MODULE
import axios from "axios";
import type { History } from "history";
import { OrganizationFormInterface } from "../shared/interfaces/Oranization.interface";
export const getOrganizationListAPI = async (TOKEN: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organization`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getOneOrganizationByIdAPI = async (TOKEN: any, id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organization/${id}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addNewOrganizationDetailsAPI = async (organizationDetails: OrganizationFormInterface, history: History, TOKEN: any) => {
  const { OrganizationName, OrgAbbreviation, OrganizationDescription, Address1, Address2, City, PostalCode, Website, Email, ContactPerson, ContactPersonPhoneNumber, PhoneNumber, CountryId, StateId } = organizationDetails;
  let userObj = {
    OrganizationName,
    OrganizationDescription,
    Address1,
    Address2,
    City,
    PostalCode,
    Website,
    Email,
    ContactPerson,
    ContactPersonPhoneNumber,
    PhoneNumber,
    CountryId,
    StateId,
    OrgAbbreviation
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/organization`,

      { ...userObj, OrganizationName: OrganizationName, OrgAbbreviation: OrgAbbreviation, OrganizationDescription: OrganizationDescription, Address1: Address1, Address2: Address2, City: City, PostalCode: String(PostalCode), Website: Website, Email: Email, ContactPerson: ContactPerson, ContactPersonPhoneNumber: ContactPersonPhoneNumber, PhoneNumber: PhoneNumber, CountryId: String(CountryId), StateId: String(StateId) },
      {
        headers: {
          authorization: `Bearer ${TOKEN}`
        }
      }
    );
    if (response.status !== 200) throw new Error("Unable to add organization please try again.");

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

//update api
export const updateOrganizationDetailsAPI = async (OrganizationId: number, userDetails: OrganizationFormInterface, history: History, TOKEN: any) => {
  const { OrganizationName, OrganizationDescription, Address1, Address2, City, PostalCode, Website, Email, ContactPerson, ContactPersonPhoneNumber, PhoneNumber, CountryId, StateId, OrgAbbreviation } = userDetails;
  let userObj = {
    OrganizationName,
    OrganizationDescription,
    Address1,
    Address2,
    City,
    PostalCode,
    Website,
    Email,
    ContactPerson,
    ContactPersonPhoneNumber,
    PhoneNumber,
    CountryId,
    StateId,
    OrgAbbreviation
  };
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/organization/${OrganizationId}`,
      { ...userObj, OrganizationName: OrganizationName, OrgAbbreviation: OrgAbbreviation, OrganizationDescription: OrganizationDescription, Address1: Address1, Address2: Address2, City: City, PostalCode: String(PostalCode), Website, Email: Email, ContactPerson: ContactPerson, ContactPersonPhoneNumber: ContactPersonPhoneNumber, PhoneNumber: PhoneNumber, CountryId: String(CountryId), StateId: String(StateId) },
      {
        headers: {
          authorization: `Bearer ${TOKEN}`
        }
      }
    );
    if (response.status !== 200) throw new Error("Unable to update user please try again.");

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

//comman master get api for country list
export const getOneMasterCountryList = async (TOKEN: string, RoleId: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/commonMaster/${RoleId}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
// get api for State list By Country Id
export const getOneStateListByCountryId = async (TOKEN: string, CountryId: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/commonMaster/state/${CountryId}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const deleteOrganizationAPI = async (TOKEN: string, OrganizationId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/organization/${OrganizationId}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error deleting user please try again.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
