export const formatRoleName = (text: any) => {
  if (text === "fieldexecutive") {
    return "FIELD-EXECUTIVE";
  } else if (text === "organizationadmin") {
    return "ORGANIZATION-ADMIN";
  } else if (text === "superadmin") {
    return "SUPERADMIN";
  } else if (text === "csrpartner") {
    return "CSR-PARTNER";
  } else {
    return text; // Return as is if no match
  }
};
