import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Space, Spin, Card, message, Button, Select } from "antd";
import { useHistory } from "react-router-dom";
import * as userServices from "../../services/userServices";
import { ProfileDataInterface, ProfileListDataInterface } from "../../shared/interfaces/User.interface";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import { formatRoleName } from "../../util/formatRoleName";

const MyInfo: React.FC = () => {
  const userToken: any = JSON.parse(localStorage.getItem(LocalStorageConstants.FORM) || "{}");
  const [form] = Form.useForm();
  const history = useHistory();
  /* loading state */
  const [formLoader, setFormLoader] = useState<boolean>(false);
  const { Option } = Select;
  const [inputData, setInputData] = useState<ProfileDataInterface>({
    MobileNumber: "",
    FirstName: "",
    LastName: "",
    Role: [],
    AreaList: [],
    ProjectList: []
  });

  const [profileData, setProfileData] = useState<ProfileListDataInterface>();

  const onFinish = () => {};

  useEffect(() => {
    renderProfileData();
  }, []);

  useEffect(() => {
    if (profileData) {
      setInputData({
        MobileNumber: profileData?.MobileNumber,
        FirstName: profileData?.FirstName,
        LastName: profileData?.LastName,
        Role: formatRoleName(profileData?.Role[0]?.RoleName),
        AreaList: profileData?.AreaList.map((area) => area?.AreaName),
        ProjectList: profileData?.ProjectList.map((project: any) => project?.ProjectName)
      });
      form.setFieldsValue({
        MobileNumber: profileData.MobileNumber,
        FirstName: profileData.FirstName,
        LastName: profileData.LastName,
        Role: formatRoleName(profileData.Role?.[0]?.RoleName),
        selectedAreas: profileData?.AreaList.map((area) => area?.AreaName),
        selectedProjects: profileData?.ProjectList.map((project: any) => project?.ProjectName) // Set selectedAreas field
      });
    }
  }, [profileData, profileData?.MobileNumber, profileData?.FirstName, profileData?.LastName, form]);

  const renderProfileData = async () => {
    setFormLoader(true);
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
    try {
      const response: any = await userServices.getProfileData(TOKEN);
      if (response) {
        setFormLoader(false);
        setProfileData(response.user);
        // message.destroy();
        // message.success(response.message);
      }
    } catch (error: any) {
      setFormLoader(false);
      // message.error("Unable to fetch user data");
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  return (
    <div>
      {formLoader ? (
        <div className="loading loading--center">
          <Space direction="vertical" className="space--width" size="large">
            <Spin tip="Loading" size="large" />
          </Space>
        </div>
      ) : (
        <Card title="Profile">
          <Form disabled style={{ marginLeft: "20px" }} colon={false} form={form} className="ant-advanced-search-form" onFinish={onFinish}>
            <Row>
              <Col lg={6} md={6} sm={12} xs={24}>
                <div className="gx-form-row0">
                  <Form.Item style={{ marginLeft: "10px" }} label={`First Name`} name="FirstName">
                    <Input readOnly placeholder="Please input your first name." name="FirstName" />
                  </Form.Item>
                </div>
              </Col>

              <Col lg={6} md={6} sm={12} xs={24} style={{ marginLeft: "17px" }}>
                <div className="gx-form-row0">
                  <Form.Item label={`Last Name`} name="LastName">
                    <Input readOnly placeholder="Please input your last name." name="LastName" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={24} style={{ paddingRight: "0px" }}>
                <div className="gx-form-row0">
                  <Form.Item style={{ marginLeft: "10px" }} name="MobileNumber" label={`Mobile No.`}>
                    <Input readOnly placeholder="Please input your Mobile no ." />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={24} style={{ paddingRight: "0px" }}>
                <div className="gx-form-row0">
                  <Form.Item style={{ marginLeft: "50px" }} name="Role" label={`Role`}>
                    <Input readOnly placeholder="Please input your role" value={profileData?.Role[0]?.RoleName} name="Role" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            {userToken?.RoleId === 3 ? (
              <Row>
                <Col lg={12} md={12} sm={12} xs={24} style={{ paddingRight: "0px" }}>
                  <div className="gx-form-row0">
                    <Form.Item style={{ marginLeft: "29px" }} label="Areas" name="selectedAreas">
                      <Select mode="multiple" placeholder="Areas">
                        {profileData?.AreaList.map((area: any) => (
                          <Option key={area?.AreaId} value={area?.AreaName}>
                            {area?.AreaName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
            {userToken?.RoleId === 4 ? (
              <Row>
                <Col lg={12} md={12} sm={12} xs={24} style={{ paddingRight: "0px" }}>
                  <div className="gx-form-row0">
                    <Form.Item style={{ marginLeft: "29px" }} label="Projects" name="selectedProjects">
                      <Select mode="multiple" placeholder="Projects">
                        {profileData?.ProjectList.map((project: any) => (
                          <Option key={project?.ProjectId} value={project?.ProjectName}>
                            {project?.ProjectName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Form.Item className="form-button form-button-pr ">
              <Button type="primary" style={{ position: "absolute", right: "20%" }} disabled={false} className={`button-gradiant`} onClick={() => history.push(`/home`)}>
                Back
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </div>
  );
};

export default MyInfo;
