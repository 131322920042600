import moment from "moment-timezone";
import { LocalStorageConstants } from "../constants/localStorageConstants";

const addTokenAndExpiryTimeToLocalStorage = (token: string) => {
  try {
    const additionalMinutes = process.env.REACT_APP_TOKEN_EXPIRY_ADDITIONAL_MINUTES ? +process.env.REACT_APP_TOKEN_EXPIRY_ADDITIONAL_MINUTES : 55;
    localStorage.setItem(LocalStorageConstants.TOKEN, token);
    localStorage.setItem(LocalStorageConstants.TOKEN_EXPIRY_TIMESTAMP, JSON.stringify(moment().add(additionalMinutes, "minutes").format()));
  } catch (error) {
    throw error;
  }
};

export default addTokenAndExpiryTimeToLocalStorage;
