import React, { useState, useEffect, useRef } from "react";
import { Table, Select, Button, Input, Space, Card, message, Spin, Modal } from "antd";
import { DeleteOutlined, SaveOutlined, CloseCircleFilled } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { ModuleAndIdMapping } from "../../constants/databaseConstants";
import styles from "./index.module.css";
import { Response, DataItem } from "../../shared/interfaces/MasterManagement.interfaces";
import * as masterServices from "../../services/masterServices";
import { TablePaginationConfig } from "antd/lib/table/interface";
import { Link } from "react-router-dom";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import { formatRoleName } from "../../util/formatRoleName";

const MasterTables: React.FC = () => {
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  //STATES
  /* when a module is selected this state stores list of records for that module. */
  const [selectedList, setSelectedList] = useState<DataItem[]>([]);
  /* selected module Id */
  const [selectedId, setSelectedId] = useState<number | null>(ModuleAndIdMapping.USERROLE);
  /* Id of the item to be edited */
  const [editingItemId, setEditingItemId] = useState<number | null>(null);
  /* states for refresh when a module is refreshed */
  const [refresh, setRefresh] = useState<boolean>(true);
  const [refreshCountry, setRefreshCountry] = useState<boolean>(false);
  /* for addView false is active and true is inactive */
  const [addView, setAddView] = useState<boolean>(false);
  /* to check if the input on add or edit is correct. */
  const [validateInput, setValidateInput] = useState<boolean>(true);
  /* when user adds a record or edits the details are stored in this state. */
  const [newItemData, setNewItemData] = useState<DataItem>();
  /* Icon list fetch refresh */
  const [refreshIconList, setRefreshIconList] = useState<boolean>(true);
  const [changeValue, setChangeValue] = useState<boolean>(false);
  /* when item to be deleted the  first columns name is higlighted */
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);
  /* state for pagination */
  const pageSizeOptions = ["10", "20", "50", "100"];
  const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 10 });
  /* searched text state */
  const [searchedText, setSearchedText] = useState<string>("");
  /* loading state */
  const [formLoader, setFormLoader] = useState<boolean>(false);
  /* Id of the item to be deleted */
  const [deleteRecordId, setDeleteRecordId] = useState<number | null>(null);
  /* when item to be deleted this state is set to true */
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<DataItem[]>([]);

  /* Fetching all Master Tables list when visited using select */
  useEffect(() => {
    const renderMasterTablesList = async () => {
      try {
        setFormLoader(true);
        setSelectedList([]);
        const response: Response = await masterServices.getOneMasterListById(TOKEN!, selectedId);

        const res = response.AllRecordsList.map((item: any) => {
          /* the internal airport ids are not displayed on the UI 
            (airport and terminal) but required for apis
            in update, delete.
          */
          if (selectedId === ModuleAndIdMapping.USERROLE) {
            return {
              ...item,
              RoleName: item.RoleName,
              Description: item.Description,
              id: item.RoleId
            };
          } else if (selectedId === ModuleAndIdMapping.COUNTRY) {
            return {
              ...item,
              key: item.CountryId,
              CountryName: item.CountryName,
              id: item.CountryId
            };
          } else if (selectedId === ModuleAndIdMapping.STATE) {
            return {
              ...item,
              key: item.StateId,
              StateName: item.StateName,
              CountryName: item.country.CountryName,
              CountryId: item.country.CountryId,
              id: item.StateId
            };
          } else if (selectedId === ModuleAndIdMapping.TREEBOTANICAL) {
            return {
              ...item,
              key: item.TreeBotanicalId,
              TreeGeneralName: item.TreeGeneralName,
              BotanicalName: item.BotanicalName,
              id: item.TreeBotanicalId
            };
          } else if (selectedId === ModuleAndIdMapping.TREEHEALTH) {
            return {
              ...item,
              key: item.TreeHealthId,
              TreeHealth: item.TreeHealth,
              Description: item.Description,
              id: item.TreeHealthId
            };
          } else {
            return {
              ...item,
              key: item.id
            };
          }
        });
        setFormLoader(false);
        setSelectedList(res);
        setRefresh(false);
      } catch (error: any) {
        setFormLoader(false);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (refresh) await renderMasterTablesList();
    };
    fetchList();
  }, [refresh, refreshIconList, selectedId]);

  /* Project list */
  useEffect(() => {
    const renderCountryList = async () => {
      try {
        setCountryList([]);
        const response: Response = await masterServices.getOneMasterListById(TOKEN!, ModuleAndIdMapping.COUNTRY);
        const res = response.AllRecordsList.map((item: DataItem) => {
          return {
            ...item,
            key: item.CountryId,
            name: item.CountryName,
            id: item.CountryId
          };
        });
        setRefreshCountry(false);
        setCountryList(res);
      } catch (error: any) {
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (refreshCountry) {
        await renderCountryList();
      }
    };
    fetchList();
  }, [refreshCountry]);

  /* utility function */
  /* define initial state to store data */
  const updateNewItemData = (id: number | null) => {
    switch (id) {
      case ModuleAndIdMapping.USERROLE:
        setNewItemData({
          RoleName: "",
          Description: ""
        });
        break;
      case ModuleAndIdMapping.COUNTRY:
        setNewItemData({
          CountryName: ""
        });
        break;
      case ModuleAndIdMapping.STATE:
        setNewItemData({
          StateName: "",
          CountryName: "",
          CountryId: ""
        });
        break;
      case ModuleAndIdMapping.TREEBOTANICAL:
        setNewItemData({
          TreeGeneralName: "",
          BotanicalName: ""
        });
        break;
      case ModuleAndIdMapping.TREEHEALTH:
        setNewItemData({
          TreeHealth: "",
          Description: ""
        });
        break;
      default:
        setNewItemData({});
        break;
    }
  };

  // HANDLERS
  /* select id to get list of master */
  const handleListSelect = (value: number) => {
    /* when add is selected, we need to disable this button.  */
    setAddView(false);
    setSelectedId(value);

    /* clear search input */
    setSearchedText("");
    /* when list is changed entire table should be refreshed as we are navigating. */
    setRefresh(true);
    /* initial state for that particular record is set */
    updateNewItemData(value);
    setEditingItemId(null);
    setHighlightedRow(null);
  };

  /* when user edits the UI should be updated accordingly  */
  const handleEditClick = async (itemId: number | null) => {
    if (itemId) {
      if (addView) {
        setRefresh(true);
      }

      updateNewItemData(selectedId);
      setAddView(true);
      setRefreshCountry(true);
      setEditingItemId(itemId);
      /* BAG and LAG Icon List fetch*/
      setRefreshIconList(true);
      /* dropdown change to selected value reset */
      setChangeValue(false);
    }
  };

  /* handle add and update data */
  const handleSaveClick = async () => {
    try {
      // double check validation name field
      try {
        if (selectedId === ModuleAndIdMapping.USERROLE && !validateInput) await validateName(null, newItemData?.name);
      } catch (error: any) {
        message.destroy();
        message.error(error?.message);
      }

      /* check valid name field empty and not special characters */
      if (!validateInput) return;

      // if (newItemData?.airport_id !== 0 && newItemData?.terminal_id === 0) {
      //   message.destroy();
      //   message.error("Please Select Terminal");
      //   return;
      // }

      setRefresh(false);
      setRefreshCountry(true);
      setFormLoader(true);

      if (selectedId !== null) {
        const formData: Record<string, string> = {};

        // Accept select record data and use update no change data field
        const selectedItem = selectedList.find((record) => record.id === editingItemId);
        // Add state-defined data to the formData object
        for (const key in newItemData) {
          if (newItemData.hasOwnProperty(key)) {
            let value: any = newItemData[key];

            if (editingItemId) {
              // Store update data to not change the table
              if (value === undefined || value === null || value === "" || !value) {
                value = selectedItem && selectedItem.hasOwnProperty(key) ? selectedItem[key] : "";
              }
            } else {
              if (value === undefined || value === null || value === "" || !value) {
                value = "";
              }
            }

            formData[key] = value.toString();
          }
        }

        formData.modelId = selectedId.toString();

        // Check if any value in formData is empty and add corresponding data from selectedItem
        for (const key in formData) {
          if (formData.hasOwnProperty(key) && formData[key] === "") {
            if (selectedItem && selectedItem.hasOwnProperty(key)) {
              formData[key] = selectedItem[key]?.toString() || "";
            }
          }
        }

        if (editingItemId) {
          // update api
          const response = await masterServices.updateMasterAPI(TOKEN!, editingItemId, formData);
          message.destroy();
          message.success(response.message);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setFormLoader(false);
          setRefresh(true);
          setAddView(false);
          updateNewItemData(selectedId);
          setEditingItemId(null);
          setRefresh(true);
        } else {
          // Add API validation
          let isValid = true;

          switch (selectedId) {
            case ModuleAndIdMapping.USERROLE:
              if (!newItemData?.RoleName || !newItemData?.Description) {
                isValid = false;
              }
              break;
            case ModuleAndIdMapping.COUNTRY:
              if (!newItemData?.CountryName) {
                isValid = false;
              }
              break;
            case ModuleAndIdMapping.STATE:
              if (!newItemData?.StateName || !newItemData?.CountryName) {
                isValid = false;
              }
              break;
            case ModuleAndIdMapping.TREEBOTANICAL:
              if (!newItemData?.TreeGeneralName || !newItemData?.BotanicalName) {
                isValid = false;
              }
              break;
            case ModuleAndIdMapping.TREEHEALTH:
              if (!newItemData?.TreeHealth || !newItemData?.Description) {
                isValid = false;
              }
              break;
            default:
              break;
          }

          if (!isValid) {
            setFormLoader(false);
            setRefresh(false);
            message.destroy();
            message.error("Please fill in all fields.");
            return;
          }

          // add api
          const response: Response = await masterServices.addMasterAPI(TOKEN!, formData);
          message.destroy();
          message.success(response.message);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setFormLoader(false);
          setRefresh(true);
          setAddView(false);
          updateNewItemData(selectedId);
        }
        setEditingItemId(null);
      }
    } catch (error: any) {
      message.destroy();
      message.error("Error: " + (error?.message || ""));
      setAddView(true);
      setFormLoader(false);
    }
  };

  /* handle cancel button click to close edit or add table cell */
  const handleCancelClick = () => {
    setRefresh(true);
    setAddView(false);
    setEditingItemId(null);
    // setRefreshTerminal(true);
  };

  /* SAFE CHECK handle display column name bases on selectId */
  const getDisplayedColumns = (selectedId: number | null): string[] => {
    switch (selectedId) {
      case ModuleAndIdMapping.USERROLE:
        return ["RoleName", "Description"]; // Columns displayed for ROLE master
      case ModuleAndIdMapping.COUNTRY:
        return ["CountryName"]; // Columns displayed for AIRPORT master
      case ModuleAndIdMapping.STATE:
        return ["StateName", "CountryName"]; // Columns displayed for TERMINAL master
      case ModuleAndIdMapping.TREEBOTANICAL:
        return ["TreeGeneralName", "BotanicalName"]; // Columns displayed for ATRS master
      case ModuleAndIdMapping.TREEHEALTH:
        return ["TreeHealth", "Description"]; // Columns displayed for BAG master / LAG master
      default:
        return []; // Default empty array
    }
  };

  /* handle new table cell on 1st table row to add data */
  const handleAddClick = () => {
    setPagination({ current: 1, pageSize: 10 });

    setAddView(true);

    /* BAG and LAG Icon List fetch*/
    setRefreshIconList(true);

    /* clear search input */
    setSearchedText("");

    /* when atrs is selected airport list API is called. */
    setRefreshCountry(true);

    let newItem: DataItem;

    switch (selectedId) {
      case ModuleAndIdMapping.USERROLE:
        newItem = {
          id: 0,
          RoleName: "",
          Description: ""
        };
        break;
      case ModuleAndIdMapping.COUNTRY:
        newItem = {
          id: 0,
          CountryName: ""
        };
        break;
      case ModuleAndIdMapping.STATE:
        newItem = {
          StateName: "",
          CountryName: ""
        };
        break;
      case ModuleAndIdMapping.TREEBOTANICAL:
        newItem = {
          id: 0,
          TreeGeneralName: "",
          BotanicalName: ""
        };
        break;
      case ModuleAndIdMapping.TREEHEALTH:
        newItem = {
          id: 0,
          TreeHealth: "",
          Description: ""
        };
        break;
      default:
        newItem = {};
        break;
    }

    /* SAFE CHECK for a particular table accept these only when adding or editing a record */
    const displayedColumns = getDisplayedColumns(selectedId);

    Object.keys(newItem).forEach((column) => {
      if (!displayedColumns?.includes(column)) {
        // delete newItem[column];
      }
    });

    setSelectedList([newItem, ...selectedList]);
    setEditingItemId(newItem.id as number | null);
  };

  /* when adding or editing the columns data is stored in the setNewItemData state */
  const handleCellChange = (value: any, record: DataItem, dataIndex: keyof DataItem) => {
    switch (dataIndex) {
      case "RoleName":
        setNewItemData((prevData: any) => ({
          ...prevData,
          RoleName: value
        }));
        break;
      case "Description":
        setNewItemData((prevData: any) => ({
          ...prevData,
          Description: value
        }));
        break;
      case "CountryName":
        setNewItemData((prevData: any) => ({
          ...prevData,
          CountryName: value,
          CountryId: value
        }));
        break;
      case "StateName":
        setNewItemData((prevData: any) => ({
          ...prevData,
          StateName: value
        }));
        break;
      case "TreeGeneralName":
        setNewItemData((prevData: any) => ({
          ...prevData,
          TreeGeneralName: value
        }));
        break;
      case "BotanicalName":
        setNewItemData((prevData: any) => ({
          ...prevData,
          BotanicalName: value
        }));
        break;
      case "TreeHealth":
        setNewItemData((prevData: any) => ({
          ...prevData,
          TreeHealth: value
        }));
        break;
      default:
        break;
    }

    const newData: any = selectedList.map((item) => {
      if (item.id === record.id) {
        item[dataIndex] = value;
      }
      return item;
    });
    setSelectedList(newData);
  };

  /* handle table changes pagination and others */

  const handleChange = (pagination: TablePaginationConfig): void => {
    setPagination(pagination);
  };

  /* editing by search on time row */
  const selectedListRef = useRef(selectedList);
  useEffect(() => {
    if (editingItemId && searchedText) {
      const itemIndex = selectedList.findIndex((item: any) => item.id === editingItemId);
      if (itemIndex > -1) {
        const updatedList = [...selectedList];
        const editedItem = updatedList.splice(itemIndex, 1)[0];
        updatedList.unshift(editedItem);
        setSelectedList(updatedList);
        setPagination({ current: 1, pageSize: 10 });
      }
    }
    /* IMP Not add selectedList dependency  */
  }, [editingItemId, searchedText]);

  useEffect(() => {
    if (selectedList !== selectedListRef.current) {
      selectedListRef.current = selectedList;
    }
  }, [selectedList]);

  /* handle table filtering data from search text fields */
  const filterDataSource = (data: any) => {
    const searchText = searchedText.toLowerCase();
    return data.filter((item: any) => {
      switch (selectedId) {
        case ModuleAndIdMapping.USERROLE:
          return String(item.RoleName).toLowerCase()?.includes(searchText) || String(item.Description).toLowerCase()?.includes(searchText);
        case ModuleAndIdMapping.COUNTRY:
          return String(item.CountryName).toLowerCase()?.includes(searchText);
        case ModuleAndIdMapping.STATE:
          return String(item.StateName).toLowerCase()?.includes(searchText) || String(item.CountryName).toLowerCase()?.includes(searchText);
        case ModuleAndIdMapping.TREEBOTANICAL:
          return String(item.TreeGeneralName).toLowerCase()?.includes(searchText) || String(item.BotanicalName).toLowerCase()?.includes(searchText);
        case ModuleAndIdMapping.TREEHEALTH:
          return String(item.TreeHealth).toLowerCase()?.includes(searchText) || String(item.Description).toLowerCase()?.includes(searchText);
        default:
          return String(item.RoleName).toLowerCase()?.includes(searchText) || String(item.Description).toLowerCase()?.includes(searchText);
      }
    });
  };

  /* handle confirm model to yes or no */
  const showDeleteConfirm = (itemId: number) => {
    setDeleteRecordId(itemId);
    setOpenModal(true);
    setHighlightedRow(itemId);
  };

  /* handle delete record */
  const handleDeleteClick = async (itemId: number | null) => {
    setOpenModal(false);
    setRefresh(false);
    if (itemId) {
      try {
        const response: Response = await masterServices.deleteMasterAPI(TOKEN!, selectedId!, itemId);
        message.destroy();
        message.success(response.message);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setRefresh(true);
      } catch (error: any) {
        setRefresh(false);
        setHighlightedRow(null);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    }
  };

  /* handle cancel for modal clicks No click or close icon click */
  const handleDeleteCancelClick = () => {
    setOpenModal(false);
    setHighlightedRow(null);
  };

  /* handler to validate special characters in name */
  const validateName = (_: any, value: any) => {
    const regex = /^[a-zA-Z0-9\s]+$/; // Regular expression to allow only numbers and characters
    if (!regex.test(value)) {
      return Promise.reject(new Error("Please enter a valid name without special characters."));
    }
    return Promise.resolve();
  };

  /* columns for all modules are defined here */
  const columns: ColumnsType<any> =
    /* safe check added to see if API data is fetched */
    selectedList.length > 0
      ? Object.keys(selectedList[0])
          .filter((key) => {
            switch (selectedId) {
              case ModuleAndIdMapping.USERROLE:
                return key === "RoleName" || key === "Description";
              case ModuleAndIdMapping.COUNTRY:
                return key === "CountryName";
              case ModuleAndIdMapping.STATE:
                return key === "StateName" || key === "CountryName";
              case ModuleAndIdMapping.TREEBOTANICAL:
                return key === "TreeGeneralName" || key === "BotanicalName";
              case ModuleAndIdMapping.TREEHEALTH:
                return key === "TreeHealth" || key === "Description";
              default:
                return false;
            }
          })
          .map((key, index) => {
            let columnName = key;
            let order = index + 1; // Initialize order with index value
            switch (key) {
              case "RoleName":
                switch (selectedId) {
                  case ModuleAndIdMapping.USERROLE:
                    columnName = "Role Name";
                    // order = 1;
                    break;
                }
                break;
              case "CountryName":
                switch (selectedId) {
                  case ModuleAndIdMapping.COUNTRY:
                    columnName = "Country Name";
                    // order = 2;
                    break;
                  case ModuleAndIdMapping.STATE:
                    columnName = "Country Name";
                    // order = 3;
                    break;
                }
                break;
              case "StateName":
                switch (selectedId) {
                  case ModuleAndIdMapping.STATE:
                    columnName = "State Name";
                    // order = 3;
                    break;
                }
                break;
              case "TreeGeneralName":
                switch (selectedId) {
                  case ModuleAndIdMapping.TREEBOTANICAL:
                    columnName = "General Name";
                    // order = 4;
                    break;
                }
                break;
              case "BotanicalName":
                switch (selectedId) {
                  case ModuleAndIdMapping.TREEBOTANICAL:
                    columnName = "Botanical Name";
                    // order = 4;
                    break;
                }
                break;
              case "TreeHealth":
                switch (selectedId) {
                  case ModuleAndIdMapping.TREEHEALTH:
                    columnName = "Tree Health";
                    // order = 5;
                    break;
                }
                break;
              default:
                columnName = key;
            }

            columnName = columnName.charAt(0).toUpperCase() + columnName.slice(1); // Capitalize the first letter

            return {
              title: columnName,
              dataIndex: key,
              order,
              sorter: (a: any, b: any) => {
                switch (selectedId) {
                  case ModuleAndIdMapping.USERROLE:
                    return a.RoleName.localeCompare(b.RoleName) || a.Description.localeCompare(b.Description);
                  case ModuleAndIdMapping.COUNTRY:
                    return a.CountryName.localeCompare(b.CountryName);
                  case ModuleAndIdMapping.STATE:
                    return a.StateName.localeCompare(b.StateName) || a.CountryName.localeCompare(b.CountryName);
                  case ModuleAndIdMapping.TREEBOTANICAL:
                    return a.TreeGeneralName.localeCompare(b.TreeGeneralName) || a.BotanicalName.localeCompare(b.BotanicalName);
                  case ModuleAndIdMapping.TREEHEALTH:
                    return a.TreeHealth.localeCompare(b.TreeHealth) || a.Description.localeCompare(b.Description);
                  default:
                    return false;
                }
              },
              render: (text: any, record: any) => {
                const renderInput = (value: any, key: any) => (
                  <Input
                    value={String(value)}
                    name={String(key)}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (!inputValue.startsWith(" ")) {
                        handleCellChange(inputValue, record, key);
                      }
                    }}
                    onBlur={async (e) => {
                      try {
                        const inputValue = e.target.value;
                        if (!inputValue.startsWith(" ") && (key === "name" || key === "rolename")) {
                          await validateName(null, inputValue);
                        }
                        if (!inputValue.trim()) {
                          throw new Error(`Please Enter ${key}`); // Throw error for empty input
                        }

                        setValidateInput(true); // Validation successful
                      } catch (error: any) {
                        setValidateInput(false); // Handle validation error
                        message.destroy();
                        message.error(error.message);
                      }
                    }}
                  />
                );

                const renderSelect = (value: any, key: any, options: any) => (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    className={`${styles["input-dropdown"]}`}
                    placeholder={`Select ${key === "CountryName" ? "Country Name" : key}`}
                    onChange={(value: any) => {
                      if (key === "CountryName") {
                        setChangeValue(true);
                      }

                      handleCellChange(value === `Select ${key === "CountryName" ? "Country Name" : key}` && changeValue ? 0 : value, record, key);
                    }}
                    value={value ? value : changeValue ? `Select ${key === "CountryName" ? "Country Name" : key}` : `Select ${key === "CountryName" ? "Country Name" : key}`}
                    filterOption={(input: any, option: any) => {
                      const children = option?.children as unknown;
                      if (typeof children === "string") {
                        return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                      }
                      return false;
                    }}
                  >
                    {options?.map((option: any, index: any) => (
                      <Select.Option value={option.id} key={index}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                );

                const renderText = (value: any) => (
                  <Link to={``}>
                    <span className="entity-name-selected">{String(value)}</span>
                  </Link>
                );

                /* nested switch case to check for edit and delete */
                switch (true) {
                  case editingItemId === record?.id:
                    switch (selectedId) {
                      case ModuleAndIdMapping.USERROLE:
                        return renderInput(text, key);
                      case ModuleAndIdMapping.COUNTRY:
                        return renderInput(text, key);
                      case ModuleAndIdMapping.STATE:
                        if (key === "CountryName") {
                          return renderSelect(record.CountryName, "CountryName", countryList);
                        } else {
                          return renderInput(text, key);
                        }

                      case ModuleAndIdMapping.TREEBOTANICAL:
                        return renderInput(text, key);
                      case ModuleAndIdMapping.TREEHEALTH:
                        return renderInput(text, key);
                      default:
                        return renderInput(text, key);
                    }
                  case highlightedRow === record.id:
                    if (key === "name" || key === "RoleName" || key === "Description" || key === "CountryName" || key === "StateName" || key === "TreeGeneralName" || key === "BotanicalName" || key === "TreeHealth") {
                      return renderText(text);
                    }

                    return text;
                  default:
                    return formatRoleName(text);
                }
              }
            };
          })
          .sort((a, b) => a.order - b.order)
      : [];

  /* extra column added for action. */
  if (selectedList.length > 0) {
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, record: any) => {
        if (editingItemId === record.id) {
          return (
            <Space>
              <span className="gx-link" onClick={handleSaveClick}>
                <SaveOutlined className={`${styles["icon-save"]}`} />
              </span>
              <span className="gx-link" onClick={handleCancelClick}>
                <CloseCircleFilled className={`${styles["icon-delete-close"]}`} />
              </span>
            </Space>
          );
        }
        return (
          <Space>
            <span className="gx-link" onClick={() => handleEditClick(record?.id)}>
              <i className={`icon icon-edit ${styles["icon-edit"]}`} />
            </span>
            <span className="gx-link" onClick={() => showDeleteConfirm(record?.id)}>
              <DeleteOutlined className={`${styles["icon-delete-close"]}`} />
            </span>
          </Space>
        );
      }
    });
  }

  return (
    <div>
      {formLoader ? (
        <div className="loading loading--center">
          <Space direction="vertical" className="space--width" size="large">
            <Spin tip="Loading" size="large" />
          </Space>
        </div>
      ) : (
        <Card title="Master Management" className="card--height">
          <div className={`${styles["div-container"]}`}>
            <div style={{ width: "30%" }}>
              <Select
                className={`${styles["select-master-dropdown"]}`}
                placeholder="Select Master Name"
                onChange={(value: number) => {
                  handleListSelect(value);
                  setAddView(false);
                }}
                value={selectedId}
              >
                {/* Mapping rolesList to generate Select.Option elements */}
                <Select.Option value={ModuleAndIdMapping.USERROLE}>User Role</Select.Option>
                <Select.Option value={ModuleAndIdMapping.COUNTRY}>Country</Select.Option>
                <Select.Option value={ModuleAndIdMapping.STATE}>State</Select.Option>
                <Select.Option value={ModuleAndIdMapping.TREEBOTANICAL}>Tree Botanical Name</Select.Option>
                <Select.Option value={ModuleAndIdMapping.TREEHEALTH}>Tree Health Condition</Select.Option>
              </Select>
            </div>

            <div className={` ${styles["search-container"]}`}>
              <Input.Search
                className={styles["input-search"]}
                placeholder={`Please Enter ${selectedId === ModuleAndIdMapping.USERROLE ? "Role" : selectedId === ModuleAndIdMapping.COUNTRY ? "Country" : selectedId === ModuleAndIdMapping.STATE ? "State" : selectedId === ModuleAndIdMapping.TREEBOTANICAL ? "Tree Botanical" : selectedId === ModuleAndIdMapping.TREEHEALTH ? "Tree Health" : "Item"} Name.`}
                onSearch={(value) => {
                  setSearchedText(value);
                }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (!inputValue.startsWith(" ")) {
                    setSearchedText(inputValue);
                  }
                }}
                value={searchedText}
              />
              <Button type="primary" style={{ display: "block", margin: "0 auto" }} disabled={addView} className={` ${styles["add-button"]}`} onClick={handleAddClick}>
                Add
              </Button>
            </div>
          </div>

          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={editingItemId && searchedText ? selectedList : filterDataSource(selectedList)}
            // dataSource={selectedList}
            onChange={handleChange}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              pageSizeOptions
            }}
            bordered
          />
        </Card>
      )}

      <Modal
        title={`Are you sure you want to delete this ${selectedId === ModuleAndIdMapping.USERROLE ? "User Role" : selectedId === ModuleAndIdMapping.COUNTRY ? "Country" : selectedId === ModuleAndIdMapping.STATE ? "State" : selectedId === ModuleAndIdMapping.TREEBOTANICAL ? "Tree Botanical" : selectedId === ModuleAndIdMapping.TREEHEALTH ? "Tree Health" : "Item"} ?`}
        open={openModal}
        onOk={() => handleDeleteClick(deleteRecordId)}
        onCancel={handleDeleteCancelClick}
        okText="Yes"
        cancelText="No"
        width="500px"
        destroyOnClose
        maskClosable={true}
        bodyStyle={{ padding: 0 }}
        wrapClassName="delete-modal"
      />
    </div>
  );
};

export default MasterTables;
