import axios from "axios";

export const getLatestDeployementLogAPI = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/deploymentlog/latest`);

    if (response.status !== 200) {
      throw new Error("Error fetching deployment log. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getDeploymentLogListAPI = async (TOKEN: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/deploymentlog/all`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching deployment logs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
