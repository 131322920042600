import UserProfile from "./UserProfile";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { toggleCollapsedSideNav } from "../../appRedux/actions";
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../appRedux/store/index";
import styles from "./UserProfile.module.css";
import { useHistory } from "react-router";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import { projectName } from "../../util/projectName";
import TrackMyTreeLogo from "../../assets/pictures/trackMyTreeLogo.jpg";

const { Header } = Layout;

const Topbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { navStyle } = useSelector(({ settings }: RootState) => settings);
  const { navCollapsed, width } = useSelector(({ common }: RootState) => common);
  const user: any = JSON.parse(localStorage.getItem(LocalStorageConstants.USER) || "{}");

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <Link to="/">
        <div style={{ display: "flex" }}></div>
      </Link>

      <div className={styles["topbar"]}>
        <span className={styles["gradient-text"]} onClick={() => history.push(`/home`)}>
          {/* {projectName() ?? "TrackMyTree"} */}
          <img
            src={TrackMyTreeLogo}
            alt="TrackMyTree"
            height={70}
            style={{ marginLeft: "-10px" }}
            onError={(e: any) => {
              e.target.style.display = projectName(); // Hide the image
            }}
          />
        </span>

        {user && <span className={styles["text"]}>{(user?.Organization && user?.Organization[0]?.OrganizationName) ?? ""}</span>}

        <div className="gx-d-none gx-d-lg-block" style={{ paddingTop: "14px" }}>
          <UserProfile />
        </div>
      </div>
    </Header>
  );
};

export default Topbar;
