import axios from "axios";

// area
export const getOneOrgMasterListByAreaId = async (TOKEN: string, villageId: number, isActive: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organizationMaster/village/area/${villageId}?IsActive=${isActive}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

// area
export const getFarmerListByVillageId = async (TOKEN: string, villageId: number, isActive: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/organizationMaster/farmer/${villageId}?IsActive=${isActive}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const ReportTableData = async (TOKEN: string, projectId: number[], clusterId: number[], villageId: number[], farmerId: number[], areaId: number[], healthConditionId: number[], formattedFromDate: string, formattedToDate: string, isActive: string, isReplantationData: boolean, isTagMissingData: boolean) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/report?projectIdList=[${projectId}]&clusterIdList=[${clusterId}]&villageIdList=[${villageId}]&farmerIdList=[${farmerId}]&areaIdList=[${areaId}]&healthConditionIdList=[${healthConditionId}]&fromDateTime=${formattedFromDate}&toDateTime=${formattedToDate}&allTransactionHistory=${isActive}&isReplantationData=${isReplantationData}&isTagMissingData=${isTagMissingData}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const ErrorLogTableData = async (TOKEN: string, areaId: number, formattedFromDate: string, formattedToDate: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/tree/ErrorLogList?AreaId=${areaId}&fromDateTime=${formattedFromDate}&toDateTime=${formattedToDate}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateMissingTreeTag = async (TOKEN: any, areaIdList: number[], isTagMissing: boolean, treeIdList: number[]) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/tree/missingTag?areaIdList=[${areaIdList}]&isTagMissing=${isTagMissing}&treeIdList=[${treeIdList}]`,
      {},
      {
        headers: {
          authorization: `Bearer ${TOKEN}`
        }
      }
    );

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};
