import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface DataPoint {
  name: string;
  Trees: number;
}

interface LineChartProps {
  lineChartData: DataPoint[];
}

const SimpleLineChart: React.FC<LineChartProps> = ({ lineChartData }) => (
  <ResponsiveContainer width="100%" height={260}>
    {lineChartData && lineChartData?.length > 0 ? (
      <LineChart data={lineChartData} margin={{ top: 11, right: 7, left: -10, bottom: 50 }}>
        {/* <XAxis dataKey="name" /> */}
        <XAxis dataKey="name" interval={0} angle={-45} textAnchor="end" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        {/* <Legend /> */}
        <Line type="monotone" dataKey="Trees" stroke="#003366" activeDot={{ r: 8 }} />
        {/* <Line type="monotone" dataKey="uv" stroke="#FE9E15" /> */}
      </LineChart>
    ) : (
      <svg width="100%" height={260}>
        <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" className="h3">
          No data available!
        </text>
      </svg>
    )}
  </ResponsiveContainer>
);

export default SimpleLineChart;
