import React, { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet-fullscreen";
import GOOD from "../../assets/pictures/GOOD.png";
import VERY_GOOD from "../../assets/pictures/VERY-GOOD.png";
import NEED_ATTENTION from "../../assets/pictures/NEED-ATTENTION.png";
import DEAD from "../../assets/pictures/DEAD.webp";

interface Location {
  latitude: string;
  longitude: string;
  name: string;
  treeTag: string;
  TreeHealthCondition: string;
  AreaName: string;
  ImageUrl: string;
  selectedTreeIndex: number;
}

interface MapProps {
  locations: Location[];
  onMarkerClick: (tree: Location) => any;
  selectedTree: Location | null;
  selectedTreeLocation: Location | null;
  selectedTreeIndex: number;
}

function getStatusIconColor(status: string) {
  switch (status) {
    case "GOOD":
      return GOOD;
    case "NEED-ATTENTION":
      return NEED_ATTENTION;
    case "VERY GOOD":
      return VERY_GOOD;
    case "DEAD":
      return DEAD;
    default:
      return "";
  }
}

const Map: React.FC<any> = ({ locations, onMarkerClick, selectedTree, selectedTreeLocation, selectedTreeIndex }) => {
  const [map, setMap] = useState<L.Map | null>(null);
  const [markers, setMarkers] = useState<L.MarkerClusterGroup | null>(null);
  const mapStyles = {
    width: "100%",
    height: "60vh"
  };

  useEffect(() => {
    const newMap = L.map("map", {
      attributionControl: false
    }).setView([18.50747587, 51.12783853], 2);

    const googleLayer = L.tileLayer("https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
      maxZoom: 30,
      subdomains: ["mt0", "mt1", "mt2", "mt3"]
    });
    googleLayer.addTo(newMap);

    //Add Google Maps Satellite layer
    // const googleSatelliteLayer = L.tileLayer("https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
    //   maxZoom: 20,
    //   subdomains: ["mt0", "mt1", "mt2", "mt3"]
    // });
    // googleSatelliteLayer.addTo(newMap);
    // Add Google Maps Satellite layer with street names
    // const googleSatelliteLayer = L.tileLayer("https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}", {
    //   maxZoom: 30,
    //   subdomains: ["mt0", "mt1", "mt2", "mt3"]
    // });
    // googleSatelliteLayer.addTo(newMap);
    // Add OpenStreetMap Satellite layer
    // const openStreetMapSatelliteLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    //   maxZoom: 19,
    //   subdomains: ["a", "b", "c"]
    // });
    // openStreetMapSatelliteLayer.addTo(newMap);

    // // Add OpenStreetMap layer with street names
    // const openStreetMapLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    //   attribution: "© OpenStreetMap contributors"
    // });
    // openStreetMapLayer.addTo(newMap);

    // Add the Fullscreen control to the map
    //@ts-ignore
    newMap.addControl(new L.Control.Fullscreen());

    setMap(newMap);
  }, []);

  useEffect(() => {
    if (selectedTree && map) {
      const newLatLng = new L.LatLng(+selectedTree.latitude, +selectedTree.longitude);
      map.setView(newLatLng, 20);
    }
  }, [map]);

  useEffect(() => {
    if (selectedTreeLocation && map) {
      const newLatLng = new L.LatLng(+selectedTreeLocation.latitude, +selectedTreeLocation.longitude);
      map.setView(newLatLng, 20);
    }
  }, [map, selectedTreeLocation]);

  useEffect(() => {
    if (map) {
      const newMarkers = L.markerClusterGroup({
        showCoverageOnHover: false,
        spiderfyOnMaxZoom: true,
        maxClusterRadius: 20,
        disableClusteringAtZoom: 20,
        iconCreateFunction: (cluster) => {
          const childCount = cluster.getChildCount();
          const className = `custom-cluster${childCount > 1 ? " multiple" : ""}`;

          // Customize the background gradient, border, shadow, and size here
          const gradientColorStart = "#1860ab"; // Light blue gradient start color
          const gradientColorEnd = "#0d4078"; // Darker blue gradient end color
          const borderColor = "#0d4078"; // Border color
          const shadowColor = "rgba(0, 0, 0, 0.5)"; // Subtle shadow color
          const textColor = "#ffffff"; // White text color
          const iconSize = 40; // Use your desired size

          let content = `<div class="${className}" style="background: linear-gradient(${gradientColorStart}, ${gradientColorEnd}); width: ${iconSize}px; height: ${iconSize}px; border-radius: 50%; border: 2px solid ${borderColor}; box-shadow: 0 2px 4px ${shadowColor}; display: flex; align-items: center; justify-content: center; color: ${textColor}; font-weight: bold; font-size: 14px;">${childCount}</div>`;

          return L.divIcon({
            html: content,
            className: "custom-cluster-icon",
            iconSize: [iconSize, iconSize]
          });
        }
      });

      locations.forEach((location: any, index: number) => {
        const customIcon = L.icon({
          iconUrl: getStatusIconColor(location.TreeHealthCondition),
          iconSize: [25, 45], // Adjusted icon size
          iconAnchor: [12, 45], // Adjusted icon anchor
          popupAnchor: [0, -30]
        });

        const popupAnchorOffset = [0, 20];

        const marker = L.marker([+location.latitude, +location.longitude], { icon: customIcon });
        marker.bindPopup(`<b>${location.treeTag}</b><br>${location.name}`); // Adjusted offset
        marker.on("click", () => {
          onMarkerClick(location, index);
          marker.openPopup();
        });

        newMarkers.addLayer(marker);
      });

      // Add the popup only for the selected tree index
      setMarkers(newMarkers);
      map.addLayer(newMarkers);
    }
  }, [map, locations, onMarkerClick]);

  useEffect(() => {
    if (markers && selectedTreeIndex !== null) {
      const marker = markers.getLayers().find((layer: any) => {
        const index = locations.findIndex((location: any) => +location.latitude === layer.getLatLng().lat && +location.longitude === layer.getLatLng().lng);
        return index === selectedTreeIndex;
      }) as L.Marker;

      if (marker) {
        // Close all popups before opening the desired one
        markers.eachLayer((layer) => {
          layer.closePopup();
        });

        marker.openPopup();
      }
    }
  }, [markers, selectedTreeIndex, locations]);

  return <div id="map" style={mapStyles} />;
};

export default Map;
