import React, { useState } from "react";
import { Layout, Menu, Tooltip } from "antd";
import { RightOutlined, AppstoreOutlined, InfoCircleFilled, UserOutlined, ChromeFilled, ArrowLeftOutlined } from "@ant-design/icons";
import ProjectLogo from "../../../assets/pictures/trackMyTreeLogo.jpg";
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";

const webPortalDemoUrl = process.env.REACT_APP_WEBPORTAL_DEMO;
const appOnlineModeDemoUrl = process.env.REACT_APP_ONLINE_MODE_APP_DEMO;
const appOfflineModeDemoUrl = process.env.REACT_APP_OFFLINE_MODE_APP_DEMO;
const { Sider, Content, Footer, Header } = Layout;

const WebPortalPage = () => {
  const history = useHistory();
  const handleBackButtonClick = () => {
    history.push("/");
  };

  const sections = [
    {
      id: 1,
      title: "About TrackMyTree",
      content: (
        <>
          <h1 style={{ textAlign: "center", fontSize: "1.8em" }}>
            Welcome to <span style={{ color: "#1860ab", fontWeight: "bold" }}>Track</span>
            <span style={{ color: "#46b82f", fontWeight: "bold" }}>MyTree</span>&nbsp;
          </h1>
          <p style={{ marginBottom: "15px", marginTop: "10px" }}>A comprehensive solution for addressing challenges in tree plantation initiatives. The system ensures accurate data tracking from plantation to growth, promoting transparency and accountability.</p>
          <p></p>
          <p>
            <strong>Platform Highlights:</strong>
          </p>
          <ul>
            <li>Create a reliable record for each tree, ensuring accurate data.</li>
            <li>Develop a user-friendly mobile app and web portal for seamless data collection and visualization.</li>
            <li>Establish a standardized platform for collaborative efforts among local organizations involved in tree plantation projects.</li>
          </ul>
          <p>Explore more about TrackMyTree:</p>
          <ul>
            <li>
              <a href={webPortalDemoUrl} target="_blank" rel="noopener noreferrer">
                Web Portal Demo
              </a>
            </li>
            <li>
              <p style={{ color: "#1860ab" }}>Mobile App Demo</p>
              <ul>
                <li>
                  <a href={appOnlineModeDemoUrl} target="_blank" rel="noopener noreferrer">
                    Online Mode
                  </a>
                </li>
                <li>
                  <a href={appOfflineModeDemoUrl} target="_blank" rel="noopener noreferrer">
                    Offline Mode
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </>
      )
    },
    {
      id: 2,
      title: "Multi-Tenant System",
      content: (
        <>
          <p className={styles["system-intro"]}>
            <span style={{ marginLeft: "20px" }}>The</span> dynamic Multi-Tenant System of <span style={{ color: "#1860ab" }}>Track</span>
            <span style={{ color: "#46b82f" }}>MyTree</span>&nbsp;, an advanced platform meticulously crafted to transform tree plantation initiatives. Within this system, various roles seamlessly collaborate, each playing a pivotal part in the success of our mission.
          </p>
          <div className={styles["role-section"]}>
            <p className={styles["role-title"]}>Roles in the System:</p>
            <ul className={styles["role-list"]}>
              <li>
                <p className={styles["highlight"]}>Super-Admin:</p> <span style={{ marginLeft: "20px" }}>As the architect</span> of system, the Super-Admin wields control over common master settings, organizations, and user management. Their strategic oversight ensures the stability and integrity of the entire platform.
              </li>
              <li>
                <p className={styles["highlight"]}>Organization-Admin:</p> <span style={{ marginLeft: "20px" }}>Empowered with</span> the authority to shape our projects, the Organization-Admin takes the lead in creating and managing projects, clusters, villages, areas, caretakers, and field-executives. Their responsibilities also extend to the generation of barcodes for efficient tree tagging, contributing to the system's efficiency.
              </li>
              <li>
                <p className={styles["highlight"]}>Field-Executive:</p> <span style={{ marginLeft: "20px" }}>On the frontlines</span>, the Field-Executive is pivotal to our data collection efforts. Armed with the ability to scan tree barcodes, they play a hands-on role in adding and updating tree information using our mobile app, ensuring real-time data accuracy.
              </li>
            </ul>
          </div>
        </>
      )
    },
    {
      id: 3,
      title: "Web Portal Overview",
      content: (
        <>
          <p className={styles["system-intro"]}>
            {" "}
            <span style={{ marginLeft: "20px" }}>The</span> Web Portal of <span style={{ color: "#1860ab" }}>Track</span>
            <span style={{ color: "#46b82f" }}>MyTree</span>&nbsp;, featuring a comprehensive dashboard, tree listings, map visualization, growth timeline, and detailed reports.
          </p>
          <ul className={styles["role-list"]}>
            <li>
              <p className={styles["highlight"]}>Dashboard:</p> - Comprehensive overview of tree-related statistics and charts.
            </li>
            <li>
              <p className={styles["highlight"]}>Tree Listings and Search:</p> - Displays a list of all trees with relevant details, and the search filter allows efficient data retrieval.
            </li>
            <li>
              <p className={styles["highlight"]}>Map View:</p> - Visualizes tree locations based on latlongs data.
            </li>
            <li>
              <p className={styles["highlight"]}>Timeline:</p> - Tree Growth Timeline Visualization.
            </li>
            <li>
              <p className={styles["highlight"]}>Reports:</p> - Provides insights through tree statistics and replantation tree reports.
            </li>
          </ul>
        </>
      )
    },
    {
      id: 4,
      title: "Mobile App Overview",
      content: (
        <>
          <p className={styles["system-intro"]}>
            {" "}
            <span style={{ marginLeft: "20px" }}>The</span> Mobile App of <span style={{ color: "#1860ab" }}>Track</span>
            <span style={{ color: "#46b82f" }}>MyTree</span>&nbsp;, offering versatile data collection in online and offline modes, efficient tree information retrieval through barcode scanning, user-friendly data input for field executives, and real-time updates to keep the system enriched with on-ground insights.
          </p>
          <ul className={styles["role-list"]}>
            <li>
              <p className={styles["highlight"]}>Online and Offline Modes:</p> - Enables data collection in various network conditions.
            </li>
            <li>
              <p className={styles["highlight"]}>Barcode Scanning:</p> - Facilitates efficient tree information retrieval.
            </li>
            <li>
              <p className={styles["highlight"]}>Data Input:</p> - Allows field executives to add and update tree information.
            </li>
            <li>
              <p className={styles["highlight"]}>Real-time Updates:</p> - Ensures the system is constantly fed with on-ground insights.
            </li>
          </ul>
        </>
      )
    }
  ];

  const getMenuIcon = (sectionId: number) => {
    const iconMap: any = {
      1: <InfoCircleFilled style={{ fontSize: "20px" }} />,
      2: <UserOutlined style={{ fontSize: "20px" }} />,
      3: <ChromeFilled style={{ fontSize: "20px" }} />,
      4: <AppstoreOutlined style={{ fontSize: "20px" }} />
    };
    return iconMap[sectionId] || <RightOutlined />;
  };

  const [selectedSection, setSelectedSection] = useState<number | null>(sections.length > 0 ? sections[0].id : null);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => setCollapsed(!collapsed);

  const getSelectedSectionContent = () => {
    const currentSection = sections.find((section) => section.id === selectedSection);
    return currentSection ? currentSection.content : null;
  };
  const handleSectionClick = (id: number) => {
    setSelectedSection(id);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider width={180} theme="dark" className={`${styles["sider"]} `} collapsed={collapsed}>
        <div className={`${styles["menu-icon"]}`}>
          <i className="icon icon-menu" onClick={toggleCollapse} />
        </div>
        <Menu className={`${styles["menu-item"]}`} style={{ height: "100vh", backgroundColor: "#1860ab !important" }} mode="vertical" theme="dark" selectedKeys={selectedSection !== null ? [String(selectedSection)] : []}>
          {sections.map((section) => (
            <Menu.Item key={section.id} onClick={() => handleSectionClick(section.id)} style={{ marginLeft: "12px", marginTop: "12px", fontSize: "14px" }} title="">
              <Tooltip title={collapsed ? <span>&nbsp;&nbsp;{section.title}&nbsp;&nbsp;</span> : ""} placement="right" color={"#104981"} overlayStyle={{ padding: "27px" }}>
                <span style={{ fontSize: collapsed ? "20px" : "24px", marginLeft: collapsed ? "-10px" : "0", transform: collapsed ? "translateX(-50%)" : "none" }}>{getMenuIcon(section.id)}</span>
              </Tooltip>
              {!collapsed && <span style={{ color: selectedSection === section.id ? "#fff" : "inherit" }}>{section.title}</span>}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>

      <Layout style={{ height: "100vh" }}>
        <Header className={styles["header"]}>
          <div>
            <img src={ProjectLogo} alt="Project Logo" className={styles["logo-image"]} />
          </div>
          <div>
            <Tooltip placement="left" title="&nbsp;&nbsp;Back&nbsp;&nbsp;" color={"#104981"} overlayStyle={{ padding: 0 }}>
              <ArrowLeftOutlined className={`${styles["back-icon"]} gx-text-primary `} onClick={handleBackButtonClick} />
            </Tooltip>
          </div>
        </Header>
        <Content className={`${styles["content"]} `}>
          <div className={`${styles["info-column"]}`}>{selectedSection !== null && <div className={styles["section-content"]}>{getSelectedSectionContent()}</div>}</div>
        </Content>
        <Footer>
          {" "}
          <div className="gx-layout-footer-content">
            © <span style={{ color: "#1860ab" }}>Track</span>
            <span style={{ color: "#46b82f" }}>MyTree</span>&nbsp;
            {new Date().getFullYear()}-{(new Date().getFullYear() + 1).toString().slice(2)}
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default WebPortalPage;
