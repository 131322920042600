import { Table, Space, Card, message, Select, Button, Spin, DatePicker, Tooltip } from "antd";
import styles from "../Reports/reports.module.css";
import { useSelector } from "react-redux";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import Dropdown from "../Reports/filterComponent";
import * as userService from "../../services/userServices";
import * as reportService from "../../services/reportService";
import { TablePaginationConfig } from "antd/lib/table/interface";
import { RootState } from "../../appRedux/store";
import CommonModal from "../Modal";
import { Column, ErrorLog } from "../../shared/interfaces/Report.interface";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Report = () => {
  // CONSTANTS
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);

  const { navCollapsed } = useSelector(({ common }: RootState) => common);

  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const [arealist, setArealist] = useState<any>([]);
  const [areaId, setAreaId] = useState<number | null>(null);
  const pageSizeOptions = ["10", "20", "50", "100"];
  const [handleSubmit, setHandleSubmit] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 10 });
  const [reportsData, setReportsData] = useState<ErrorLog[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [areaName, setAreaName] = useState("");

  /* export table data in excel sheet*/
  const [reportExportData, setReportExportData] = useState<any | null>();
  const [selectDate, setSelectDate] = useState<number | null>(7);

  const [filterSelectOption, setFilterSelectOption] = useState<string | null>("1");

  /* loading state */
  const [formLoader, setFormLoader] = useState<boolean>(false);

  /* formDate and toDate converted to YYYY-MM-DDTHH:mm:ss.SSS[Z] this format  */
  const formattedFromDate = fromDate ? fromDate.clone().seconds(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : "";
  const formattedToDate = toDate ? moment(toDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : "";

  const dateTimeValidation = (fromDateIsValid: boolean, toDateIsValid: boolean, formattedFromDate: string, formattedToDate: string) => {
    /* Validations */
    if (!fromDateIsValid) {
      message.error("Invalid 'From' date format.");
      return false;
    }

    if (!toDateIsValid) {
      message.error("Invalid 'To' date format.");
      return false;
    }

    if (formattedFromDate === formattedToDate) {
      message.error("The selected times should be different.");
      return false;
    }

    if (formattedFromDate > formattedToDate) {
      message.error("Invalid date range: 'From' date should be smaller than 'To' date.");
      return false;
    }
    return true;
  };

  /* Fetching all Area Tables list when visited using select */

  useEffect(() => {
    const renderAreaList = async () => {
      try {
        // setFormLoader(true);
        setArealist([]);
        const response = await userService.getOneMasterAreaList(TOKEN!);
        const processedData: any = response.areaList.map((item: any) => {
          return { id: item?.AreaId, name: `${item?.Project.ProjectName} >> ${item?.AreaName}`, ...item };
        });

        setArealist(processedData);
      } catch (error: any) {
        setArealist([]);
        // setFormLoader(false);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    renderAreaList();
  }, []);

  useEffect(() => {
    if (filterSelectOption === "1") {
      const defaultFromDate = moment().subtract(7, "day").startOf("day").hours(0).minutes(0);
      const defaultToDate = moment();
      setFromDate(defaultFromDate);
      setToDate(defaultToDate);
    } else if (filterSelectOption === "2") {
      const defaultFromDate = moment().subtract(1, "day").startOf("day").hours(0).minutes(0);
      const defaultToDate = moment();
      setFromDate(defaultFromDate);
      setToDate(defaultToDate);
    }
  }, [filterSelectOption]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setHandleSubmit(false);
        // Validating date and time formats.

        const fromDateIsValid = moment(formattedFromDate, "YYYY-MM-DDTHH:mm:ss.SSS[Z]", true).isValid();
        const toDateIsValid = moment(formattedToDate, "YYYY-MM-DDTHH:mm:ss.SSS[Z]", true).isValid();

        if (!dateTimeValidation(fromDateIsValid, toDateIsValid, formattedFromDate, formattedToDate)) {
          return;
        }

        if (!areaId || areaId === null) {
          message.error("Please select appropriate filter. Area selection is mandatory");
          return;
        }

        setReportsData([]);
        setFormLoader(true);
        const response = await reportService.ErrorLogTableData(TOKEN!, areaId!, formattedFromDate, formattedToDate);
        const processedData =
          response &&
          response?.ErrorLogs?.map((item: any, index: number) => {
            return {
              key: index,
              IsAddORUpdate: item?.IsAdd === 1 ? "Add" : "Update",
              ...item
            };
          });
        setReportsData(processedData);

        /* table data export in excel sheet */
        const processedExcelData =
          response &&
          response?.ErrorLogs?.map((item: any, index: number) => {
            return {
              SN: index + 1,
              "Selected Area": item.SelectedAreaName,
              "Scanned Tree Tag": item.ScannedTreeTag,
              "Scanned Area": item.ScannedAreaName,
              "Time Stamp": item.DateAndTimeStamp,
              "Add / Update": item?.IsAdd === 1 ? "Add" : "Update",
              Reason: item.Reason
            };
          });

        setReportExportData(processedExcelData);
        setFormLoader(false);
      } catch (error: any) {
        setReportsData([]);
        setIsModalOpen(true);
        setHandleSubmit(false);
        message.destroy();
        // message.error("Error: " + (error?.message || ""));
        setFormLoader(false);
      } finally {
        setFormLoader(false);
        setHandleSubmit(false);
      }
    };

    if (handleSubmit) fetchData();
  }, [handleSubmit]);

  const handleChangeAreaList = (value: number) => {
    const matchingArea: any = arealist?.find((area: any) => area?.id === value);
    setAreaName(`${matchingArea?.AreaName}`);
    setAreaId(value);
  };

  const renderSelect = (key: string, value: any, handleChange: any, options: any) => {
    return (
      <Select
        getPopupContainer={(trigger) => trigger.parentElement}
        showSearch
        allowClear
        maxTagCount="responsive"
        dropdownStyle={{ overflowY: "auto" }}
        optionFilterProp="children"
        disabled={options.length === 0}
        placeholder={`Select ${key}`}
        style={{ width: 400 }}
        filterOption={(input: any, option: any) => {
          const children = option?.children as unknown;
          if (typeof children === "string") {
            return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        onChange={(newValue: any) => {
          handleChange(newValue);
        }}
        value={value}
      >
        <Select.Option value={[]}>{`Select ${key}`}</Select.Option>

        {/* Render other options */}
        {options?.map((option: any) => (
          <Select.Option value={option.id} key={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const columns: Column[] = [
    {
      align: "right",
      title: "SN",
      dataIndex: "key",
      key: "key",
      sorter: {
        compare: (a, b) => a?.key - b?.key
      },
      render: (text, record, index) => <div>{record.key + 1}</div>,
      width: 50
    },
    {
      align: "left",
      title: "Selected Area",
      dataIndex: "SelectedAreaName",
      key: "SelectedAreaName",
      sorter: {
        compare: (a, b) => a?.SelectedAreaName?.localeCompare(b?.SelectedAreaName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Scanned Tree Tag",
      dataIndex: "ScannedTreeTag",
      key: "ScannedTreeTag",
      sorter: {
        compare: (a, b) => a?.ScannedTreeTag?.localeCompare(b?.ScannedTreeTag)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Scanned Area",
      dataIndex: "ScannedAreaName",
      key: "ScannedAreaName",
      sorter: {
        compare: (a, b) => a?.ScannedAreaName?.localeCompare(b?.ScannedAreaName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Time Stamp",
      dataIndex: "DateAndTimeStamp",
      key: "DateAndTimeStamp",
      sorter: {
        compare: (a, b) => a?.DateAndTimeStamp?.localeCompare(b?.DateAndTimeStamp)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Add / Update",
      dataIndex: "IsAddORUpdate",
      key: "IsAddORUpdate",
      sorter: {
        compare: (a, b) => a?.IsAddORUpdate?.localeCompare(b?.IsAddORUpdate)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Reason",
      dataIndex: "Reason",
      key: "Reason",
      sorter: {
        compare: (a, b) => a?.Reason?.localeCompare(b?.Reason)
      },
      render: (text) => <div>{text}</div>
    }
  ];

  const disabledFromDate = (current: Moment | null): boolean => {
    return !!current && current > moment().endOf("day");
  };

  const disabledToDate = (current: Moment | null): boolean => {
    return !!current && !!fromDate && (current < moment(fromDate).startOf("day") || current > moment().endOf("day"));
  };

  const handleFromDateChange = (value: Moment | null) => {
    if (value) {
      setFromDate(value);

      // Update toDate if fromDate is later than current toDate
      if (value.isAfter(toDate)) {
        setToDate(moment(value).hours(23).minutes(45));
      }
    }
  };

  const handleToDateChange = (value: Moment | null) => {
    setToDate(value);
  };

  /* filters all function */

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const handleOptionSelection = (selectedOption: string) => {
    // Split the selectedOption to get the month and year separately
    const [monthOption, yearOption] = selectedOption.split(" - ");
    setFilterSelectOption(selectedOption);
    setSelectDate(0);
    if (selectedOption === "1") {
      setSelectDate(7);
    }

    const selectedMonthIndex = monthNames.findIndex((month) => month === monthOption);

    // Generate the fromDate and toDate strings in the format "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    const currentDate = moment();
    const currentMonth = moment().format("MMMM");

    if (currentMonth === monthOption) {
      setFromDate(
        currentDate
          .clone()
          .date(1) // Set the day to the 1st day of the month
          .month(selectedMonthIndex) // Set the month to the selected month index (0-based)
          .hour(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
      );

      setToDate(currentDate);
    } else {
      // Handle the case when the selected month is different from the current month
      const daysInSelectedMonth = moment(selectedOption, "MMMM").daysInMonth();
      setFromDate(currentDate.clone().month(selectedMonthIndex).year(+yearOption).date(1).hour(0).minutes(0).seconds(0).milliseconds(0));

      setToDate(currentDate.clone().month(selectedMonthIndex).year(+yearOption).date(daysInSelectedMonth));
    }
  };

  const handleLastSevenDay = () => {
    const currentDate = moment();
    const lastOneDay = moment().subtract(7, "day");

    setFromDate(lastOneDay.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(7);
  };

  const handleLastThirtyDay = () => {
    const currentDate = moment();
    const lastSevenDays = moment().subtract(30, "days");

    setFromDate(lastSevenDays.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(30);
  };

  const handleLastSixtyDay = () => {
    const currentDate = moment();
    const lastFifteenDays = moment().subtract(60, "days");

    setFromDate(lastFifteenDays.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(60);
  };

  const handleLastNinetyDay = () => {
    const currentDate = moment();
    const lastThirtyDays = moment().subtract(90, "days");

    setFromDate(lastThirtyDays.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(90);
  };

  const handleGo = () => {
    if (filterSelectOption === "1") {
      if (selectDate === 7) {
        handleLastSevenDay();
      } else if (selectDate === 30) {
        handleLastThirtyDay();
      } else if (selectDate === 60) {
        handleLastSixtyDay();
      } else if (selectDate === 90) {
        handleLastNinetyDay();
      }
    }
    setHandleSubmit(true);
  };

  const showFromDate = fromDate ? fromDate.format("DD-MM-YYYY") : "";
  const showToDate = toDate ? toDate.format("DD-MM-YYYY") : "";

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleChange = (pagination: TablePaginationConfig): void => {
    setPagination(pagination);
  };

  const exportToExcel = () => {
    const csvData = reportExportData || [];
    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Specify the type as "array" to avoid type error
    const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `Error_Log_${areaName}_${showFromDate}--${showToDate}.xlsx`);
  };

  return (
    <>
      <Card className="card--height">
        <CommonModal resetModal={handleOk} showModal={isModalOpen} modalMessage={"No data found for this selection."} title="Message" />
        <h3>Error Log</h3>
        <div className={styles["filter-container-filter1"]}>
          <div className={styles["filter-container-filter1-container"]}>
            <div style={{ marginTop: "7px" }}>{renderSelect("Area", areaId, handleChangeAreaList, arealist)}</div>
          </div>
        </div>
        <div className={styles["filter-container-filter2"]} style={{ marginBottom: "20px" }}>
          <div className={styles["filter-dropdown-container"]} style={{ width: navCollapsed ? "160px" : "200px" }}>
            <Dropdown onSelectOption={handleOptionSelection} />
          </div>
          {filterSelectOption === "1" ? (
            <div className={styles["filter-Lastdays"]}>
              <div
                // className={styles["filter-dashboard-option"]}
                // style={{
                //   backgroundColor: selectDate === 7 ? "#1860ab" : "",
                //   borderColor: selectDate === 7 ? "#1860ab" : "",
                //   color: selectDate === 7 ? "#fff" : "",
                //   cursor: "pointer",
                //   opacity: 1
                // }}
                className={`${styles["filter-dashboard-option"]} ${ selectDate === 7 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color:  selectDate === 7 ? "#fff" : "",
                  opacity: 1
                }}
                onClick={handleLastSevenDay}
              >
                7
              </div>
              <div
                // style={{
                //   backgroundColor: selectDate === 30 ? "#1860ab" : "",
                //   borderColor: selectDate === 30 ? "#1860ab" : "",
                //   color: selectDate === 30 ? "#fff" : "",
                //   cursor: "pointer",
                //   opacity: 1
                // }}
                // className={styles["filter-dashboard-option"]}
                className={`${styles["filter-dashboard-option"]} ${ selectDate === 30 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color:  selectDate === 30 ? "#fff" : "",
                  opacity: 1
                }}
                onClick={handleLastThirtyDay}
              >
                30
              </div>
              <div
                // style={{
                //   backgroundColor: selectDate === 60 ? "#1860ab" : "",
                //   borderColor: selectDate === 60 ? "#1860ab" : "",
                //   color: selectDate === 60 ? "#fff" : "",
                //   cursor: "pointer",
                //   opacity: 1
                // }}
                // className={styles["filter-dashboard-option"]}
                className={`${styles["filter-dashboard-option"]} ${ selectDate === 60 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color:  selectDate === 60 ? "#fff" : "",
                  opacity: 1
                }}
                onClick={handleLastSixtyDay}
              >
                60
              </div>
              <div
                // style={{
                //   backgroundColor: selectDate === 90 ? "#1860ab" : "",
                //   borderColor: selectDate === 90 ? "#1860ab" : "",
                //   color: selectDate === 90 ? "#fff" : "",
                //   cursor: "pointer",
                //   opacity: 1
                // }}
                // className={styles["filter-dashboard-option"]}
                className={`${styles["filter-dashboard-option"]} ${ selectDate === 90 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color:  selectDate === 90 ? "#fff" : "",
                  opacity: 1
                }}
                onClick={handleLastNinetyDay}
              >
                90
              </div>
            </div>
          ) : filterSelectOption === "2" ? (
            <div className={styles["filter-customDate"]}>
              <DatePicker style={{ width: "150px" }} format="DD-MM-YYYY" className={styles["datepicker"]} allowClear={false} disabledDate={disabledFromDate} value={fromDate} placeholder="Select Start Date" onChange={handleFromDateChange} />
              <DatePicker style={{ width: "150px" }} format="DD-MM-YYYY" className={styles["datepicker"]} allowClear={false} disabledDate={disabledToDate} value={toDate} placeholder="Select End Date" onChange={handleToDateChange} />
            </div>
          ) : (
            ""
          )}

          <div className={styles["filter-showDate-container"]}>
            <div className={styles["filter-showDate-container-1"]}>
              <span>From Date :</span>
              <span>{showFromDate}</span>
            </div>
            <div className={styles["filter-showDate-container-1"]}>
              <span>To Date :</span>
              <span>{showToDate}</span>
            </div>
          </div>

          <div className={styles["filterClick"]}>
            <Button type="primary" disabled={!areaId} style={{ marginLeft: "12px", display: "block", margin: "0 auto" }} onClick={handleGo}>
              Show Details
            </Button>
          </div>

          <div className={`${styles["trecords"]}`}>
            <h4>Total Records: {(reportsData && reportsData?.length) ?? 0}</h4>
          </div>
          <div className={`${styles["exportButton"]}`}>
            <Tooltip title="Download Report">
              {reportsData && reportsData?.length ? (
                <Button onClick={exportToExcel} className={styles["export-button-gradiant"]}>
                  Export
                </Button>
              ) : (
                <Button className={styles["export-button-gradiant-reset"]}>Export</Button>
              )}
            </Tooltip>
          </div>
        </div>

        {formLoader ? (
          <div className="loading loading--center">
            <Space direction="vertical" className="space--width" size="large">
              <Spin tip="Loading" size="large" />
            </Space>
          </div>
        ) : (
          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={reportsData || undefined}
            bordered
            onChange={handleChange}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              pageSizeOptions
            }}
          ></Table>
        )}
      </Card>
    </>
  );
};

export default Report;
