import React, { useState, useEffect, useRef, useCallback } from "react";
import { Select, Button, Card, Tag, Spin, Space, message, Input, Row, Col } from "antd";
import * as treeServices from "../../services/treeServices";
import * as reportService from "../../services/reportService";
import * as masterServices from "../../services/masterServices";
import NoImageAvailable from "../../assets/pictures/No_Image_Available.jpg";
import moment from "moment";
import { useHistory } from "react-router";
import styles from "./index.module.css";
import { OrgMasterModulesAndMapping, UserRoleDATA } from "../../constants/databaseConstants";
import * as orgmasterServices from "../../services/orgmasterServices";
import * as userService from "../../services/userServices";
import { ModuleAndIdMapping } from "../../constants/databaseConstants";
import CommonModal from "../Modal";
import { LocalStorageConstants } from "../../constants/localStorageConstants";

const IMAGE_BASE_URL = process.env.REACT_APP_API_URL?.split("/").slice(0, 3).join("/");

const TreeDetails: React.FC = () => {
  const filtersDataFromLocalStorage = localStorage.getItem("TreeDetailsFilters");
  const userToken: any = JSON.parse(localStorage.getItem(LocalStorageConstants.FORM) || "{}");

  const history = useHistory();
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [reportsData, setReportsData] = useState<any>();

  const [totalCount, setTotalCount] = useState<number | any>(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const loaderRef = useRef(null);

  const [projectlist, setProjectlist] = useState<number[]>([]);
  const [clusterlist, setClusterlist] = useState<number[]>([]);
  const [villagelist, setVillagelist] = useState<number[]>([]);
  const [arealist, setArealist] = useState<number[]>([]);
  const [farmerlist, setFarmerlist] = useState<number[]>([]);
  const [healthConditionlist, setHealthConditionlist] = useState<number[]>([]);

  const [projectId, setProjectId] = useState<number | null>(null);
  const [clusterId, setClusterId] = useState<number | null>(null);
  const [villageId, setVillageId] = useState<number | null>(null);
  const [farmerId, setFarmerId] = useState<number | null>(null);

  const [areaId, setAreaId] = useState<number[]>([]);

  const [healthConditionId, setHealthConditionId] = useState<number[] | []>([]);

  const [handleSubmit, setHandleSubmit] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActive, setIsactive] = useState<string>("");

  const [searchText, setSearchText] = useState<string>("");
  const [projectListCheck, setProjectListCheck] = useState(false);
  const [initialSelectedProjectId, setInitialSelectedProjectId] = useState<boolean>(false);

  const getDefaulthealthConditionIds = (healthConditionlist: any) => {
    if (!healthConditionlist || !Array.isArray(healthConditionlist)) {
      return [];
    }

    return healthConditionlist.map((item) => item.id);
  };

  const getDefaultAreaListIds = (arealist: any) => {
    if (!arealist || !Array.isArray(arealist)) {
      return [];
    }

    return arealist.map((item) => item.id);
  };

  /* health condition list */
  useEffect(() => {
    const fetchlist = async () => {
      try {
        setHealthConditionlist([]);
        const response = await masterServices.getOneMasterListById(TOKEN!, ModuleAndIdMapping.TREEHEALTH);

        const res =
          response &&
          response.AllRecordsList.map((item: any) => {
            return {
              name: item.TreeHealth,
              id: item.TreeHealthId
            };
          });

        setHealthConditionlist(res);
        // Check if healthConditionId exists in the stored filters
        if (filtersDataFromLocalStorage) {
          const filters = JSON.parse(filtersDataFromLocalStorage);
          if (filters.healthConditionId && filters.healthConditionId.length !== 0) {
            setHealthConditionId(filters.healthConditionId);
          } else {
            setHealthConditionId(getDefaulthealthConditionIds(res));
          }
        } else {
          setHealthConditionId(getDefaulthealthConditionIds(res));
        }
        setHandleSubmit(true);
        //setHealthConditionId(getDefaulthealthConditionIds(res));
      } catch (error: any) {
        setHealthConditionlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    fetchlist();
  }, [setHealthConditionId]);

  /* Fetching all Area Tables list when visited using select */
  useEffect(() => {
    const renderAreaList = async () => {
      try {
        setArealist([]);
        const response = await userService.getOneMasterAreaList(TOKEN!);

        const processedData: any = response.areaList.map((item: any) => {
          if (userToken?.RoleName === UserRoleDATA.FIELD_EXECUTIVE) {
            return { id: item?.AreaId, name: `${item?.Project.ProjectName} >> ${item?.Cluster.ClusterName} >> ${item?.Village.VillageName} >> ${item?.AreaName}` };
          } else {
            return { id: item?.AreaId, name: item?.AreaName };
          }
        });

        setArealist(processedData);
        handleChangeAreaList(getDefaultAreaListIds(processedData));
        setHandleSubmit(true);
      } catch (error: any) {
        setArealist([]);

        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    if (userToken?.RoleName === UserRoleDATA.FIELD_EXECUTIVE) renderAreaList();
  }, []);

  const handleFinish = (treeId: any) => {
    history.push(`/timeline/${treeId}`);
  };

  // infinite-scrolling
  useEffect(() => {
    // Load filters data from LocalStorage on component mount
    if (filtersDataFromLocalStorage) {
      const filters = JSON.parse(filtersDataFromLocalStorage);
      // Now you can use the filters object to set your state valu
      setProjectId(filters?.projectId);
      setClusterId(filters?.clusterId);
      setVillageId(filters?.villageId);
      setFarmerId(filters?.farmerId);
      setAreaId(filters?.areaId);
      setHealthConditionId(filters?.healthConditionId);
      setTotalCount(filters?.totalCount);
      setSearchText(filters?.searchText);
    }
  }, [filtersDataFromLocalStorage]);

  const loadMoreData = useCallback(async () => {
    try {
      const totalPages = Math.ceil(totalCount / pageSize);

      if (currentPage > totalPages) {
        setTotalRecords(totalCount + 1);
        // Don't load more data if the current page exceeds total pages
        return;
      }

      const response = await treeServices.getTreeListFilterAPI(TOKEN, currentPage, pageSize, projectId === null ? [] : Array.isArray(projectId) ? projectId : [projectId], clusterId === null ? [] : Array.isArray(clusterId) ? clusterId : [clusterId], villageId === null ? [] : Array.isArray(villageId) ? villageId : [villageId], farmerId === null ? [] : Array.isArray(farmerId) ? farmerId : [farmerId], areaId, healthConditionId, searchText);
      const fetchedNewItems = response.treeList || [];

      setReportsData((prevData: any) => {
        return [...prevData, ...fetchedNewItems];
      });

      setCurrentPage((prevPage) => prevPage + 1);
    } catch (error: any) {
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  }, [currentPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          // Load more data when the loader is visible
          loadMoreData();
        }
      },
      {
        threshold: 0.5 // Change this threshold value as needed
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, [loadMoreData]);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        setRefresh(true);
        setReportsData([]);
        setPageSize(10);
        setCurrentPage(1);
        setTotalCount(0);
        setTotalRecords(0);

        if (userToken?.RoleName === UserRoleDATA.CSR_PARTNER && !projectId!) {
          message.destroy();
          message.error("Project is not assigned.Please contact the administrator");
          setLoading(false);
          setRefresh(false);
          return;
        }

        const Filters = {
          projectId,
          clusterId,
          villageId,
          farmerId,
          areaId,
          healthConditionId,
          totalCount,
          searchText
        };

        localStorage.setItem("TreeDetailsFilters", JSON.stringify(Filters));

        const response: any = await treeServices.getTreeListFilterAPI(TOKEN, currentPage, pageSize, projectId === null ? [] : Array.isArray(projectId) ? projectId : [projectId], clusterId === null ? [] : Array.isArray(clusterId) ? clusterId : [clusterId], villageId === null ? [] : Array.isArray(villageId) ? villageId : [villageId], farmerId === null ? [] : Array.isArray(farmerId) ? farmerId : [farmerId], areaId, healthConditionId, searchText);

        setReportsData(
          response.treeList // Set treeList directly from the response
        );
        setTotalCount(response?.treeListTotalCount);
        setRefresh(false);
        setHandleSubmit(false);
        setCurrentPage((prevPage) => prevPage + 1);
        setRefresh(false);
      } catch (error: any) {
        message.destroy();
        // message.error("Error: " + (error?.message || ""));
        setIsModalOpen(true);
        setTotalCount(0);
        setReportsData([]);
        setLoading(false);
        setRefresh(false);
        setHandleSubmit(false);
      } finally {
        setLoading(false);
        setRefresh(false);
      }
    };

    if (userToken?.RoleName === UserRoleDATA.FIELD_EXECUTIVE && handleSubmit && arealist.length > 0 && healthConditionlist.length > 0) {
      fetchData1();
    } else if (handleSubmit && userToken?.RoleName !== UserRoleDATA.FIELD_EXECUTIVE && userToken?.RoleName !== UserRoleDATA.CSR_PARTNER) {
      fetchData1();
    } else if (handleSubmit && userToken?.RoleName === UserRoleDATA.CSR_PARTNER && projectListCheck) {
      fetchData1();
    }
  }, [handleSubmit, projectListCheck]);

  /* project list */
  useEffect(() => {
    const fetchlist = async () => {
      try {
        setProjectlist([]);
        const response = await orgmasterServices.getOneOrgMasterListById(TOKEN!, OrgMasterModulesAndMapping.PROJECT, isActive);

        const res = response.AllRecordsList.map((item: any) => {
          return {
            ...item,
            key: item.ProjectId,
            name: item.ProjectName,
            id: item.ProjectId
          };
        });
        if (res.length > 0) {
          setProjectlist(res);
          if (userToken?.RoleName === UserRoleDATA.CSR_PARTNER) {
            setProjectId(res[0].ProjectId);
            setProjectListCheck(true);
            setHandleSubmit(true);
          }
        } else {
          setProjectlist([]);
          if (userToken?.RoleName === UserRoleDATA.CSR_PARTNER) {
            setProjectListCheck(false);
            setHandleSubmit(false);
            setLoading(false);
            setRefresh(false);
            message.destroy();
            message.error("Project is not assigned.Please contact the administrator");
          }
        }
      } catch (error: any) {
        setProjectlist([]);
        setLoading(false);
        setRefresh(false);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    fetchlist();
  }, []);

  /* cluster list */
  useEffect(() => {
    const renderList = async () => {
      if (!projectId || projectId === 0) {
        return;
      }
      try {
        setClusterlist([]);
        const response = await orgmasterServices.getOneOrgMasterListByClusterId(TOKEN!, projectId, isActive);

        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.ClusterId,
              name: item.ClusterName,
              id: item.ClusterId
            };
          });
        if (res.length > 0) {
          setClusterlist(res);
        } else {
          setClusterlist([]);
        }
      } catch (error: any) {
        setClusterlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (projectId) {
        await renderList();
      }
    };
    fetchList();
  }, [projectId]);

  /* village list */
  useEffect(() => {
    const renderList = async () => {
      if (!clusterId || clusterId === 0) {
        return;
      }
      try {
        setVillagelist([]);
        setFarmerlist([]);
        const response = await orgmasterServices.getOneOrgMasterListByVillageId(TOKEN!, clusterId, isActive);
        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.VillageId,
              name: item.VillageName,
              id: item.VillageId
            };
          });
        if (res.length > 0) {
          setVillagelist(res);
        } else {
          setVillagelist([]);
        }
      } catch (error: any) {
        setVillagelist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (clusterId) {
        await renderList();
      }
    };
    fetchList();
  }, [clusterId]);

  /* area list */
  useEffect(() => {
    const renderList = async () => {
      if (!villageId || villageId === 0) {
        return;
      }
      try {
        setFarmerlist([]);
        setArealist([]);
        const response = await reportService.getOneOrgMasterListByAreaId(TOKEN!, villageId, isActive);

        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.AreaId,
              name: item.AreaName,
              id: item.AreaId
            };
          });
        if (res.length > 0) {
          setArealist(res);
        } else {
          setArealist([]);
        }
      } catch (error: any) {
        setArealist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (villageId) {
        await renderList();
      }
    };
    fetchList();
  }, [villageId]);

  /* farmer list */
  useEffect(() => {
    const renderList = async () => {
      if (!villageId || villageId === 0) {
        return;
      }
      try {
        setFarmerlist([]);
        const response = await reportService.getFarmerListByVillageId(TOKEN!, villageId, isActive);

        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.FarmerId,
              name: item.FarmerName,
              id: item.FarmerId
            };
          });
        if (res.length > 0) {
          setFarmerlist(res);
        } else {
          setFarmerlist([]);
        }
      } catch (error: any) {
        setFarmerlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (villageId) {
        await renderList();
      }
    };
    fetchList();
  }, [villageId]);

  const handleGo = () => {
    setHandleSubmit(true);
    setReportsData([]);
    setPageSize(10);
    setCurrentPage(1);
    setTotalCount(0);
  };

  const handleChangeProject = (value: number) => {
    setProjectId(value);
    setClusterlist([]);
    setVillagelist([]);
    setArealist([]);
    setClusterId(null);
    setVillageId(null);
    setFarmerId(null);
    setFarmerlist([]);
    setAreaId([]);
    setHandleSubmit(false);
  };
  const handleChangeCluster = (value: number) => {
    setClusterId(value);
    setVillagelist([]);
    setArealist([]);
    setVillageId(null);
    setFarmerId(null);
    setFarmerlist([]);
    setAreaId([]);
    setHandleSubmit(false);
  };
  const handleChangeVillage = (value: number) => {
    setVillageId(value);
    setFarmerlist([]);
    setFarmerId(null);
    setArealist([]);
    setAreaId([]);
    setHandleSubmit(false);
  };
  const handleChangeFarmer = (value: number) => {
    setFarmerId(value);
    setHandleSubmit(false);
  };
  const handleChangeArea = (value: number[]) => {
    setAreaId(value);
    setHandleSubmit(false);
  };

  const handleChangeAreaList = (value: number[]) => {
    setAreaId(value);
    setHandleSubmit(false);
  };

  const handleChangeHealthCondition = (value: number[]) => {
    setHealthConditionId(value);
    setHandleSubmit(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const renderSelect = (key: string, value: any, handleChange: any, options: any) => {
    const selectAllOption = { id: "all", name: "Select All" };
    const unselectAllOption = { id: "none", name: "Unselect All" };

    // Function to handle "Select All" click
    const handleSelectAll = () => {
      const allOptionIds = options.map((option: any) => option.id);
      handleChange([...allOptionIds]);
    };

    // Function to handle "Unselect All" click
    const handleUnselectAll = () => {
      handleChange([]);
    };

    const isSelectAllSelected = Array.isArray(value) && value.includes(selectAllOption.id);

    // If "Select All" is selected, update value to include all option IDs
    const updatedValue = isSelectAllSelected ? options.map((option: any) => option.id) : value;

    return (
      <Select
        getPopupContainer={(trigger) => trigger.parentElement}
        showSearch
        allowClear
        maxTagCount="responsive"
        dropdownStyle={{ overflowY: "auto" }}
        mode={key === "Area" || key === "Health Condition" ? "multiple" : undefined}
        optionFilterProp="children"
        disabled={options.length === 0}
        // className={`${styles["input-dropdown"]}`}
        placeholder={`Select ${key}`}
        // style={{ minWidth: "100%", width: userToken?.RoleId === 3 ? "7%" : 190 }}
        style={{ width: "100%" }}
        filterOption={(input: any, option: any) => {
          const children = option?.children as unknown;
          if (typeof children === "string") {
            return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        onChange={(newValue: any) => {
          if (key === "Area" || key === "Health Condition") {
            if (Array.isArray(newValue) && newValue.includes(selectAllOption.id)) {
              handleSelectAll();
            } else if (Array.isArray(newValue) && newValue.includes(unselectAllOption.id)) {
              handleUnselectAll();
            } else {
              handleChange(Array.isArray(newValue) ? newValue.filter((id: any) => id !== selectAllOption.id && id !== unselectAllOption.id) : []);
            }
          } else {
            handleChange(newValue);
          }
        }}
        value={key === "Area" || key === "Health Condition" ? updatedValue : value}
      >
        {/* Add the "Select All" option */}
        {key === "Area" || key === "Health Condition" ? (
          <Select.Option value={selectAllOption.id} key={selectAllOption.id}>
            {selectAllOption.name}
          </Select.Option>
        ) : null}

        {/* Add the "Unselect All" option */}
        {/* Add the "Placeholder option" option */}
        {key === "Area" || key === "Health Condition" ? (
          <Select.Option value={unselectAllOption.id} key={unselectAllOption.id}>
            {unselectAllOption.name}
          </Select.Option>
        ) : (
          (userToken?.RoleName === UserRoleDATA.ORGANIZATION_ADMIN || userToken?.RoleName === UserRoleDATA.FIELD_EXECUTIVE) && <Select.Option value={null}>{`Select ${key}`}</Select.Option>
        )}

        {/* Render other options */}
        {options?.map((option: any) => (
          <Select.Option value={option.id} key={option.id}>
            {option.name}
            {/* areaList[0].Project.ProjectName   ${item?.Project?.ProjectName} >>  */}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const handleSearch = async (value: string) => {
    setSearchText(value);
  };

  const handleShowMap = () => {
    const Filters = {
      projectId,
      clusterId,
      villageId,
      farmerId,
      areaId,
      healthConditionId,
      totalCount,
      searchText
    };

    localStorage.setItem("TreeDetailsFilters", JSON.stringify(Filters));

    history.push("/tree-locations");
  };

  return (
    <div>
      <CommonModal resetModal={handleOk} showModal={isModalOpen} modalMessage={"No data found for this selection."} title="Message" />
      {loading ? (
        <div className="loading loading--center">
          <Space direction="vertical" className="space--width" size="large">
            <Spin tip="Loading" size="large"></Spin>
          </Space>
        </div>
      ) : (
        <Card title="Tree Details" className="card--height">
          <div className={`${styles["div-container"]}`} style={{ marginTop: "30px", marginRight: "30%" }}>
            <div className="card-list-data-wrapper">
              <div className="card-list-data-container">
                <Input.Search
                  placeholder="Search Tree Name or Tree Tag"
                  onSearch={(value) => {
                    handleSearch(value);
                    handleGo();
                  }}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  value={searchText}
                  allowClear
                  style={{ width: "30%", marginRight: "2%" }}
                />
                {userToken?.RoleName !== UserRoleDATA.FIELD_EXECUTIVE ? (
                  <div style={{ marginTop: "6px", marginRight: "3%" }}>
                    <h4 style={{ display: "block" }}>Total Records: {totalCount}</h4>
                  </div>
                ) : (
                  <div style={{ marginTop: "6px", marginRight: "3%" }}>
                    <h4>Total Records: {totalCount}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "20px", marginLeft: "5px" }}>
            <div>
              <div>
                <div>
                  {userToken?.RoleName !== UserRoleDATA.FIELD_EXECUTIVE ? (
                    <>
                      <Row gutter={24}>
                        <Col span={3}>
                          <div>{renderSelect("Project", projectId, handleChangeProject, projectlist)}</div>
                        </Col>
                        <Col span={3}>
                          <div>{renderSelect("Cluster", clusterId, handleChangeCluster, clusterlist)}</div>
                        </Col>
                        <Col span={3}>
                          <div>{renderSelect("Village", villageId, handleChangeVillage, villagelist)}</div>
                        </Col>
                        <Col span={3}>
                          <div>{renderSelect("Area", areaId, handleChangeArea, arealist)}</div>
                        </Col>
                        <Col span={3}>
                          <div>{renderSelect("Farmer", farmerId, handleChangeFarmer, farmerlist)}</div>
                        </Col>
                        <Col span={3}>
                          <div>{renderSelect("Health Condition", healthConditionId, handleChangeHealthCondition, healthConditionlist)}</div>
                        </Col>

                        <Col span={4}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Button type="primary" className={`${styles["filter-handleGO"]}`} onClick={handleGo}>
                              Show Details
                            </Button>
                            <Button type="primary" className={`${styles["filter-handleGO"]}`} onClick={handleShowMap}>
                              Show On Map
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row gutter={24}>
                        <Col span={9}>
                          <div>{renderSelect("Area", areaId, handleChangeAreaList, arealist)}</div>
                        </Col>
                        <Col span={7}>
                          <div>{renderSelect("Health Condition", healthConditionId, handleChangeHealthCondition, healthConditionlist)}</div>
                        </Col>
                        <Col span={3}>
                          <div>
                            <Button type="primary" style={{ marginTop: "12px", display: "block", margin: "0 auto" }} className={`${styles["filter-handleGO"]} `} onClick={handleGo}>
                              Show Details
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <br />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {userToken?.RoleName !== UserRoleDATA.FIELD_EXECUTIVE ? (
            <>
              {refresh ? (
                <div className="loading loading--center">
                  <Space direction="vertical" className="space--width" size="large">
                    <Spin tip="Loading" size="large"></Spin>
                  </Space>
                </div>
              ) : (
                <>
                  {reportsData &&
                    reportsData.map((item: any, index: number) => (
                      <div className="gx-media gx-featured-item" style={{ padding: "5px", marginBottom: "5px" }}>
                        <div className="gx-featured-thumb">
                          <div style={{ position: "relative" }}>
                            <img
                              className="gx-rounded-lg gx-pointer"
                              src={`${IMAGE_BASE_URL}${item?.TreeTransaction[0]?.DocumentPath[0]}`}
                              alt="No img Available"
                              height="200px"
                              width="210px"
                              onError={(e: any) => {
                                e.target.src = NoImageAvailable; // Replace with the URL of your fallback image
                              }}
                              onClick={() => {
                                handleFinish(item?.TreeId);
                              }}
                            />
                          </div>
                        </div>
                        <div className="gx-media-body gx-featured-content">
                          <div className="gx-featured-content-left">
                            {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth === "GOOD" ? (
                              <Tag className="gx-rounded-xs" color="#64e764">
                                {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth}
                              </Tag>
                            ) : item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth === "NEED-ATTENTION" ? (
                              <Tag className="gx-rounded-xs" color="#F7B239">
                                {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth}
                              </Tag>
                            ) : item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth === "VERY GOOD" ? (
                              <Tag className="gx-rounded-xs" color="#4CB706">
                                {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth}
                              </Tag>
                            ) : item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth === "DEAD" ? (
                              <Tag className="gx-rounded-xs" color="#F75039">
                                {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth}
                              </Tag>
                            ) : (
                              <Tag className="gx-rounded-xs" color="#B6FA8A">
                                {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth}
                              </Tag>
                            )}
                            <h3 className="gx-mb-2" style={{ width: "400px" }}>
                              Name: &nbsp;
                              <span
                                onClick={() => {
                                  handleFinish(item?.TreeId);
                                }}
                                className="gx-pointer"
                              >
                                {item?.TreeBotanicalName.TreeGeneralName} ({item?.TreeBotanicalName.BotanicalName})
                              </span>
                            </h3>

                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "120px" }}>Height</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                                : {item?.TreeTransaction[0].HeightInCm} cm
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "120px" }}>Girth</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                                : {item?.TreeTransaction[0]?.GirthInCm ?? 0} cm
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "120px" }}>Latitude</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                                : {item?.Latitude}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "120px" }}>Longitude</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                                : {item?.Longitude}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "200px" }}>Farmer</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Farmer?.FarmerName ?? "NA"}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "120px" }}>Last Updated on</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                                : {moment(item?.TreeTransaction[0]?.TrackingDate).format("DD-MM-YYYY")}
                              </span>
                            </p>
                          </div>
                          <div className="gx-featured-content-left" style={{ marginTop: "60px", marginLeft: "20px" }}>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "150px" }}>Area</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Area.AreaName}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "150px" }}>Village</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Area.Village.VillageName}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "150px" }}>Cluster</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Area.Cluster.ClusterName}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "150px" }}>Project</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Area.Project.ProjectName}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "150px" }}>Care Taker</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Caretaker.CaretakerName}
                              </span>
                            </p>
                          </div>
                          <div className="gx-featured-content-right">
                            <div>
                              <h2
                                className="gx-text-primary gx-mb-1 gx-font-weight-medium gx-pointer"
                                onClick={() => {
                                  handleFinish(item?.TreeId);
                                }}
                              >
                                {item?.TreeTag}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {totalRecords < totalCount ? (
                    <div ref={loaderRef} style={{ textAlign: "center", marginTop: "40px" }}>
                      <Spin tip="Loading more..." />
                    </div>
                  ) : totalCount ? (
                    <p style={{ textAlign: "center", marginTop: "40px" }}>No more updates</p>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {refresh ? (
                <div className="loading loading--center">
                  <Space direction="vertical" className="space--width" size="large">
                    <Spin tip="Loading" size="large"></Spin>
                  </Space>
                </div>
              ) : (
                <>
                  {reportsData &&
                    reportsData.map((item: any, index: number) => (
                      <div className="gx-media gx-featured-item" style={{ padding: "5px", marginBottom: "5px" }}>
                        <div className="gx-featured-thumb">
                          <div style={{ position: "relative" }}>
                            <img
                              className="gx-rounded-lg gx-pointer"
                              src={`${IMAGE_BASE_URL}${item?.TreeTransaction[0]?.DocumentPath[0]}`}
                              alt="No img Available"
                              height="200px"
                              width="210px"
                              onError={(e: any) => {
                                e.target.src = NoImageAvailable; // Replace with the URL of your fallback image
                              }}
                              onClick={() => {
                                handleFinish(item?.TreeId);
                              }}
                            />
                          </div>
                        </div>
                        <div className="gx-media-body gx-featured-content">
                          <div className="gx-featured-content-left">
                            {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth === "GOOD" ? (
                              <Tag className="gx-rounded-xs" color="#64e764">
                                {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth}
                              </Tag>
                            ) : item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth === "NEED-ATTENTION" ? (
                              <Tag className="gx-rounded-xs" color="#F7B239">
                                {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth}
                              </Tag>
                            ) : item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth === "VERY GOOD" ? (
                              <Tag className="gx-rounded-xs" color="#4CB706">
                                {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth}
                              </Tag>
                            ) : item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth === "DEAD" ? (
                              <Tag className="gx-rounded-xs" color="#F75039">
                                {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth}
                              </Tag>
                            ) : (
                              <Tag className="gx-rounded-xs" color="#B6FA8A">
                                {item?.TreeTransaction[0]?.TreeHealthCondition?.TreeHealth}
                              </Tag>
                            )}
                            <h3 className="gx-mb-2" style={{ width: "350px" }}>
                              Name: &nbsp;
                              <span
                                onClick={() => {
                                  handleFinish(item?.TreeId);
                                }}
                                className="gx-pointer"
                              >
                                {item?.TreeBotanicalName.TreeGeneralName} ({item?.TreeBotanicalName.BotanicalName})
                              </span>
                            </h3>

                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "120px" }}>Height</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                                : {item?.TreeTransaction[0].HeightInCm} cm
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "120px" }}>Girth</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                                : {item?.TreeTransaction[0]?.GirthInCm ?? 0} cm
                              </span>
                            </p>

                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "120px" }}>Latitude</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                                : {item?.Latitude}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "120px" }}>Longitude</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                                : {item?.Longitude}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "200px" }}>Farmer</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Farmer?.FarmerName ?? "NA"}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "120px" }}>Last Updated on</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                                : {moment(item?.TreeTransaction[0]?.TrackingDate).format("DD-MM-YYYY")}
                              </span>
                            </p>
                          </div>
                          <div className="gx-featured-content-left" style={{ marginTop: "60px", marginLeft: "20px", flex: 1 }}>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "150px" }}>Area</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Area.AreaName}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "150px" }}>Village</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Area.Village.VillageName}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "150px" }}>Cluster</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Area.Cluster.ClusterName}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "150px" }}>Project</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Area.Project.ProjectName}
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                              <span style={{ width: "150px" }}>Care Taker</span>
                              <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                                : {item?.Caretaker.CaretakerName}
                              </span>
                            </p>
                          </div>

                          <div className="gx-featured-content-right">
                            <div>
                              <h2
                                className="gx-text-primary gx-mb-1 gx-font-weight-medium gx-pointer"
                                onClick={() => {
                                  handleFinish(item?.TreeId);
                                }}
                              >
                                {item?.TreeTag}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {totalRecords < totalCount ? (
                    <div ref={loaderRef} style={{ textAlign: "center", marginTop: "40px" }}>
                      <Spin tip="Loading more..." />
                    </div>
                  ) : totalCount ? (
                    <p style={{ textAlign: "center", marginTop: "40px" }}>No more updates</p>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}
        </Card>
      )}
    </div>
  );
};

export default TreeDetails;
