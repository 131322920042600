/* modules or screens which user has access to */

const enum UserRoleDATA {
  SUPER_ADMIN = "superadmin",
  ORGANIZATION_ADMIN = "organizationadmin",
  FIELD_EXECUTIVE = "fieldexecutive",
  CSR_PARTNER = "csrpartner"
}

const allowedRoles = [UserRoleDATA.FIELD_EXECUTIVE, UserRoleDATA.ORGANIZATION_ADMIN, UserRoleDATA.SUPER_ADMIN, UserRoleDATA.CSR_PARTNER];

const enum Modules {
  USERROLE = "User Role",
  COUNTRY = "Country",
  STATE = "State",
  TREEBOTANICAL = "Tree Botanical Name",
  TREEHEALTH = "Tree Health Condition"
}

const enum ModuleAndIdMapping {
  USERROLE = 1,
  COUNTRY = 2,
  STATE = 3,
  TREEBOTANICAL = 4,
  TREEHEALTH = 5
}

const enum OrganizationMasterModules {
  PROJECT = "Project",
  CLUSTER = "Cluster",
  VILLAGE = "Village",
  AREA = "Area",
  CARETAKER = "CareTaker"
}

const enum OrgMasterModulesAndMapping {
  PROJECT = 1,
  CLUSTER = 2,
  VILLAGE = 3,
  AREA = 4,
  CARETAKER = 5,
  FARMER = 6
}

const enum AccessModules {
  COMMON_MASTER = "Common Master Management",
  ORG_MANAGEMENT = "Organization Management",
  ORG_MASTER_MANAGEMENT = "Organization Master Management",
  USER = "User",
  TREE = "Tree",
  TIMELINE = "TimeLine",
  REPORT = "Report",
  ERROR_LOG = "ErrorLog"
}

const enum AccessModulesAndMapping {
  COMMONMASTER = 1,
  ORGMANAGEMENT = 2,
  USER = 3,
  ORGMASTER = 4
}

export { allowedRoles, UserRoleDATA, Modules, ModuleAndIdMapping, AccessModulesAndMapping, AccessModules, OrganizationMasterModules, OrgMasterModulesAndMapping };
