import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

interface DataPoint {
  name: string;
  value: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ payload: DataPoint }>;
}

interface PieChartDataItem {
  name: string;
  value: number | string;
  percentage: number | string;
}

interface PieChartData {
  pieChartData: PieChartDataItem[];
}

/* custom tool tip */
const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip" style={{ border: "1px solid grey", background: "rgba(255, 255, 255, 0.9)", color: "#1860ab", textAlign: "center", padding: "9px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "5px" }}>
        <p className="label" style={{ margin: 0 }}>{`${data.name}: ${data.value}`}</p>
        {/* <p className="label" style={{ margin: 0 }}>{`${data.name}: ${numberWithCommas(data.value)}`}</p> */}
      </div>
    );
  }
  return null;
};

const COLORS = ["#64e764", "#4CB706", "#F7B239", "#F75039"];

const SimplePieChart: React.FC<PieChartData> = ({ pieChartData }) => {
  return (
    <div>
      <ResponsiveContainer width="100%" height={230}>
        <PieChart>
          <Tooltip content={<CustomTooltip />} />

          <text x="50%" className="h3" y="50%" textAnchor="middle" dominantBaseline="middle">
            {pieChartData && pieChartData[0].value === 0 && pieChartData[1].value === 0 && pieChartData[2].value === 0 && pieChartData[3].value === 0 ? "No data available!" : ""}
          </text>

          <Pie data={pieChartData} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={0} outerRadius={79} fill="#8884d8" label={({ percentage }) => ` ${percentage.toFixed(2)} %`}>
            {pieChartData?.map((entry: any, index: number) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SimplePieChart;
