import "./circularProgressBar.css";
const CircularProgressBar = () => {
  return (
    <div className="circularProgressbar-container">
      <div className="circularProgressbar"></div>
    </div>
  );
};

export default CircularProgressBar;
