import { Row, Col, Card, Tag } from "antd";
import styles from "../index.module.css";
import ChartCard from "../ChartCard";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { citiesData, propertiesData, visitsData } from "../ChartCard/data";
import SimplePieChart from "../PieChart";
import SimpleLineChart from "../LineChart";
import StackedBarChart from "../BarChart";
import numberWithCommas from "../../../util/numberWithCommas";

const SuperAdminDashboard = ({ dashboardCards, pieChartData, lineChartData, barChartData }: any) => {
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Row gutter={[16, 16]} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Col lg={1.5} md={1.5}></Col>
          <Col lg={7} md={7} sm={24} xs={24}>
            <ChartCard
              chartProperties={{
                title: "Total Organizations",
                prize: numberWithCommas(dashboardCards?.totalOrganization ?? 0),
                icon: "stats",
                bgColor: "primary",
                styleName: "up",
                desc: "",
                percent: ""
              }}
              children={
                <ResponsiveContainer width="100%" height={55}>
                  <AreaChart data={propertiesData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area dataKey="properties" strokeWidth={0} stackId="2" stroke="#092453" fill="#092453" fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
          <Col lg={1} md={1}></Col>
          <Col lg={7} md={7} sm={24} xs={24}>
            <ChartCard
              chartProperties={{
                title: "Total Trees",
                prize: numberWithCommas(dashboardCards?.totalTreeCount ?? 0),
                icon: "stats",
                bgColor: "orange",
                styleName: "up",
                desc: "",
                percent: ""
              }}
              children={
                <ResponsiveContainer width="100%" height={55}>
                  <AreaChart data={citiesData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area dataKey="cities" type="monotone" strokeWidth={0} stackId="2" stroke="#C85000" fill="#C85000" fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
          <Col lg={1} md={1}></Col>
          <Col lg={7} md={7} sm={24} xs={24}>
            <ChartCard
              chartProperties={{
                title: "Total Projects",
                prize: numberWithCommas(dashboardCards?.totalProjectCount ?? 0),
                icon: "stats",
                bgColor: "teal",
                styleName: "down",
                desc: "",
                percent: ""
              }}
              children={
                <ResponsiveContainer width="100%" height={55}>
                  <AreaChart data={visitsData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area dataKey="visit" strokeWidth={0} stackId="2" stroke="#158565" fill="#158565" fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
          <Col lg={1.5} md={1.5}></Col>
        </Row>
        <Row gutter={[16, 16]} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Col lg={1.5} md={1.5}></Col>
          <Col lg={7} md={7} sm={24} xs={24}>
            <ChartCard
              chartProperties={{
                title: "Total Clusters",
                prize: numberWithCommas(dashboardCards?.totalClusterCount ?? 0),
                icon: "stats",
                bgColor: "pink",
                styleName: "up",
                desc: "",
                percent: ""
              }}
              children={
                <ResponsiveContainer width="100%" height={55}>
                  <AreaChart data={propertiesData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area dataKey="properties" strokeWidth={0} stackId="2" stroke="#092453" fill="#092453" fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
          <Col lg={1} md={1}></Col>
          <Col lg={7} md={7} sm={24} xs={24}>
            <ChartCard
              chartProperties={{
                title: "Total Villages",
                prize: numberWithCommas(dashboardCards?.totalVillageCount ?? 0),
                icon: "stats",
                bgColor: "green",
                styleName: "up",
                desc: "",
                percent: ""
              }}
              children={
                <ResponsiveContainer width="100%" height={55}>
                  <AreaChart data={citiesData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area dataKey="cities" type="monotone" strokeWidth={0} stackId="2" stroke="#C85000" fill="#C85000" fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
          <Col lg={1} md={1}></Col>
          <Col lg={7} md={7} sm={24} xs={24}>
            <ChartCard
              chartProperties={{
                title: "Total Areas",
                prize: numberWithCommas(dashboardCards?.totalAreaCount ?? 0),
                icon: "stats",
                bgColor: "blue",
                styleName: "down",
                desc: "",
                percent: ""
              }}
              children={
                <ResponsiveContainer width="100%" height={55}>
                  <AreaChart data={visitsData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area dataKey="visit" strokeWidth={0} stackId="2" stroke="#158565" fill="#158565" fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
          <Col lg={1.5} md={1.5}></Col>
        </Row>
      </div>
      <div>
        <Row gutter={[16, 16]}>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Card className="gx-card">
              <div style={{ marginBottom: "5px" }} className={styles["piechart-container"]}>
                <Tag className="gx-rounded-xs" color="#4CB506">
                  VERY GOOD
                </Tag>

                <Tag className="gx-rounded-xs" color="#64e564">
                  GOOD
                </Tag>

                <Tag className="gx-rounded-xs" color="#F5B239">
                  NEED-ATTENTION
                </Tag>

                <Tag className="gx-rounded-xs" color="#F55039">
                  DEAD
                </Tag>
              </div>
              <SimplePieChart pieChartData={pieChartData} />
              <div style={{ marginTop: "5px" }} className={styles["piechart-container"]}>
                <h3>Health Status</h3>
              </div>
            </Card>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Card className="gx-card">
              <SimpleLineChart lineChartData={lineChartData} />
              <div style={{ paddingTop: "10px" }} className={styles["linechart-container"]}>
                <h3>Plantation</h3>
              </div>
            </Card>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Card className="gx-card">
              <StackedBarChart barChartData={barChartData} />
              <div className={styles["linechart-container"]}>
                <h3>Top-10 Trees Planted</h3>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: "20px" }}>
        <Row gutter={[16, 16]} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Col lg={2} md={1.5}></Col>
          <Col lg={9} md={9} sm={24} xs={24}>
            <ChartCard
              chartProperties={{
                title: "Total Org Admin",
                prize: numberWithCommas(dashboardCards?.organizationAdminCount ?? 0),
                icon: "stats",
                bgColor: "primary",
                styleName: "up",
                desc: "",
                percent: ""
              }}
              children={
                <ResponsiveContainer width="100%" height={55}>
                  <AreaChart data={propertiesData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area dataKey="properties" strokeWidth={0} stackId="2" stroke="#092453" fill="#092453" fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
          <Col lg={2} md={1.5}></Col>
          <Col lg={9} md={9} sm={24} xs={24}>
            <ChartCard
              chartProperties={{
                title: "Total Field Executive",
                prize: numberWithCommas(dashboardCards?.fieldExecutiveCount ?? 0),
                icon: "stats",
                bgColor: "orange",
                styleName: "up",
                desc: "",
                percent: ""
              }}
              children={
                <ResponsiveContainer width="100%" height={55}>
                  <AreaChart data={citiesData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Tooltip />
                    <Area dataKey="cities" type="monotone" strokeWidth={0} stackId="2" stroke="#C85000" fill="#C85000" fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
              }
            />
          </Col>
          <Col lg={2} md={1.5}></Col>
        </Row>
      </div>
    </>
  );
};

export default SuperAdminDashboard;
