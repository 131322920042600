export const growthData = [
  { name: "Page A", price: 1200 },
  { name: "Page B", price: 600 },
  { name: "Page C", price: 1200 },
  { name: "Page D", price: 600 },
  { name: "Page D", price: 900 }
];
export const growth2Data = [
  { name: "Page A", revenue: 1200 },
  { name: "Page B", revenue: 600 },
  { name: "Page C", revenue: 1200 },
  { name: "Page D", revenue: 600 },
  { name: "Page D", revenue: 900 }
];

export const trafficData = [
  { name: "Page A", income: 200 },
  { name: "Page B", income: 900 },
  { name: "Page C", income: 600 },
  { name: "Page D", income: 1600 },
  { name: "Page D", income: 900 }
];

export const trafficRaiseData = [
  { name: "Page A", traffic: 200 },
  { name: "Page B", traffic: 900 },
  { name: "Page C", traffic: 600 },
  { name: "Page D", traffic: 1600 },
  { name: "Page D", traffic: 900 }
];

export const revenueData = [
  { name: "Page A", revenue: 850 },
  { name: "Page B", revenue: 300 },
  { name: "Page C", revenue: 1100 },
  { name: "Page D", revenue: 600 }
];

export const propertiesData = [
  { name: "Page A", properties: 200 },
  { name: "Page B", properties: 1200 },
  { name: "Page C", properties: 600 },
  { name: "Page D", properties: 1600 },
  { name: "Page D", properties: 1000 },
  { name: "Page H", properties: 2260 },
  { name: "Page K", properties: 800 }
];
export const citiesData = [
  { name: "Page A", cities: 200 },
  { name: "Page B", cities: 1200 },
  { name: "Page C", cities: 600 },
  { name: "Page D", cities: 1600 },
  { name: "Page D", cities: 1000 },
  { name: "Page H", cities: 2260 },
  { name: "Page K", cities: 800 }
];
export const visitsData = [
  { name: "Page A", visit: 200 },
  { name: "Page B", visit: 1200 },
  { name: "Page C", visit: 600 },
  { name: "Page D", visit: 1600 },
  { name: "Page D", visit: 1000 },
  { name: "Page H", visit: 2260 },
  { name: "Page K", visit: 800 }
];

export const queriesData = [
  { name: "Page A", queries: 200 },
  { name: "Page B", queries: 1200 },
  { name: "Page C", queries: 600 },
  { name: "Page D", queries: 1600 },
  { name: "Page D", queries: 1000 },
  { name: "Page H", queries: 2260 },
  { name: "Page K", queries: 800 }
];

export const increamentData = [
  { name: "Page A", price: 200 },
  { name: "Page B", price: 1200 },
  { name: "Page C", price: 600 },
  { name: "Page D", price: 1600 },
  { name: "Page D", price: 1000 },
  { name: "Page H", price: 2260 },
  { name: "Page K", price: 800 }
];

export const increamentRevenueData = [
  { name: "Page A", revenue: 200 },
  { name: "Page B", revenue: 1200 },
  { name: "Page C", revenue: 600 },
  { name: "Page D", revenue: 1600 },
  { name: "Page D", revenue: 1000 },
  { name: "Page H", revenue: 2260 },
  { name: "Page K", revenue: 800 }
];

export const lineData = [
  { name: "Page A", price: 200 },
  { name: "Page B", price: 1100 },
  { name: "Page C", price: 800 },
  { name: "Page D", price: 1700 },
  { name: "Page D", price: 600 },
  { name: "Page D", price: 1800 },
  { name: "Page D", price: 600 }
];

export const lineData2 = [
  { name: "Page A", traffic: 200 },
  { name: "Page B", traffic: 1100 },
  { name: "Page C", traffic: 800 },
  { name: "Page D", traffic: 1700 },
  { name: "Page D", traffic: 600 },
  { name: "Page D", traffic: 1800 },
  { name: "Page D", traffic: 600 }
];
