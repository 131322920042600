import axios from "axios";

export const getOneMasterListById = async (TOKEN: string, RoleId: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/commonMaster/${RoleId}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error fetching atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const addMasterAPI = async (TOKEN: string, formData: object) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/commonMaster`, formData, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error adding atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateMasterAPI = async (TOKEN: string, id: number, formData: object) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/commonMaster/${id}`, formData, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });
    if (response.status !== 200) {
      throw new Error("Error updating atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const deleteMasterAPI = async (TOKEN: string, modelId: number, id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/commonMaster/${modelId}/${id}`, {
      headers: {
        authorization: `Bearer ${TOKEN}`
      }
    });

    if (response.status !== 200) {
      throw new Error("Error deleting atrs. Please check your user credentials.");
    }

    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};