import axios from "axios";

export const signIn = async (MobileNumber: string, Password: string, message: string) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/user/signIn`,
      data: {
        MobileNumber,
        Password
      }
    });
    if (res.status !== 200) throw new Error("Unable to login, Please check your user credentials.");
    return res.data;
  } catch (error:any) {
    throw new Error(error?.response?.data?.message);
  }
};
