import axios from "axios";
import { ResetPasswordInterface } from "../shared/interfaces/ResetPasswordInterfaces";

export const resetPasswordAPI = async (inputData: ResetPasswordInterface, Token: any, UserId: number) => {
    const { newPassword } = inputData;
    let userObj = {
      newPassword
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/resetPassword/${UserId}`,
        { ...userObj, newPassword: newPassword },
        {
          headers: {
            authorization: `Bearer ${Token}`
          }
        }
      );
      if (response.status !== 200) throw new Error("Unable to update password please try again.");
  
      return response.data;
    } catch (error:any) {
      throw new Error(error?.response?.data?.message);
    }
  };