import { useEffect, useState, useRef, useCallback } from "react";
import { Col, Row, message, Card, Tag, Button, Spin, Space, Modal } from "antd";
import tree_icon from "../../assets/pictures/tree_white_icon.png";
import * as treeService from "../../services/treeServices";
import moment from "moment";
import NoImageAvailable from "../../assets/pictures/No_Image_Available.jpg";
import { Link, useHistory, useParams } from "react-router-dom";
import { UserRoleDATA } from "../../constants/databaseConstants";
import TreeLocation from "./treeLocation";
import styles from "./index.module.css";
import { CloseCircleOutlined } from "@ant-design/icons";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import TreDetailsUpdateModel from "./treeDetalisUpdateModel";

const IMAGE_BASE_URL = process.env.REACT_APP_API_URL?.split("/").slice(0, 3).join("/");

const Timeline = () => {
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const userToken: any = JSON.parse(localStorage.getItem(LocalStorageConstants.FORM) || "{}");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const history = useHistory();
  const { id } = useParams<{ id: any }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [timelineData, setTimelineData] = useState<any | null>();
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isImageHide, setIsImageHide] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number | any>(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const loaderRef = useRef(null);
  const [updateTreeDetails, setUpdateTreeDetails] = useState(true);

  const [isEditModelVisible, setEditModelVisible] = useState(false);
  const [editMode, setEditMode] = useState<string>("");

  // Function to show the edit model
  const showEditModel = (mode: string) => {
    setEditMode(mode);
    setEditModelVisible(true);
  };

  // Function to hide the edit model
  const hideEditModel = () => {
    setEditModelVisible(false);
  };

  // Function to handle save in the edit model
  const handleSave = (updatedData: any) => {
    // Implement the logic to save the updated data
    setTotalRecords(0); // Initialize totalRecords to 0
    setCurrentPage(1);
    setPageSize(3);
    setUpdateTreeDetails(true);
    setEditModelVisible(false);
  };

  const loadMoreData = useCallback(async () => {
    try {
      const totalPages = Math.ceil(totalCount / pageSize);

      if (currentPage > totalPages) {
        setTotalRecords(totalCount + 1);
        // Don't load more data if the current page exceeds total pages
        return;
      }

      const response = await treeService.getTimelineListByTreeTag(TOKEN, id, currentPage, pageSize);
      const fetchedNewItems = response?.treeDetails?.treeTransaction || [];

      setTimelineData((prevData: any) => {
        return {
          ...prevData,
          treeTransaction: [...prevData.treeTransaction, ...fetchedNewItems]
        };
      });

      setCurrentPage((prevPage) => prevPage + 1);
    } catch (error: any) {
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  }, [currentPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          // Load more data when the loader is visible
          loadMoreData();
        }
      },
      {
        threshold: 0.5 // Change this threshold value as needed
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, [loadMoreData]);

  useEffect(() => {
    const renderTimelineData = async (id: number, page: number, size: number) => {
      setLoading(true);
      const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
      try {
        setTimelineData(null);
        setUpdateTreeDetails(false);
        const response: any = await treeService.getTimelineListByTreeTag(TOKEN, id, page, size);
        if (response?.treeDetails) {
          setLoading(false);
          setTimelineData(response.treeDetails);
          setTotalCount(response?.treeListTotalCount);
          setTotalRecords(0); // Initialize totalRecords to 0
          setCurrentPage((prevPage) => prevPage + 1);
        }
      } catch (error: any) {
        setLoading(false);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };
    if (updateTreeDetails) {
      renderTimelineData(id, currentPage, pageSize);
    }
  }, [updateTreeDetails]);

  const handleImageClick = (url: string) => {
    if (isImageHide) {
      setSelectedImageUrl(url);
      setIsModalVisible(true);
    } else {
      setIsImageHide(true);
    }
  };

  const closeModal = () => {
    setSelectedImageUrl(null);
    setIsModalVisible(false);
  };

  const locations = {
    latitude: timelineData?.Latitude,
    longitude: timelineData?.Longitude,
    name: timelineData?.TreeBotanicalName?.TreeGeneralName,
    treeTag: timelineData?.TreeTag
  };
  return (
    <div style={{ margin: "10px 10px 0 30px" }}>
      <div>
        {loading === true ? (
          <div className="loading loading--center">
            <Space direction="vertical" className="space--width" size="large">
              <Spin tip="Loading" size="large"></Spin>
            </Space>
          </div>
        ) : (
          <div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h2>Tree Details</h2>
                <div style={{ textAlign: "right", marginTop: "10px" }}>
                  <Button type="primary" style={{ display: "block", margin: "0 auto" }} className="gx-mb-2" onClick={() => history.goBack()}>
                    Back
                  </Button>
                </div>
              </div>
              {timelineData && isEditModelVisible && (
                <TreDetailsUpdateModel
                  timelineData={timelineData} // Pass the data to be edited
                  onCancel={hideEditModel}
                  onSave={handleSave}
                  editMode={editMode}
                />
              )}
              {timelineData && (
                <Card>
                  <div className="gx-media-body gx-featured-content">
                    <div className="gx-featured-content-left">
                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "120px" }}>Tree Tag</span>{" "}
                        <span className="gx-text-primary gx-mb-1" style={{ width: "150px" }}>
                          <span className="gx-text-black gx-mb-1">:</span> {timelineData?.TreeTag}
                        </span>
                      </p>
                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "120px" }}>Tree Name</span>{" "}
                        <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                          : {timelineData?.TreeBotanicalName?.TreeGeneralName}
                        </span>
                      </p>
                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "120px" }}>Botanical Name</span>

                        <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                          : {timelineData?.TreeBotanicalName?.BotanicalName}
                        </span>
                      </p>
                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "120px" }}>Plantation Date</span>{" "}
                        <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                          : {moment(timelineData?.PlantationDate).format("DD-MM-YYYY")}
                        </span>
                      </p>
                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "120px" }}>Latitude</span>{" "}
                        <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                          : {timelineData?.Latitude}
                        </span>
                      </p>
                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "120px" }}>Longitude</span>{" "}
                        <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                          : {timelineData?.Longitude}
                        </span>
                      </p>
                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "120px" }}>IsReplanted</span>
                        <span className="gx-text-black gx-mb-1" style={{ width: "150px" }}>
                          : {timelineData?.replantedTree?.ReplantTreeId ? "True" : "False"}
                        </span>
                      </p>
                      {timelineData?.replantedTree?.ReplantTreeId && (
                        <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                          <span style={{ width: "120px" }}>Dead Tree Tag</span>
                          <span
                            className="gx-text-black gx-mb-1"
                            style={{ width: "150px" }}
                            onClick={() => {
                              history.push(`/timeline/${timelineData?.replantedTree?.ReplantTreeId}`);
                              window.location.reload();
                            }}
                          >
                            : <Link to={""}>{timelineData?.replantedTree?.ReplantTreeTag}</Link>
                          </span>
                        </p>
                      )}
                      {userToken && userToken?.RoleName === UserRoleDATA.ORGANIZATION_ADMIN && (
                        <Button style={{ fontSize: "15px", width: "300px", display: "flex", justifyContent: "space-between", marginLeft: "-15px", textDecoration: "underline" }} type="link" onClick={() => showEditModel("header")}>
                          Edit
                        </Button>
                      )}
                      {/* Render the EditTreeModel component */}
                    </div>

                    <div className="gx-featured-content-left" style={{ marginLeft: "10%", marginTop: "27px" }}>
                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "150px" }}>Area</span>{" "}
                        <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                          : {timelineData?.Area?.AreaName}
                        </span>
                      </p>

                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "150px" }}>Village</span>{" "}
                        <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                          : {timelineData?.Area?.Village?.VillageName}
                        </span>
                      </p>

                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "150px" }}>Cluster</span>{" "}
                        <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                          : {timelineData?.Area?.Cluster?.ClusterName}
                        </span>
                      </p>

                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "150px" }}>Project</span>{" "}
                        <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                          : {timelineData?.Area?.Project?.ProjectName}
                        </span>
                      </p>
                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "150px" }}>Care Taker</span>{" "}
                        <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                          : {timelineData?.Caretaker?.CaretakerName}
                        </span>
                      </p>
                      <p className="gx-text-gray gx-mb-1" style={{ fontSize: "14px", width: "300px", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "150px" }}>Farmer</span>{" "}
                        <span className="gx-text-black gx-mb-1" style={{ width: "200px" }}>
                          : {timelineData?.Farmer?.FarmerName || "NA"}
                        </span>
                      </p>
                    </div>

                    {/* tree location show */}
                    <div className={styles["map-container"]}>
                      <TreeLocation location={locations} />
                    </div>
                  </div>
                </Card>
              )}
            </div>

            <Row>
              <Col span={24}>
                <h2 style={{ marginLeft: "16px" }}>Tree Updates</h2>
              </Col>

              <Col span={24}>
                {timelineData &&
                  timelineData?.treeTransaction?.map((item: any, index: number) => (
                    <div className="gx-timeline-section gx-timeline-center" style={{ marginLeft: "-35%", marginRight: "35%" }} key={index}>
                      <div className={`gx-timeline-item gx-timeline-center`}>
                        <div className="gx-timeline-time">
                          Last Updated on
                          <br /> {moment(item?.TrackingDate).format("DD MMM, YYYY")}
                        </div>
                        <p>
                          {item?.TreeHealthCondition.TreeHealth === "GOOD" ? (
                            <Tag className="gx-timeline-badge" style={{ backgroundColor: "#64e764", display: "flex", alignItems: "center", justifyContent: "center", marginRight: 0 }}>
                              <img alt="adani_airport" src={tree_icon} width="25px" height="25px" />
                            </Tag>
                          ) : item?.TreeHealthCondition.TreeHealth === "NEED-ATTENTION" ? (
                            <Tag className="gx-timeline-badge" style={{ backgroundColor: "#F7B239", display: "flex", alignItems: "center", justifyContent: "center", marginRight: 0 }}>
                              <img alt="adani_airport" src={tree_icon} width="25px" height="25px" />
                            </Tag>
                          ) : item?.TreeHealthCondition.TreeHealth === "VERY GOOD" ? (
                            <Tag className="gx-timeline-badge" style={{ backgroundColor: "#4CB706", display: "flex", alignItems: "center", justifyContent: "center", marginRight: 0 }}>
                              <img alt="adani_airport" src={tree_icon} width="25px" height="25px" />
                            </Tag>
                          ) : item?.TreeHealthCondition.TreeHealth === "DEAD" ? (
                            <Tag className="gx-timeline-badge" style={{ backgroundColor: "#F75039", display: "flex", alignItems: "center", justifyContent: "center", marginRight: 0 }}>
                              <img alt="adani_airport" src={tree_icon} width="25px" height="25px" />
                            </Tag>
                          ) : (
                            <Tag className="gx-timeline-badge" style={{ backgroundColor: "#64e764", display: "flex", alignItems: "center", justifyContent: "center", marginRight: 0 }}>
                              <img alt="adani_airport" src={tree_icon} width="25px" height="25px" />
                            </Tag>
                          )}
                        </p>

                        <div className="gx-timeline-panel">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {item?.DocumentPath.map((url: any, index: number) => (
                              <div key={index} style={{ marginRight: "10px" }}>
                                <img
                                  key={index} // Add a unique key to each image when rendering in a loop
                                  className="gx-rounded-lg"
                                  style={{ margin: " 10px 10px 10px 0", cursor: "pointer" }}
                                  src={`${IMAGE_BASE_URL}${url}`}
                                  alt="No img Available"
                                  height="200px"
                                  width="210px"
                                  onError={(e: any) => {
                                    setIsImageHide(false);
                                    e.target.src = NoImageAvailable; // Replace with the URL of your fallback image
                                  }}
                                  // onClick={() => handleImageClick(url)}
                                  onClick={() => {
                                    if (url) {
                                      handleImageClick(url);
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </div>

                          <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                            <p className="gx-text-gray gx-mb-1" style={{ display: "flex" }}>
                              Health Condition :&nbsp;
                              {item?.TreeHealthCondition.TreeHealth === "GOOD" ? (
                                <Tag className="gx-rounded-xs" color="#64e764">
                                  {item?.TreeHealthCondition.TreeHealth}
                                </Tag>
                              ) : item?.TreeHealthCondition.TreeHealth === "NEED-ATTENTION" ? (
                                <Tag className="gx-rounded-xs" color="#F7B239">
                                  {item?.TreeHealthCondition.TreeHealth}
                                </Tag>
                              ) : item?.TreeHealthCondition.TreeHealth === "VERY GOOD" ? (
                                <Tag className="gx-rounded-xs" color="#4CB706">
                                  {item?.TreeHealthCondition.TreeHealth}
                                </Tag>
                              ) : item?.TreeHealthCondition.TreeHealth === "DEAD" ? (
                                <Tag className="gx-rounded-xs" color="#F75039">
                                  {item?.TreeHealthCondition.TreeHealth}
                                </Tag>
                              ) : (
                                <Tag className="gx-rounded-xs" color="#B6FA8A">
                                  {item?.TreeHealthCondition.TreeHealth}
                                </Tag>
                              )}
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ display: "flex", marginTop: "-8px" }}>
                              Height :&nbsp;
                              <span className="gx-text-black gx-mb-1">
                                {item?.HeightInCm} cm, {item?.HeightInFt} feet
                              </span>
                            </p>
                            <p className="gx-text-gray gx-mb-1" style={{ display: "flex" }}>
                              Girth :&nbsp;
                              <span className="gx-text-black gx-mb-1">
                                {item?.GirthInCm ?? 0} cm, {item?.GirthInMeter ?? 0} m
                              </span>
                            </p>

                            <p className="gx-text-gray gx-mb-1" style={{ display: "flex" }}>
                              {item?.LastModifiedBy === null ? <>Created By : &nbsp; </> : <>Last Modified By : &nbsp;</>}

                              <span className="gx-text-black gx-mb-1">
                                {item?.LastModifiedBy === null ? (
                                  <>
                                    {" "}
                                    {item?.CreatedBy.FirstName} {item?.CreatedBy.LastName}
                                  </>
                                ) : (
                                  <>
                                    {item?.LastModifiedBy?.FirstName} {item?.LastModifiedBy?.LastName}{" "}
                                  </>
                                )}
                              </span>
                            </p>
                            {userToken && userToken?.RoleName === UserRoleDATA.ORGANIZATION_ADMIN && index === 0 && (
                              <Button style={{ fontSize: "15px", width: "300px", display: "flex", justifyContent: "space-between", marginLeft: "-15px", textDecoration: "underline" }} type="link" onClick={() => showEditModel("transaction")}>
                                Edit
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {totalRecords < totalCount ? (
                  <div ref={loaderRef} style={{ textAlign: "center" }}>
                    <Spin tip="Loading more..." />
                  </div>
                ) : (
                  <p style={{ textAlign: "center" }}>No more updates</p>
                )}

                <div></div>
              </Col>
            </Row>
          </div>
        )}
      </div>

      <Modal
        visible={isModalVisible}
        onCancel={closeModal}
        footer={null}
        centered
        width={selectedImageUrl ? "500" : "auto"} // Set the width to 'auto' if there's an image, otherwise set it to 900px
        destroyOnClose // This helps to re-render modal content on each open
        bodyStyle={{
          padding: 0,
          backgroundColor: "rgba(0, 0, 0, 0)" // Set the background color to transparent
        }}
        closeIcon={<CloseCircleOutlined style={{ color: "white", background: "black", fontSize: "27px", borderRadius: "20px", border: "1px solid black" }} />}
      >
        {selectedImageUrl && (
          <div
            className="image-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              padding: "20px"
            }}
          >
            <img
              className="gx-rounded-lg"
              style={{
                width: "100%",
                height: "650px",
                objectFit: "contain"
              }}
              src={`${IMAGE_BASE_URL}${selectedImageUrl}`}
              alt="Clicked_Image"
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Timeline;
