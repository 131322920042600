import React, { useState, useEffect } from "react";
import { Form, Table, Button, Input, Card, Tooltip, Spin, Space, Modal, message } from "antd";
import { useHistory } from "react-router-dom";
import styles from "./index.module.css";
import type { ColumnsType } from "antd/lib/table";
import type { TableProps } from "antd/lib/table";
import { Link } from "react-router-dom";
import type { SorterResult } from "antd/lib/table/interface";
import { DeleteOutlined } from "@ant-design/icons";
import TitleCaseFunction from "../../util/TitleCaseFunction";
import * as userServices from "../../services/userServices";
import { UserDeleteResponse, UserListInterface } from "../../shared/interfaces/User.interface";
import * as resetPasswordService from "../../services/resetPasswordServices";
import { ResetPasswordInterface, ResetResponseDataInterface } from "../../shared/interfaces/ResetPasswordInterfaces";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import { formatRoleName } from "../../util/formatRoleName";

const Users: React.FC = () => {
  const history = useHistory();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, any>>({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<UserListInterface> | null>(null);
  const [searchedText, setSearchedText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [resetRecordId, setResetRecordId] = useState<number>(0);
  const [deleteRecordId, setDeleteRecordId] = useState<number>(0);
  const [userData, setUserData] = useState<Array<UserListInterface>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [renderLogin, setRenderLogin] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [inputData, setInputData] = useState({
    newPassword: "",
    confirmPassword: ""
  });

  useEffect(() => {
    renderUsersList();
  }, [refresh]);

  const renderUsersList = async () => {
    setLoading(true);
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
    try {
      const response = await userServices.getUsersListAPI(TOKEN);
      if (response?.userList) {
        setLoading(false);
        setUserData(response?.userList);
      }
    } catch (error: any) {
      setLoading(false);
      // message.error("Unable to load user list");
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  const handleInputData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputData((prevState) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const onFinish = () => {
    renderResetPassword(inputData);
    setResetPasswordModal(false);
  };

  const renderResetPassword = async (inputData: ResetPasswordInterface) => {
    setLoader(true);
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
    try {
      const response: ResetResponseDataInterface = await resetPasswordService.resetPasswordAPI(inputData, TOKEN, resetRecordId);
      if (response) {
        setLoader(false);
        message.destroy();
        message.success(response.message);
        setRenderLogin(true);
      }
    } catch (error: any) {
      setLoader(false);
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  const handleCopy = (event: any) => {
    event.preventDefault();
  };

  const columns: ColumnsType<UserListInterface> = [
    {
      title: "First Name",
      key: "FirstName",
      render: (text, record) => (
        <span className={`gx-link ${highlightedRow === record.UserId ? "entity-name-selected" : "entity-name"}`} onClick={() => renderView(text)}>
          <Link to={``}>
            <Tooltip title="View User Details">{TitleCaseFunction(text.FirstName)}</Tooltip>
          </Link>
        </span>
      ),
      onFilter: (value: any, record) => String(record.FirstName).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a.FirstName.localeCompare(b.FirstName)
      },
      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "FirstName", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },
    {
      title: "Last Name",
      key: "LastName",
      render: (text, record) => (
        <span className={`gx-link ${highlightedRow === record.UserId ? "entity-name-selected" : "entity-name"}`} onClick={() => renderView(text)}>
          <Link to={``}>
            <Tooltip title="View User Details">{TitleCaseFunction(text.LastName)}</Tooltip>
          </Link>
        </span>
      ),
      onFilter: (value: any, record) => String(record.LastName).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a.LastName.localeCompare(b.LastName)
      },

      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "LastName", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },
    {
      title: "Mobile Number",
      key: "MobileNumber",
      render: (text, record) => (
        <span className={`gx-link entity-name`} onClick={() => renderView(text)}>
          <Link to={``}>
            <Tooltip title="View User Details">{TitleCaseFunction(text.MobileNumber)}</Tooltip>
          </Link>
        </span>
      ),
      onFilter: (value: any, record) => String(record.MobileNumber).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a.MobileNumber.localeCompare(b.MobileNumber)
      },
      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "MobileNumber", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },
    {
      title: "Role",
      key: "RoleName",
      render: (text, record) => (
        <span>
          <>{formatRoleName(text.Role?.RoleName)}</>
        </span>
      ),
      onFilter: (value: any, record) => String(record.Role?.RoleName).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a.Role?.RoleName.localeCompare(b.Role?.RoleName)
      },
      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "RoleName", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },
    {
      title: "Organization",
      key: "OrganizationName",
      render: (text, record) => <span>{TitleCaseFunction(text.Organization?.OrganizationName)}</span>,
      onFilter: (value: any, record) => String(record.Organization?.OrganizationName).toLowerCase()?.includes(value.toLowerCase()),
      sorter: {
        compare: (a, b) => a.Organization?.OrganizationName.localeCompare(b.Organization?.OrganizationName)
      },
      onHeaderCell: () => ({
        onClick: () => {
          setSortedInfo({ columnKey: "OrganizationName", order: sortedInfo?.order === "ascend" ? "descend" : "ascend" });
        }
      })
    },

    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <>
          <Tooltip title="Edit">
            <span className="gx-link" onClick={() => renderEdit(text)}>
              <i className="icon icon-edit" style={{ fontSize: "18px", marginRight: "25px" }} />
            </span>
          </Tooltip>
          &nbsp;&nbsp;|&nbsp;&nbsp; &nbsp;&nbsp;
          <Tooltip title="Reset Password">
            <span
              className="gx-link"
              onClick={() => {
                renderResetPasswordModal(text);
              }}
            >
              <i className="icon icon-reset-password" style={{ fontSize: "20px", marginRight: "15px" }} />
            </span>
          </Tooltip>
          &nbsp;&nbsp;|&nbsp;&nbsp; &nbsp;&nbsp;
          <Tooltip title="Delete">
            <span
              className="gx-link"
              onClick={() => {
                showDeleteConfirm(text);
              }}
            >
              <DeleteOutlined style={{ fontSize: "20px" }} />
            </span>
          </Tooltip>
        </>
      )
    }
  ];

  const filterDataSource = (data: UserListInterface[]) => {
    const searchText = searchedText.toLowerCase();
    return data.filter((item) => {
      return String(item.FirstName).toLowerCase()?.includes(searchText) || String(item.LastName).toLowerCase()?.includes(searchText) || String(item.Organization?.OrganizationName).toLowerCase()?.includes(searchText) || String(item.Role?.RoleName).toLowerCase()?.includes(searchText) || String(item.MobileNumber).toLowerCase()?.includes(searchText);
    });
  };

  const showDeleteConfirm = (deleteUser: UserListInterface) => {
    setDeleteRecordId(deleteUser.UserId);
    setOpenModal(true);
    setHighlightedRow(deleteUser.UserId);
  };

  const renderResetPasswordModal = (resetUser: UserListInterface) => {
    setResetRecordId(resetUser.UserId);
    setResetPasswordModal(true);
    setHighlightedRow(resetUser.UserId);
  };

  const renderEdit = (editUser: UserListInterface) => {
    history.push(`/user/update/${editUser.UserId}`);
  };

  const renderView = (view: UserListInterface) => {
    history.push({
      pathname: `/user/view/${view.UserId} `
    });
  };

  const addUser = () => {
    history.push(`/user/add/form`);
  };

  const handleOk = (id: number | null) => {
    setOpenModal(false);
    deleteUserById(id);
  };

  const deleteUserById = async (UserId: number | null) => {
    const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
    try {
      if (UserId !== null) {
        const response: UserDeleteResponse = await userServices.deleteUserAPI(TOKEN!, UserId);
        setRefresh(true);
        message.destroy();
        message.success(response.message);
        setRefresh(false);
        setHighlightedRow(null);
      }
    } catch (error: any) {
      setHighlightedRow(null);
      // message.error("Failed to delete user !");
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setResetPasswordModal(false);
    setHighlightedRow(null);
  };

  const handleChange: TableProps<UserListInterface>["onChange"] = (pagination: any, filters, sorter) => {
    setFilteredInfo(filters);
    if (Array.isArray(sorter)) {
      setSortedInfo(null);
    } else if (sorter && sorter.columnKey) {
      setSortedInfo({ columnKey: sorter.columnKey, order: sorter.order });
    } else {
      setSortedInfo(null);
    }
    setPagination(pagination);
  };

  return (
    <div>
      {loading === true ? (
        <div className="loading loading--center">
          <Space direction="vertical" className="space--width" size="large">
            <Spin tip="Loading" size="large"></Spin>
          </Space>
        </div>
      ) : (
        <Card title="Users" className="card--height">
          <div className={`${styles["div-container"]}`}>
            <div className="card-list-data-wrapper">
              <div className="card-list-data-container">
                <Input.Search
                  placeholder="Search."
                  onSearch={(value) => {
                    setSearchedText(value);
                  }}
                  onChange={(e) => {
                    setSearchedText(e.target.value);
                  }}
                  style={{ width: "35%" }}
                />
              </div>
              <Button
                type="primary"
                onClick={() => addUser()}
                style={{
                  fontWeight: "500px",
                  display: "block",
                  margin: "0 auto"
                }}
              >
                Add User
              </Button>
            </div>
          </div>
          <Table className="gx-table-responsive" columns={columns} pagination={pagination} dataSource={filterDataSource(userData)} onChange={handleChange} bordered />
        </Card>
      )}
      <Modal title="Are you sure you want to delete this user?" visible={openModal} onOk={() => handleOk(deleteRecordId)} onCancel={handleCancel} okText="Yes" cancelText="No" width="500px" destroyOnClose maskClosable={true} bodyStyle={{ padding: 0 }} wrapClassName="delete-modal"></Modal>
      <Modal
        title="Reset Password"
        visible={resetPasswordModal}
        onCancel={handleCancel}
        width="500px"
        destroyOnClose
        maskClosable={true}
        wrapClassName="delete-modal"
        footer={null} // This will remove the default OK and Cancel buttons
      >
        <Form onFinish={onFinish} autoComplete="off" name="basic" validateTrigger="onBlur" className={`gx-signin-form gx-form-row0`}>
          <p>Please reset your new password.</p>
          <Form.Item
            name="newPassword"
            rules={[
              { required: true, message: "The new password is required" },

              { min: 8, message: "New Password must be minimum 8 characters." },
              {
                pattern: /[A-Z]/,
                message: "Password must contain at least one uppercase letter."
              },
              {
                pattern: /[a-z]/,
                message: "Password must contain at least one lowercase letter"
              },
              {
                pattern: /\d/,
                message: "Password must contain at least one digit."
              },
              {
                pattern: /[$&+,:;=?@#|'<>.^*()%!-]/,
                message: "Password must contain at least one special character."
              }
            ]}
          >
            <Input.Password placeholder="New Password" type="password" onChange={handleInputData} name="newPassword" onCopy={handleCopy} />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!"
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords that you entered do not match!");
                }
              })
            ]}
          >
            <Input.Password placeholder="Confirm Password" type="password" onChange={handleInputData} name="confirmPassword" onCopy={handleCopy} />
          </Form.Item>

          <div
            style={{
              display: "flex"
            }}
          >
            <Button type="primary" style={{ display: "block", margin: "0 auto" }} className="password-form-button" htmlType="submit" disabled={loader}>
              Reset
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Users;
