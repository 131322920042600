import React from "react";
import Widget from "../../Dashboard/Widget";
import { Row, Col, Button } from "antd";
import { ChromeFilled, AndroidFilled, ProjectFilled } from "@ant-design/icons";
import styles from "./index.module.css";
import { useHistory } from "react-router-dom";

const downloadAppUrl = process.env.REACT_APP_DOWNLOAD_APP;

interface LinksProps {
  handleLinksClose: () => void;
}

const Links: React.FC<LinksProps> = ({ handleLinksClose }) => {
  const history = useHistory();

  const openLink = (url: string) => {
    window.open(url, "_blank");
  };
  const openWebPortalPage = () => {
    history.push("/info");
  };
  return (
    <>
      <Widget styleName={`gx-card-profile-sm ${styles["link-widget"]}`}>
        {/* Web portal link */}
        <Row gutter={24} style={{ marginTop: "10px" }}>
          <Col xs={24} sm={12} md={10} lg={8} xl={8} className={styles["links-content"]} onClick={openWebPortalPage}>
            <div className={styles["link-container"]}>
              <ChromeFilled className={styles["icon"]} />
              <div className={styles["text-container"]}>
                <span className={styles["text"]}>About TrackMyTree</span>
              </div>
            </div>
          </Col>

          {/* Play Store link */}

          <Col xs={24} sm={12} md={10} lg={8} xl={8} className={styles["links-content"]} onClick={() => openLink(`${downloadAppUrl}`)}>
            <div className={styles["link-container"]}>
              <AndroidFilled className={styles["icon"]} />
              <div className={styles["text-container"]}>
                <span className={styles["text"]}>Download App</span>
              </div>
            </div>
          </Col>

          {/* Company website link */}

          <Col xs={24} sm={12} md={10} lg={8} xl={8} className={styles["links-content"]} onClick={() => openLink("https://corecotechnologies.com/")}>
            <div className={styles["link-container"]}>
              <ProjectFilled className={`${styles["icon"]}`} style={{ transform: " scaleY(-1)" }} />
              <div className={styles["text-container"]}>
                <span className={styles["text"]}>About CoReCo Technologies</span>
              </div>
            </div>
          </Col>
        </Row>
      </Widget>

      <Button type="link" onClick={handleLinksClose} style={{ marginRight: "5%", height: "0px" }}>
        Login
      </Button>
    </>
  );
};

export default Links;
