import { useState } from "react";
import { Button, Drawer, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomScrollbars from "../util/CustomScrollbars";
import { setThemeType } from "../appRedux/actions/Setting";
import { ACTIVE_COLOR_OPTION, BLUE, BLUE_SEC, DARK_BLUE, DARK_BLUE_SEC, DEEP_ORANGE, DEEP_ORANGE_SEC, LIGHT_BLUE, LIGHT_BLUE_SEC, LIGHT_PURPLE, LIGHT_PURPLE_1, LIGHT_PURPLE_1_SEC, LIGHT_PURPLE_2, LIGHT_PURPLE_2_SEC, LIGHT_PURPLE_SEC, ORANGE, ORANGE_SEC, RED, RED_SEC, THEME_TYPE_DARK, THEME_TYPE_LITE, THEME_TYPE_SEMI_DARK } from "../constants/ThemeSetting";
import { setThemeColor } from "../appRedux/actions";
import { RootState } from "../appRedux/store";

const CustomizerSystem = () => {
  const [isCustomizerOpened, setIsCustomizerOpened] = useState(false);
  const { themeType, themeColor } = useSelector(({ settings }: RootState) => settings);
  const dispatch = useDispatch();

  const handleColorChange = (filename: any) => {
    dispatch(setThemeColor(filename));
  };

  const toggleCustomizer = () => {
    setIsCustomizerOpened(!isCustomizerOpened);
  };

  const onThemeTypeChange = (e: any) => {
    dispatch(setThemeType(e.target.value));
  };

  const getCustomizerContent = () => {
    return (
      <CustomScrollbars className="gx-customizer">
        <div className="gx-customizer-item">
          <h5 className="gx-mb-0 gx-text-uppercase">Theme Colors</h5>
          {/* <Radio.Group value={themeType} onChange={onThemeTypeChange}>
            <Radio.Button value={THEME_TYPE_LITE}>Lite</Radio.Button>
            <Radio.Button value={THEME_TYPE_SEMI_DARK}>Semi Dark</Radio.Button>
            <Radio.Button value={THEME_TYPE_DARK}>Dark</Radio.Button>
          </Radio.Group> */}
        </div>
        {themeType !== THEME_TYPE_DARK && <div className="gx-customizer-item">{getPresetColors(themeColor)}</div>}
      </CustomScrollbars>
    );
  };

  const handleThemeColor = (fileName: any) => {
    handleColorChange(fileName);
  };

  const getPresetColors = (themeColor: any) => {
    return (
      <ul className="gx-color-option gx-list-inline">
        <li>
          <span onClick={() => handleThemeColor("light_purple")} style={{ backgroundColor: LIGHT_PURPLE_SEC, color: ACTIVE_COLOR_OPTION }} className={`gx-link gx-color-light-purple ${themeColor === LIGHT_PURPLE && "active"}`} />
        </li>
        <li>
          <span onClick={() => handleThemeColor("red")} style={{ backgroundColor: RED_SEC, color: ACTIVE_COLOR_OPTION }} className={`gx-link gx-color-red ${themeColor === RED && "active"}`} />
        </li>
        <li>
          <span onClick={() => handleThemeColor("blue")} style={{ backgroundColor: BLUE_SEC, color: ACTIVE_COLOR_OPTION }} className={`gx-link gx-color-blue ${themeColor === BLUE && "active"}`} />
        </li>
        <li>
          <span onClick={() => handleThemeColor("dark_blue")} style={{ backgroundColor: DARK_BLUE_SEC, color: ACTIVE_COLOR_OPTION }} className={`gx-link gx-color-dark-blue ${themeColor === DARK_BLUE && "active"}`} />
        </li>

        <li>
          <span onClick={() => handleThemeColor("orange")} style={{ backgroundColor: ORANGE_SEC, color: ACTIVE_COLOR_OPTION }} className={`gx-link gx-color-orange ${themeColor === ORANGE && "active"}`} />
        </li>

        <li>
          <span onClick={() => handleThemeColor("light_blue")} style={{ backgroundColor: LIGHT_BLUE_SEC, color: ACTIVE_COLOR_OPTION }} className={`gx-link gx-color-light-blue ${themeColor === LIGHT_BLUE && "active"}`} />
        </li>

        <li>
          <span onClick={() => handleThemeColor("deep_orange")} style={{ backgroundColor: DEEP_ORANGE_SEC, color: ACTIVE_COLOR_OPTION }} className={`gx-link gx-color-deep-orange ${themeColor === DEEP_ORANGE && "active"}`} />
        </li>

        <li>
          <span
            onClick={() => handleThemeColor("light_purple_1")}
            style={{
              backgroundColor: LIGHT_PURPLE_1_SEC,
              color: ACTIVE_COLOR_OPTION
            }}
            className={`gx-link gx-color-light-purple1 ${themeColor === LIGHT_PURPLE_1 && "active"}`}
          />
        </li>

        <li>
          <span
            onClick={() => handleThemeColor("light_purple_2")}
            style={{
              backgroundColor: LIGHT_PURPLE_2_SEC,
              color: ACTIVE_COLOR_OPTION
            }}
            className={`gx-link gx-color-light-purple2 ${themeColor === LIGHT_PURPLE_2 && "active"}`}
          />
        </li>
      </ul>
    );
  };

  return (
    <>
      <Drawer placement="right" closable={false} onClose={toggleCustomizer} visible={isCustomizerOpened}>
        {getCustomizerContent()}
      </Drawer>
      <div className="gx-customizer-option">
        <Button type="primary" onClick={toggleCustomizer}>
          <i className="icon icon-setting fxicon-hc-spin gx-d-block" />
        </Button>
      </div>
    </>
  );
};

export default CustomizerSystem;
